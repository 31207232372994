import { Input } from "antd";

const { TextArea } = Input;
interface IProps {
  placeholder?: string;
  rows?: number;
  autoSize?: boolean;
  maxLength?: number;
  minLength?: number;
  className?: string;
  disabled?: boolean;
  label?: string;
  onChange?: (value: any) => void;
}
export default function TextAreaUI(props: IProps) {
  //! state
  const {
    placeholder,
    rows,
    autoSize,
    maxLength,
    minLength,
    className,
    disabled,
    label,
    onChange,
    ...res
  } = props;
  //! function
  //! render
  return (
    <>
      {label && <p className="mb-2">{label}</p>}
      <TextArea
        rows={rows}
        placeholder={placeholder}
        maxLength={maxLength}
        minLength={minLength}
        autoSize={autoSize}
        className={className}
        disabled={disabled}
        style={{ resize: "none" }}
        onChange={onChange}
        {...res}
      />
    </>
  );
}
