import { useCallback, useEffect, useState } from "react";
import { ResponseGetNotifications } from "../notification.interface";
import notificationService from "../notification.service";

const useGetNotifications = (query: string, enabeld = true) => {
  const [data, setData] = useState<ResponseGetNotifications>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return notificationService.getNotifications(query);
  }, [enabeld, query]);

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [query]);

  const transformResponse = useCallback(
    (response: ResponseGetNotifications) => {
      if (response) {
        setData(response);
      }
    },
    []
  );

  useEffect(() => {
    if (!enabeld) {
      return;
    }
    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabeld, query]);

  return { data, error, isLoading, refetch };
};

export default useGetNotifications;
