import _ from "lodash";
import { TMsg } from "../interface";
import { handleToast } from "../store/Slices/ToastSlice";
import { Roles, optionsRole } from "../constants/Index";
import moment from "moment";
import { IDateFilter } from "../interface/IDateFilter";

export const getErrorMsg = (error: any) => {
  if (error?.response?.data?.error?.message) {
    return error.response.data.error.message;
  }
  if (error?.response?.data?.[0]) {
    return error?.response?.data?.[0];
  }
  if (error?.response?.data?.message) {
    return error?.response?.data?.message;
  }

  if (error?.response?.data?.detail) {
    return error?.response?.data?.detail;
  }

  return `Something wrong!`;
};

export const showMsg = (type: TMsg, msg: string) => {
  return (dispatch: any) => {
    dispatch(
      handleToast({
        type,
        content: msg,
      })
    );
  };
};

export const parseQueryString = (query: Object) => {
  return Object.entries(query)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");
};

export const limitTextString = (text: string, limit: number) => {
  return _.truncate(text, {
    length: limit,
    separator: " ",
  });
};

export const checkRole = (role: string) => {
  const roleFind = optionsRole.find((item) => item.value === role);
  return roleFind?.label ?? "";
};

export const getTimeStampHour = (
  timeNumber: number,
  type: "end" | "start",
  typeFilter: IDateFilter
) => {
  let typeOf = typeFilter.type;

  if (typeFilter.type === "month") {
    typeOf = "date";
  }
  let time = "";
  if (type === "start") {
    time = moment.utc(timeNumber).startOf(typeOf).format("YYYY-MM-DD HH:mm:ss");
  }
  if (type === "end") {
    time = moment.utc(timeNumber).endOf(typeOf).format("YYYY-MM-DD HH:mm:ss");
  }

  return moment.utc(time, "YYYY-MM-DD HH:mm:ss").valueOf();
};

export const getCreatedTime = (createdTime: number | string) => {
  return moment
    .utc(createdTime)
    .subtract(2, "hours")
    .format("DD-MM-YYYY HH:mm");
};

export const getStatusAction = (valueBefore: any[], value: any[]) => {
  let status = true;
  if (valueBefore?.length !== value?.length) {
    return false;
  }
  for (let index = 0; index < valueBefore?.length; index++) {
    if (valueBefore[index] !== value[index]) {
      status = false;
    }
  }
  return status;
};

export const functionRole = (userRole: string) => {
  if (userRole === Roles.ADMIN) {
    return 1;
  }
  if (userRole === Roles.CONFIG) {
    return 2;
  }
  if (userRole === Roles.CUSTOMER) {
    return 3;
  }
};
