import { Tabs } from "antd";
import type { TabsProps } from "antd";
import TypographyUI from "../typography/Typography";

const { TabPane } = Tabs;
interface IProps {
  tabs: TabsProps["items"];
  handleChange?: () => void;
  extraAction?: any;
  type?: "card" | "line" | "editable-card";
  className?: string;
}

export default function TabUI(props: IProps) {
  //! state
  const { tabs, handleChange, extraAction, type, className, ...res } = props;
  const onChange = (key: string) => {};

  //! function
  //! render
  const renderTabBar = () => {
    return tabs?.map((item, index) => {
      return (
        <TabPane tab={<TypographyUI>{item.label}</TypographyUI>} key={item.key}>
          <div className="!h-full">{item.children}</div>
        </TabPane>
      );
    });
  };
  return (
    <Tabs
      tabBarExtraContent={extraAction && extraAction}
      type={type}
      defaultActiveKey="1"
      rootClassName={className}
      {...res}
    >
      {renderTabBar()}
    </Tabs>
  );
}
