import { Popconfirm } from "antd";
import CommonIcons from "../../../assets/icons";

interface IProps {
  title?: string;
  onConfirm?: () => void;
  description?: string;
  open?: boolean;
  close?: () => void;
}
export default function PopConfirmUI(props: IProps) {
  //! state
  const { title, onConfirm, description, open, close } = props;

  //! function
  //! render
  return (
    <Popconfirm
      title={title}
      description={description}
      onCancel={close}
      onConfirm={onConfirm}
      placement="topRight"
      icon={<CommonIcons.QuestionCircle style={{ color: "red" }} />}
      open={open}
    ></Popconfirm>
  );
}
