import LoginHead from "../../components/login/LoginHeader";
import LoginForm from "../../components/login/LoginForm";
import CommonImages from "../../assets/images";
export default function LoginPage(props: any) {
  return (
    <div
      className="min-h-full h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-blue-500"
      style={{
        backgroundImage: `url(${CommonImages.bgLogin})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="max-w-md w-full space-y-4 p-8 bg-white rounded-xl shadow-form">
        <LoginHead />
        <LoginForm />
      </div>
    </div>
  );
}
