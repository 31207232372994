import ToastUI from "./components/ui/toast/Toast";
import Router from "./router/Index";

function App() {
  return (
    <>
      <ToastUI />
      <Router></Router>
    </>
  );
}

export default App;
