import { Form } from "antd";
import { CommonStyles } from "../../components/ui";
import { useAppSelector } from "../../hooks/hooks";
import baseRouter from "../../router/baseRouter";
import FormPassword from "./FormPassword";

export default function SettingPassword() {
  //! state

  //! function

  //! render
  return (
    <div className="flex flex-col gap-5">
      <CommonStyles.TitleCardUI
        title="Đổi mật khẩu tài khoản"
        subTitle={[
          {
            title: "Thay đổi mật khẩu",
            link: baseRouter.settingPassword,
          },
        ]}
      />
      <CommonStyles.CardResize>
        <FormPassword />
      </CommonStyles.CardResize>
    </div>
  );
}
