import { Switch } from "antd";
interface IProps {
  onChange?: (e: any) => void;
  autoFocus?: boolean;
  checked?: boolean;
  className?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  loading?: boolean;
  size?: "default" | "small";
  value?: boolean;
  defaultValue?: boolean;
  label?: string;
}
export default function SwitchUI(props: IProps) {
  //! state
  const {
    onChange,
    autoFocus,
    checked,
    className,
    defaultChecked,
    disabled,
    loading,
    size,
    value,
    defaultValue,
    label,
  } = props;
  //! function
  //! render
  return (
    <>
      {label && <label>{label}</label>}
      <Switch
        autoFocus={autoFocus}
        checked={checked}
        className={className}
        defaultChecked={defaultChecked}
        disabled={disabled}
        loading={loading}
        size={size}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
      />
    </>
  );
}
