import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
  // ChartDataLabels
);

interface IProps {
  dataChart?: any;
  labels?: string[];
}
export default function Chart(props: IProps) {
  //! state
  const { dataChart, labels } = props;
  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,

    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: "black",
        anchor: "end",
        offset: -20,
        align: "start",
        font: { size: "16" },
      },
    },
  };

  const data = {
    labels: labels ?? [],
    datasets: dataChart,
  };
  //! function
  //! render

  return (
    <div
      style={{
        width: "100%",
        flexGrow: 1,
        height: "100%",
        position: "relative",
      }}
    >
      <Bar
        updateMode="resize"
        options={options}
        // plugins={[ChartDataLabels]}
        data={data}
      />
    </div>
  );
}
