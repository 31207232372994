import {
  MapContainer,
  ZoomControl,
  TileLayer,
  GeoJSON,
  useMapEvents,
} from "react-leaflet";
import { LatLngExpression } from "leaflet";
import { TDataMap } from "../../../interface";
import { API_LAYER_MAP } from "../../../constants/api";
import dataHB from "../../../dataJson/DataHB.json";
import "./leaflet.css";
import { useState } from "react";
import { debounce } from "lodash";
interface IProps {
  data?: TDataMap[];
  zoom?: {
    default: number;
    minZoom: number;
    maxZoom: number;
  };
  className?: string;
  height?: number;
  renderMarkers?: any;
  zoomControl?: boolean;
  uniqueId?: string;
}
export default function LeafletUI(props: IProps) {
  //! state
  const { zoom, renderMarkers, zoomControl = true, uniqueId } = props;
  const position: LatLngExpression = [20.82805207721789, 105.34432911809807];
  //! function
  // const codingSpot = new Icon({
  //   iconUrl: CommonImages.waterMap,
  //   iconSize: [30, 125],
  //   iconAnchor: [40, 90],
  //   popupAnchor: [-25, -40],
  // });
  const [mousePosition, setMousePosition] = useState({
    latitude: 0,
    longtitude: 0,
  });

  const [zoomLevel, setZoomLevel] = useState(zoom?.default);

  //! render
  const onEachFeature = (feature: any, layer: any) => {
    if (feature.properties && feature.properties.TEN_DVHC_X) {
      const center = layer.getBounds().getCenter();
      layer
        .bindTooltip(feature.properties.TEN_DVHC_X, {
          permanent: true,
          direction: "center",
          className: "layer-custom-Zoom",
        })
        .openTooltip(center);
    }
  };
  const handleMouseMove = (event: any) => {
    const { latlng } = event;
    const { lat, lng } = latlng;
    const set = debounce(() => {
      setMousePosition({
        latitude: lat,
        longtitude: lng,
      });
    }, 1000);
    set();
  };

  function MouseMoveEvents() {
    const map = useMapEvents({
      mousemove: handleMouseMove,
      zoomend: () => {
        setZoomLevel(map.getZoom());
      },
    });

    return null;
  }
  return (
    <MapContainer
      key={uniqueId && `${uniqueId}-${zoomLevel}`}
      center={position}
      zoom={zoom?.default ?? 10}
      minZoom={zoom?.minZoom ?? 10}
      maxZoom={zoom?.maxZoom ?? 18}
      zoomControl={false}
      scrollWheelZoom={true}
      doubleClickZoom={false}
      attributionControl={false}
      style={{
        width: "100%",
        height: "100%",
        minHeight: 350,
        marginBottom: "12px",
        position: "relative",
      }}
      className="border rounded-lg"
    >
      <MouseMoveEvents />
      <TileLayer url={String(API_LAYER_MAP)} />
      <div key={zoomLevel}>
        {Number(zoomLevel) >= 12 ? (
          <GeoJSON
            data={dataHB as any}
            onEachFeature={onEachFeature}
            style={{ fillColor: "none", weight: 1 }}
          />
        ) : (
          <GeoJSON
            data={dataHB as any}
            style={{ fillColor: "none", weight: 1 }}
          />
        )}
      </div>

      {zoomControl && <ZoomControl position="bottomright" />}
      {renderMarkers}
      <div className="absolute bottom-4 right-20 z-[500] text-white text-[13px]">
        <p>Kinh độ:{mousePosition.longtitude}</p>
        <p>Vĩ độ:{mousePosition.latitude}</p>
      </div>
    </MapContainer>
  );
}
