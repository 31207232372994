import { useCallback, useEffect, useState } from "react";
import { ResponseGetDetailDevice } from "../device.interface";
import deviceService from "../device.service";
import { ResponseGetDeviceType } from "../deviceType.interface";

const useGetDeviceType = (enabled = true) => {
  const [data, setData] = useState<ResponseGetDeviceType>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return deviceService.getDeviceType();
  }, []);

  const transformResponse = useCallback((response: ResponseGetDeviceType) => {
    if (response) {
      setData(response);
    }
  }, []);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled]);

  return { data, error, isLoading };
};

export default useGetDeviceType;
