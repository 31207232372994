import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { IDataChart, IDataTime } from "../../../../interface";
import { IDevice } from "../../../../modules/device/device.interface";
import { IDateFilter } from "../../../../interface/IDateFilter";
import telemetryService from "../../../../modules/telemetry/telemetry.service";
import { parseQueryString } from "../../../../helpers";
import { TelemetryValue } from "../../../../modules/telemetry/telemetry.interface";
import { wsTelemeetry } from "../../../../helpers/ws";
import { ENTITY_TYPE, ID_WS } from "../../../../constants/Index";
import { useGetLabelsChart } from "../../../../hooks/useGetLabelChart";
import { CommonStyles } from "../../../ui";
import _ from "lodash";
import { colorHighLow } from "../../../../constants/color";
import useGetAttributeKey from "../../../../modules/telemetry/hooks/useGetAttributeKey";
import { segment } from "../../../../helpers/chart";

interface IProps {
  detailDevice: IDevice;
  dateFilter: IDateFilter;
  loading: boolean;
}
export default function RealChartLMMN(props: IProps) {
  //! state
  const { detailDevice, dateFilter, loading } = props;
  const [dataChart, setDataChart] = useState<IDataChart>();
  const { labelChart } = useGetLabelsChart(
    dataChart?.dataChart,
    dateFilter,
    loading,
    dataChart?.dataChart && dataChart?.dataChart.length > 0
  );
  const { data: dataCrack } = useGetAttributeKey(
    ENTITY_TYPE,
    detailDevice.id.id,
    true,
    parseQueryString({ keys: "colorChart" })
  );
  let i = 0;
  let valueTemp: any = [];
  //! function
  const handleRealtime = (data: any) => {
    const valueRealTime = JSON.parse(data[0][1]);
    const timeReal = data[0][0];
    const convertedDate = moment(timeReal);

    const item = {
      minute: convertedDate.subtract(7, "minutes").minute(),
      hour: convertedDate.subtract(9, "hours").hour(),
      date: convertedDate.date(),
      month: convertedDate.month(),
      data: valueRealTime,
    };
    if (i === 0) {
      let temp: any = [...(dataChart?.dataChart as any)];
      temp.push(item);
      temp.splice(0, 1);
      valueTemp = temp;
      i = 1;

      setDataChart((prev) => ({
        ...prev,
        station: detailDevice.name,
        dataChart: temp,
      }));
    } else {
      let temp: any = [...valueTemp];
      temp.push(item);
      temp.splice(0, 1);
      valueTemp = temp;
      setDataChart((prev) => ({
        ...prev,
        station: detailDevice.name,
        dataChart: temp,
      }));
    }
  };
  useEffect(() => {
    const dataDefaultReal = async () => {
      const dataElement: IDataTime[] = [];

      const dataLow_High = telemetryService.getAttributeKey(
        detailDevice?.id?.entityType,
        detailDevice?.id?.id,
        parseQueryString({ keys: "Sethigh" })
      );
      const detailStation = await telemetryService.getTimeSeries(
        detailDevice?.id?.entityType,
        detailDevice?.id?.id,
        parseQueryString({
          startTs: dateFilter.endDate - 3600000,
          endTs: dateFilter.endDate,
          limit: 50000,
          useStrictDataTypes: true,
          keys: "data",
          orderBy: "ASC",
        })
      );

      detailStation?.data?.data?.forEach(
        (element: { ts: number; value: TelemetryValue }) => {
          const convertedDate = moment(element.ts);
          const minute = convertedDate.minute();
          const hour = convertedDate.subtract(9, "hours").hour();
          const date = convertedDate.date();
          const month = convertedDate.month();
          const item = {
            minute,
            hour,
            date,
            month,
            data: element.value,
          };
          dataElement.push(item);
        }
      );

      setDataChart({
        station: detailDevice.name,
        dataChart: dataElement,
        dataLow_High: (await dataLow_High).data,
      });
    };
    if (detailDevice && dateFilter.value == "real") {
      dataDefaultReal();
    }
  }, [detailDevice, dateFilter]);

  useEffect(() => {
    const dataReal = async () => {
      wsTelemeetry(
        "plugins/telemetry",
        detailDevice.id.id,
        detailDevice.id.entityType,
        ID_WS.CrackMetter,
        1000,
        "HISTORY",
        handleRealtime,
        moment.utc(Date.now()).valueOf()
      );
    };

    if (
      detailDevice &&
      dateFilter.value == "real" &&
      dataChart?.dataChart &&
      dataChart?.dataChart?.length > 0
    ) {
      dataReal();
    }
  }, [
    dataChart?.dataChart && dataChart?.dataChart?.length > 0,
    dateFilter.value,
  ]);
  const keyFilter =
    dateFilter.type === "hour" ? "Hourly rainfall" : "Rainfall on the day";
  const generateDataChart = useCallback(() => {
    if (!!dataChart?.dataChart && dataChart.dataChart.length > 0) {
      const dataLine = dataChart?.dataChart.map((child, index) => {
        return child.data[`${keyFilter}`].status
          ? child.data[`${keyFilter}`].value
          : NaN;
      });
      return dataLine;
    } else {
      return [{}];
    }
  }, [dataChart, loading, dateFilter]);
  // Total rainfall

  //! render
  return (
    <div className="flex flex-col gap-4">
      <CommonStyles.CardResize
        title="Lượng mưa (mm)"
        className=" border border-gray-200"
      >
        <CommonStyles.LineChart
          labels={labelChart ?? []}
          dataChart={[
            {
              borderColor: dataCrack?.data?.find(
                (el) => el.key === "colorChart"
              )?.value,
              data: generateDataChart(),
              cubicInterpolationMode: "monotone",
              lineTension: 3,
              borderWidth: 2.5,
              segment: segment,
              spanGaps: true,
              order: 1,
            },
            {
              label: `Ngưỡng cao ${dataChart?.station?.split("/")[1]}`,
              data: Array.from({ length: generateDataChart()?.length }, () =>
                Number(
                  dataChart?.dataLow_High &&
                    dataChart?.dataLow_High[0]?.value?.split(",")[0]
                )
              ),
              borderColor: colorHighLow.value,
              lineTension: 0,
              borderWidth: 1.5,
              borderDash: [5, 5],
              order: 0,
            },
          ]}
          displayLengend={false}
        />
      </CommonStyles.CardResize>

      <CommonStyles.CardResize
        title="Thống kê lượng mưa"
        className=" border border-gray-200 pb-0"
      >
        <div className="grid grid-cols-3 gap-4 pt-5">
          <CommonStyles.BoxInfo
            title="Tổng lượng mưa"
            value={Number(
              _.last(dataChart?.dataChart)?.data["Total rainfall"].value
            )}
            unit={"mm"}
          />
          <CommonStyles.BoxInfo
            title="Lượng mưa cao nhất"
            value={Number(
              _.maxBy(dataChart?.dataChart, `data[${keyFilter}].value`)?.data[
                keyFilter
              ].value
            )}
            unit={"mm"}
          />
          <CommonStyles.BoxInfo
            title="Lượng mưa thấp nhất"
            value={Number(
              _.minBy(dataChart?.dataChart, `data[${keyFilter}].value`)?.data[
                keyFilter
              ].value
            )}
            unit={"mm"}
          />
        </div>
      </CommonStyles.CardResize>
    </div>
  );
}
