import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { REFRESH_TOKEN, TOKEN } from "../constants/localKey";
import { urlsAPI } from "../constants/api";

class Services {
  axios: AxiosInstance;
  constructor() {
    this.axios = axios.create();
    this.axios.defaults.withCredentials = false;
    // const { accessToken } = this.getToken() || "";
    // if (!!accessToken) {
    //   this.attachTokenToHeader(accessToken);
    // }
    //! Interceptor request
    this.axios.interceptors.request.use(
      (config) => {
        const { accessToken } = this.getToken() || "";
        if (accessToken) {
          config.headers[`Authorization`] = `Bearer ${accessToken}`;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    //! Interceptor response
    this.axios.interceptors.response.use(
      (config) => {
        return config;
      },
      async (error) => {
        const originalRequest = error.config;
        if (error.response) {
          if (
            error.response.status === 401 &&
            originalRequest.url === urlsAPI.auth.token
          ) {
            localStorage.clear();
            return;
          }
          // Access Token was expired
          if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
              const { refreshToken } = this.getToken();
              if (refreshToken) {
                const response = await this.axios.post(urlsAPI.auth.token, {
                  refreshToken: refreshToken,
                });
                const { token } = response.data;
                this.setToken(token, refreshToken);
                originalRequest.headers[`Authorization`] = `Bearer ${token}`;
                return axios(originalRequest);
              }
            } catch (_error) {
              return Promise.reject(_error);
            }
          }
        }
        return Promise.reject(error);
      }
    );
  }

  attachTokenToHeader(token: string) {
    this.axios.interceptors.request.use(
      function (config) {
        if (config && config.headers) {
          // Do something before request is sent
          // config.headers[`Authorization`] = `Bearer ${token}`;
          config.headers[`Authorization`] = `Bearer ${token}`;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }

  get(url: string, config?: AxiosRequestConfig) {
    return this.axios.get(url, config);
  }

  post(url: string, data: any, config?: AxiosRequestConfig) {
    return this.axios.post(url, data, config);
  }

  delete(url: string, config?: AxiosRequestConfig) {
    return this.axios.delete(url, config);
  }

  put(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.axios.put(url, data, config);
  }

  patch(url: string, data: any, config?: AxiosRequestConfig) {
    return this.axios.patch(url, data, config);
  }

  setToken(token: string, refreshToken: string) {
    localStorage.setItem(TOKEN, token);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  getToken() {
    const accessToken = localStorage?.getItem(TOKEN);
    const refreshToken = localStorage?.getItem(REFRESH_TOKEN);
    return { accessToken, refreshToken };
  }
  clearLocalStorage() {
    localStorage.clear();
    //  delete this.axios.defaults.headers.common['Authorization']
  }
}

export default new Services();

// localStorage.setItem(
//   TOKEN,
//   JSON.stringify({
//     token:
//       " eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ2ZWdhZGVtb0BlbWFpbC5jb20iLCJ1c2VySWQiOiI2Nzk3ZDFiMC05ZjM1LTExZWUtYTg5Zi04ZDYzYzQ5ZTgzNWYiLCJzY29wZXMiOlsiVEVOQU5UX0FETUlOIl0sInNlc3Npb25JZCI6ImJmYzA0MmUwLWQwMzUtNDM3OC1iYTk1LWM1OGYwMTRhZDQ0ZiIsImlzcyI6InRoaW5nc2JvYXJkLmlvIiwiaWF0IjoxNzEyMTc1MjQ5LCJleHAiOjE3MTIxODQyNDksImVuYWJsZWQiOnRydWUsImlzUHVibGljIjpmYWxzZSwidGVuYW50SWQiOiI1M2NlMzRkMC05ZjM1LTExZWUtYTg5Zi04ZDYzYzQ5ZTgzNWYiLCJjdXN0b21lcklkIjoiMTM4MTQwMDAtMWRkMi0xMWIyLTgwODAtODA4MDgwODA4MDgwIn0.UnncvSwZIWTJjGpnSbGhIOWoOA1Bo_EOUYzOo0uDlwawDRk5S-tgBl9uS5FbccYebj-wuOFGMkb5qaTtNC-Agw",
//     refreshToken:
//       "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ2ZWdhZGVtb0BlbWFpbC5jb20iLCJ1c2VySWQiOiI2Nzk3ZDFiMC05ZjM1LTExZWUtYTg5Zi04ZDYzYzQ5ZTgzNWYiLCJzY29wZXMiOlsiUkVGUkVTSF9UT0tFTiJdLCJzZXNzaW9uSWQiOiI5MzQ5Mzg0Zi1iNjc0LTQ3ZjUtOTQ5Zi0yODZkZDI1NmJmNGYiLCJpc3MiOiJ0aGluZ3Nib2FyZC5pbyIsImlhdCI6MTcxMjIyNzYxOSwiZXhwIjoxNzEyODMyNDE5LCJpc1B1YmxpYyI6ZmFsc2UsImp0aSI6IjE4YTMxMDJjLTMxYzItNGE4ZS05YWU5LWM0YWY1YmRmOWNlNSJ9.mRdBv8QliEIgNIMK7rG9JHRmRTpMOBOg9gjL4fQfDsennS5VE75_h4vYAdyhEwL0aVBjEpu0qDyNop32fDHYCA",
//   })
// );
