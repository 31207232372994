import { CommonStyles } from "../../components/ui";

import OverallChart from "../../components/overalMonitor/Chart";
import OverallMap from "../../components/overalMonitor/Maps";
import baseRouter from "../../router/baseRouter";

export default function OveralMonitor(props: any) {
  //! state

  //! function

  //! render

  return (
    <div className="flex flex-col gap-3">
      <CommonStyles.TitleCardUI
        title="Giám sát tổng quan"
        subTitle={[
          {
            title: "Giám sát tổng quan",
            link: baseRouter.dashboard,
          },
        ]}
      />
      {/* //! Map */}
      <OverallMap />
      {/* //! Chart */}
      <OverallChart />
    </div>
  );
}
