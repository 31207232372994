import { urlsAPI } from "../../constants/api";
import httpService from "../../services/httpService";

class NotificationService {
  getNotifications(query: string) {
    return httpService.get(`${urlsAPI.notification.list}?${query}`);
  }
  deleteNotification(id: string) {
    return httpService.delete(`${urlsAPI.notification.detail}/${id}`);
  }
  readNotification(id: string) {
    return httpService.put(`${urlsAPI.notification.detail}/${id}/read`);
  }
}

export default new NotificationService();
