import { useNavigate } from "react-router-dom";
import ILogin from "../../../interface/ILogin";
import { useAppDispatch } from "../../../hooks/hooks";
import httpService from "../../../services/httpService";
import { getErrorMsg, showMsg } from "../../../helpers";
import { useTranslation } from "react-i18next";
import authService from "../auth.service";
import { clearUser, setUser } from "../../../store/Slices/UserSlice";

const useAuth = () => {
  //! state
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  //! function
  const handleLogin = async (values: ILogin) => {
    try {
      const data = await authService.login(values);
      if (data) {
        const token = data?.data.token;
        const refreshToken = data?.data.refreshToken;
        httpService.setToken(token, refreshToken);
        const userApi = await authService.user();
        const user = userApi.data;
        dispatch(setUser(user));
        navigate("/");
      }
    } catch (error) {
      dispatch(showMsg("error", t(`${getErrorMsg(error)}`)));
    }
  };

  const handleLogout = async () => {
    await authService.logout();
    httpService.clearLocalStorage();
    dispatch(clearUser());
    navigate("/login");

    try {
    } catch (error) {
      dispatch(showMsg("error", t(`${getErrorMsg(error)}`)));
    }
  };

  const handleChangePassword = async (values: any) => {
    try {
      const result = await authService.changePassword(values);
      if (result.status === 200) {
        const token = result?.data.token;
        const refreshToken = result?.data.refreshToken;
        httpService.setToken(token, refreshToken);
        const userApi = await authService.user();
        const user = userApi.data;
        dispatch(setUser(user));
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", t(`${getErrorMsg(error)}`)));
    }
  };

  const handleChangeProfile = async (values: any) => {
    try {
      const result = await authService.saveProfile(values);
      if (result.status === 200) {
        const userApi = await authService.user();
        const user = userApi.data;
        dispatch(setUser(user));
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", t(`${getErrorMsg(error)}`)));
    }
  };
  return {
    handleLogin,
    handleLogout,
    handleChangePassword,
    handleChangeProfile,
  };
};

export default useAuth;
