import { Routes, Route } from "react-router-dom";

import DefaultLayout from "../layouts/DefaultLayout";
import EmptyLayout from "../layouts/EmptyLayout";

import LoginPage from "../pages/login/Login";

import PrivatePage from "./PrivatePage";
import OveralMonitor from "../pages/overalMonitor/Index";
import DetailSation from "../pages/[detailStation]";
import Users from "../pages/manageSystems/users";
import baseRouter from "./baseRouter";
import Station from "../pages/station";
import StationSettings from "../pages/station/stationSettings";
import Tickets from "../pages/tickets";
import TicketStation from "../pages/tickets/ticketStaion";
import SettingPassword from "../pages/settingPassword";
import Profile from "../pages/Profile";
import StationLMMN from "../pages/stationLMMN";
import StationCDRC from "../pages/StationCDRC";
import SMS from "../pages/sms";
import Camera from "../pages/camera";
import PageAccessProvider from "../providers/PageAccessProvider";
import { Roles } from "../constants/Index";
import ListNotification from "../components/notificationDashboard/ListNotification";
import SMSGroupPhone from "../pages/sms/smsGroupPhone";

const Router = () => {
  return (
    <Routes>
      <Route path="/login" element={<EmptyLayout />}>
        <Route path="" element={<LoginPage />} />
      </Route>
      <Route element={<PrivatePage />}>
        <Route path="/" element={<DefaultLayout />}>
          <Route path="" element={<OveralMonitor />} />
          <Route path={baseRouter.station} element={<Station />} />
          <Route path={baseRouter.stationLMMN} element={<StationLMMN />} />
          <Route path={baseRouter.stationCDRC} element={<StationCDRC />} />

          <Route path={baseRouter.camera} element={<Camera />} />

          <Route
            path={`${baseRouter.stationSettings}/:id`}
            element={
              <PageAccessProvider accessRole={[Roles.ADMIN, Roles.CONFIG]}>
                <StationSettings />
              </PageAccessProvider>
            }
          />
          <Route
            path={`${baseRouter.station}/:id`}
            element={<DetailSation />}
          />
          <Route
            path={baseRouter.user}
            element={
              <PageAccessProvider accessRole={[Roles.ADMIN]}>
                <Users />
              </PageAccessProvider>
            }
          />

          <Route
            path={baseRouter.tickets}
            element={
              <PageAccessProvider accessRole={[Roles.ADMIN, Roles.CONFIG]}>
                <Tickets />
              </PageAccessProvider>
            }
          />
          <Route
            path={baseRouter.sms}
            element={
              <PageAccessProvider accessRole={[Roles.ADMIN, Roles.CONFIG]}>
                <SMS />
              </PageAccessProvider>
            }
          />
          <Route
            path={`${baseRouter.sms}${baseRouter.smsGroupPhone}`}
            element={
              <PageAccessProvider accessRole={[Roles.ADMIN, Roles.CONFIG]}>
                <SMSGroupPhone />
              </PageAccessProvider>
            }
          />

          <Route
            path={`${baseRouter.tickets}/:id`}
            element={
              <PageAccessProvider accessRole={[Roles.ADMIN, Roles.CONFIG]}>
                <TicketStation />
              </PageAccessProvider>
            }
          />

          <Route
            path={baseRouter.settingPassword}
            element={<SettingPassword />}
          />
          <Route path={baseRouter.profile} element={<Profile />} />
          <Route
            path={baseRouter.notifications}
            element={<ListNotification />}
          />

          <Route path="*" element={<OveralMonitor />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default Router;
