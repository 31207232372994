import { urlsAPI } from "../../constants/api";
import httpService from "../../services/httpService";

class TelemtryService {
  getTimeSeries(entityType: string, entityId: string, query: string) {
    return httpService.get(
      urlsAPI.telemetry.getTimeseries(entityType, entityId, query)
    );
  }

  getAttributeScope(
    entityType: string,
    entityId: string,
    scope: string,
    query?: string
  ) {
    return httpService.get(
      urlsAPI.telemetry.getAttributeScope(entityType, entityId, scope, query)
    );
  }

  getAttributeKey(entityType: string, entityId: string, query?: string) {
    return httpService.get(
      urlsAPI.telemetry.getAttributeKey(entityType, entityId, query)
    );
  }

  postDeviceTele(device: string, scope: string, body: any) {
    return httpService.post(
      urlsAPI.telemetry.postDeviceTele(device, scope),
      body
    );
  }
}

export default new TelemtryService();
