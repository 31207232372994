import { urlsAPI } from "../../constants/api";
import httpService from "../../services/httpService";
import { IUser } from "../auth/user.interface";

class UserService {
  getCustomerUsers(customerID: string, query: string) {
    return httpService.get(urlsAPI.user.getCustomerUsers(customerID, query));
  }
  getDetailUser(userID: string) {
    return httpService.get(`${urlsAPI.user.getDetailUser}/${userID}`);
  }
  updateAndCreateUser(user: IUser, sendActivationMail = false) {
    return httpService.post(
      `${urlsAPI.user.getDetailUser}?sendActivationMail=${sendActivationMail}`,
      user
    );
  }

  deleteUser(userID: string) {
    return httpService.delete(`${urlsAPI.user.getDetailUser}/${userID}`);
  }

  getActivationLink(userID: string) {
    return httpService.get(
      `${urlsAPI.user.getDetailUser}/${userID}/activationLink`
    );
  }
}

export default new UserService();
