import { useEffect, useState } from "react";
import { CommonStyles } from "../../ui";
import { useGetRelationInfo } from "../../../modules/relation/hooks/useGetRelationInfo";
import { ASSET_LMMN, ASSET_TYPE, ENTITY_TYPE } from "../../../constants/Index";
import telemetryService from "../../../modules/telemetry/telemetry.service";
import { parseQueryString } from "../../../helpers";
import _ from "lodash";
import { IAttributeScope } from "../../../modules/telemetry/attributeScope.interface";
import { useNavigate } from "react-router-dom";
import baseRouter from "../../../router/baseRouter";

interface IRecord {
  stt: number;
  id: string;
  name: string;
  lat: IAttributeScope;
  lon: IAttributeScope;
  active: IAttributeScope;
}
export default function LMMNStation() {
  //! station
  const navigate = useNavigate();
  const { data: dCDRC, isLoading: loadingCDRC } = useGetRelationInfo({
    fromId: ASSET_LMMN,
    fromType: ASSET_TYPE,
  });
  const [dataTable, setDataTable] = useState<IRecord[]>([]);
  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
    },
    {
      title: "Mã",
      dataIndex: "name",
      key: "code",
      render: (_: any, record: IRecord) => {
        return <>{record.name.split("/")[0]}</>;
      },
    },
    {
      title: "Tên trạm",
      dataIndex: "name",
      key: "name",
      render: (_: any, record: IRecord) => {
        return <>{record.name.split("/")[1]}</>;
      },
    },
    {
      title: "Vĩ độ",
      dataIndex: "lat",
      key: "lat",
      render: (_: any, record: IRecord) => {
        return <>{record.lat.value}</>;
      },
    },
    {
      title: "Kinh độ",
      dataIndex: "lon",
      key: "lon",
      render: (_: any, record: IRecord) => {
        return <>{record.lon.value}</>;
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "active",
      key: "active",
      render: (_: any, record: IRecord) => {
        const status = record.active.value ? "Online" : "Offline";
        const color = record.active.value ? "bg-[#52C41A]" : "bg-[#E7E7EE]";

        return (
          <div className="flex items-center gap-1">
            <div className={`w-1.5 h-1.5  rounded-full ${color}`}></div>
            <p>{status}</p>
          </div>
        );
      },
    },
  ];
  //! function
  useEffect(() => {
    const fetchData = async () => {
      const newData: any = [];
      if (dCDRC?.data && dCDRC.data.length > 0) {
        await Promise.all(
          dCDRC?.data.map(async (device, index) => {
            const attributes = await telemetryService.getAttributeKey(
              ENTITY_TYPE,
              device.to.id,
              parseQueryString({
                keys: "lat,lon,active",
              })
            );

            const itemWithLatKey = attributes.data?.find(
              (item: any) => item?.key === "lat"
            );
            const itemWithLonKey = attributes.data?.find(
              (item: any) => item?.key === "lon"
            );
            const itemWithActiveKey = attributes.data?.find(
              (item: any) => item?.key === "active"
            );

            newData.push({
              stt: index + 1,
              id: device.to.id,
              name: device.toName,
              lat: itemWithLatKey,
              lon: itemWithLonKey,
              active: itemWithActiveKey,
            });
          })
        );
        setDataTable(newData);
      }
    };
    fetchData();
  }, [loadingCDRC]);

  const onClickRow = (record: IRecord) => {
    navigate(`${baseRouter.stationSettings}/${record.id}`);
  };

  //! render
  return (
    <CommonStyles.CardResize title="Cấu hình lượng mưa và mực nước">
      <CommonStyles.TableUI
        columns={columns}
        dataSource={_.sortBy(dataTable, ["stt"])}
        rowClassName={"cursor-pointer"}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              onClickRow(record);
            },
          };
        }}
        scroll={{
          x: 1000,
        }}
      />
    </CommonStyles.CardResize>
  );
}
