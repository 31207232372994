import { isEmpty } from "lodash";
import { useAppSelector } from "../hooks/hooks";
import { Navigate, Outlet } from "react-router-dom";

const PrivatePage = (props: any) => {
  const { children } = props;
  const user = useAppSelector((state) => state.user.user);
  if (isEmpty(user)) {
    return <Navigate to={"/login"} />;
  }
  return children ? children : <Outlet />;
};

export default PrivatePage;
