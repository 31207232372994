import React, { ReactNode } from "react";
import CommonIcons from "../../../assets/icons";
import ButtonUI from "../button/Button";
import TypographyUI from "../typography/Typography";

interface IProps {
  title: string;
  handleCheck?: () => void;
  handleClose?: () => void;
  children?: ReactNode;
  Icon?: any;
  className?: string;
  extra?: string | React.ReactNode;
}
export default function SubCard(props: IProps) {
  //! state
  const {
    title,
    handleCheck,
    handleClose,
    children,
    Icon,
    className,
    extra,
    ...res
  } = props;
  //! function
  //! render
  return (
    <div
      className={`flex flex-col bg-white p-3 gap-5 h-full rounded-lg ${className}`}
    >
      <div className="flex items-center justify-between">
        <TypographyUI className="flex font-medium gap-2">
          {Icon && <Icon />}
          {title}
        </TypographyUI>
        <div className=" flex gap-1">{extra && extra}</div>
      </div>
      {children}
    </div>
  );
}
