import { Form } from "antd";
import { CommonStyles } from "../../../../components/ui";
import ResponseUI from "../../../../constants/response";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import Regex from "../../../../constants/regex";
import { useAppDispatch } from "../../../../hooks/hooks";
import { getErrorMsg, parseQueryString, showMsg } from "../../../../helpers";
import smsService from "../../../../modules/sms/sms.service";
import useGetGroupPhones from "../../../../modules/sms/hooks/useGetGroupPhones";

interface IProps {
  toggle: () => void;
  refetch: () => void;
  detailGroup: {
    id: null | string;
    type: "info" | "edit" | "create";
  };
}
export default function ModalGroupPhone(props: IProps) {
  //! state
  const { toggle, detailGroup, refetch } = props;
  const { data, isLoading } = useGetGroupPhones(
    parseQueryString({ id_group: detailGroup.id }),
    !!detailGroup.id
  );
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [phones, setPhones] = useState<string[]>([]);
  const [errorField, setErrorField] = useState<string>("");

  const initialValues = {
    name: data?.data.page_contents[0].name ?? "",
    phone_number: data?.data.page_contents[0].phone_number ?? [],
  };
  //! function
  const onCloseTag = (index: number) => {
    const newValue = [...phones];
    newValue.splice(index, 1);
    setPhones(newValue);
  };
  const onChangePhones = (value: string) => {
    if (isEmpty(value)) {
      return;
    }
    if (!String(value).toLocaleLowerCase().match(Regex.phoneVN)) {
      setErrorField("Số điện thoại không hợp lệ!");
      return;
    }
    const newValue = [...phones];
    newValue.push(value);
    setPhones(newValue);
    setErrorField("");
  };
  const handleSubmit = async (values: any) => {
    if (phones.length === 0) {
      setErrorField("Số điện thoại không được để trống!");
      return;
    }
    const dataCreate = {
      name: values.name,
      phone_number: phones,
      status: true,
    };
    try {
      if (detailGroup.id) {
        const result = await smsService.setGroupPhone(
          dataCreate,
          detailGroup.id
        );
      } else {
        const result = await smsService.setGroupPhone(dataCreate);
      }
      refetch();
      toggle();
      dispatch(showMsg("success", "Thành công"));
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
    setPhones(data?.data.page_contents[0].phone_number ?? []);
  }, [form, isLoading]);
  //! render
  return (
    <Form
      form={form}
      initialValues={initialValues}
      layout="vertical"
      onFinish={handleSubmit}
      className="my-5"
      onKeyDown={(e) => (e.key == "Enter" ? e.preventDefault() : "")}
    >
      <CommonStyles.FormFieldUI
        name="name"
        label="Tên nhóm"
        rules={[
          {
            required: true,
            message: "Tên nhóm không được trống!",
          },
        ]}
        component={
          <CommonStyles.InputUI
            placeholder="Nhập tên nhóm ..."
            disabled={detailGroup.type === "info"}
          />
        }
      />
      <CommonStyles.FormFieldUI
        name="phones"
        label="Số điện thoại"
        component={
          <CommonStyles.InputMulti
            placeholder="Nhập số điện thoại ..."
            defaultValue={phones}
            onChangeMulti={onChangePhones}
            errorField={errorField}
            onCloseTag={onCloseTag}
            disabled={detailGroup.type === "info"}
          />
        }
      />
      {detailGroup.type !== "info" && (
        <div className="flex justify-end items-center gap-3">
          <CommonStyles.ButtonUI
            ghost
            danger
            className={`${ResponseUI.button} w-24`}
            onClick={toggle}
          >
            Hủy
          </CommonStyles.ButtonUI>
          <CommonStyles.ButtonUI
            action="submit"
            className={`${ResponseUI.button} w-24`}
          >
            Xác nhận
          </CommonStyles.ButtonUI>
        </div>
      )}
    </Form>
  );
}
