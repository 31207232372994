import { getErrorMsg, showMsg } from "../../../../helpers";
import authService from "../../../../modules/auth/auth.service";

import userService from "../../../../modules/user/user.service";

export const ActionSubmit = async (
  user: any,
  actionAfter: () => any,
  dispatch: any,
  type: "create" | "update"
) => {
  try {
    const userUpdate = await userService.updateAndCreateUser(user as any);
    if (!!userUpdate) {
      if (type === "create") {
        const resultLink = await userService.getActivationLink(
          userUpdate.data?.id?.id
        );
        if (resultLink.status === 200) {
          const activateToken = resultLink.data.split("activateToken=")[1];
          const userActive = await authService.postActiveNoAuth({
            activateToken: activateToken,
            password: "123456",
          });
          if (userActive.status === 200) {
            dispatch(showMsg("success", "Thành công"));
            actionAfter();
          }
        }
      } else {
        dispatch(showMsg("success", "Thành công"));
        actionAfter();
      }
    }
  } catch (error) {
    dispatch(showMsg("error", getErrorMsg(error)));
  }
};
