import type { DatePickerProps, TimePickerProps } from "antd";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";

interface IProps {
  type?: "time" | "date" | "week" | "month" | "quarter" | "year";
  handleChange?: TimePickerProps["onChange"] | DatePickerProps["onChange"];
  // handleChange?: (data: any) => void;
  className?: string;
  disabled?: boolean;
  minDate?: any;
  maxDate?: any;
  placeholder?: string;
  size?: "large" | "middle" | "small";
  status?: "error" | "warning";
  variant?: "outlined" | "borderless" | "filled";
  afterOnchange?: () => void;
  value?: any;
}
export default function DatePickerUI(props: IProps) {
  //! state
  const {
    type = "date",
    handleChange,
    className,
    disabled,
    maxDate,
    minDate,
    placeholder,
    size,
    status,
    variant,
    afterOnchange,
    value,
  } = props;
  //! function
  const handleChangeTime:
    | TimePickerProps["onChange"]
    | DatePickerProps["onChange"] = (time, timeString) => {
    handleChange && handleChange(time, timeString);
    if (afterOnchange) {
      afterOnchange();
    }
  };
  //! render
  if (type === "time") {
    return (
      <TimePicker
        placeholder={placeholder}
        size={size}
        onChange={handleChangeTime}
        variant={variant}
        className={className}
        disabled={disabled}
        status={status}
        minDate={minDate}
        maxDate={maxDate}
        value={dayjs(value, "HH:mm:ss")}
        format={"HH:mm:ss"}
      />
    );
  }
  if (type === "date") {
    return (
      <DatePicker
        placeholder={placeholder}
        size={size}
        onChange={handleChangeTime}
        variant={variant}
        className={className}
        disabled={disabled}
        status={status}
        minDate={minDate}
        maxDate={maxDate}
        value={dayjs(value, "DD-MM-YYYY")}
        format={"DD-MM-YYYY"}
      />
    );
  }
  return (
    <DatePicker
      picker={type}
      placeholder={placeholder}
      size={size}
      onChange={handleChangeTime}
      variant={variant}
      className={className}
      disabled={disabled}
      status={status}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
}
