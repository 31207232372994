export const skipped = (ctx: any, value: any) =>
  isNaN(ctx.p0.raw) ? value : undefined;

export const down = (ctx: any, value: any) =>
  ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;

export const segment = {
  borderColor: (ctx: any) => skipped(ctx, "rgb(0,0,0,0.2)"),
  borderDash: (ctx: any) => skipped(ctx, [6, 6]),
};
