import React, { useEffect, useState } from "react";
import { CommonStyles } from "../../../ui";
import { Form } from "antd";
import { useAppDispatch } from "../../../../hooks/hooks";
import useGetAttributeKey from "../../../../modules/telemetry/hooks/useGetAttributeKey";
import { ENTITY_TYPE, SCOPE } from "../../../../constants/Index";
import {
  getErrorMsg,
  getStatusAction,
  parseQueryString,
  showMsg,
} from "../../../../helpers";
import telemetryService from "../../../../modules/telemetry/telemetry.service";

interface IProps {
  idStation: string;
}
export default function Address(props: IProps) {
  //! station
  const { idStation } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { data, isLoading, refetch } = useGetAttributeKey(
    ENTITY_TYPE,
    idStation,
    true,
    parseQueryString({ keys: "address" })
  );
  const valueAddress = Form.useWatch("address", form);
  //! function
  const initialValues = {
    address: data?.data[0]?.value ?? "",
  };
  const handleSubmit = async () => {
    try {
      const value = form.getFieldsValue();
      const postLocate = await telemetryService.postDeviceTele(
        idStation,
        SCOPE.SHARED_SCOPE,
        value
      );
      if (postLocate.status === 200) {
        refetch();
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [isLoading]);

  //! render
  const renderExtra = () => {
    return (
      <CommonStyles.ButtonUI
        disabled={getStatusAction([data?.data[0]?.value], [valueAddress])}
        onClick={handleSubmit}
        size="small"
      >
        Hoàn thành
      </CommonStyles.ButtonUI>
    );
  };

  return (
    <CommonStyles.SubCard
      title="Thông tin địa chỉ trạm đo"
      extra={renderExtra()}
    >
      <Form
        name="AddressForm"
        form={form}
        initialValues={initialValues}
        layout="vertical"
      >
        <CommonStyles.FormFieldUI
          name={"address"}
          label="Địa chỉ"
          component={
            <CommonStyles.InputUI
              maxLength={50}
              className="h-9 border !border-s-line active:!border-main-color"
            />
          }
        />
      </Form>
    </CommonStyles.SubCard>
  );
}
