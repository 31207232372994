import ResponseUI from "../../../../constants/response";
import useToggle from "../../../../hooks/useToggle";
import ModalCreateTicket from "../../../detailStation/ModalCreateTicket";
import ModalTicket from "../../../overall/modalTicket";
import { CommonStyles } from "../../../ui";

interface IProps {
  idStation: string;
}
export default function Ticket(props: IProps) {
  //! state
  const { idStation } = props;
  const { open, shouldRender, toggle } = useToggle();
  //! function
  //! render
  return (
    <>
      <CommonStyles.SubCard
        title="Tạo ticket sự cố"
        className="justify-between pb-9"
      >
        <CommonStyles.ButtonUI
          className={`${ResponseUI.button} bg-[#42a5f5] text-wrap h-fit`}
          onClick={toggle}
        >
          Tạo ticket
        </CommonStyles.ButtonUI>
      </CommonStyles.SubCard>

      {shouldRender && (
        <CommonStyles.ModalUI
          title="Tạo ticket sự cố"
          hiddenAction
          open={open}
          toggle={toggle}
        >
          <ModalTicket
            toggle={toggle}
            idStation={String(idStation)}
            onlyCreate={true}
          />
        </CommonStyles.ModalUI>
      )}
    </>
  );
}
