import React, { useEffect, useState } from "react";
import { CommonStyles } from "../../../ui";
import useGetAttributeKey from "../../../../modules/telemetry/hooks/useGetAttributeKey";
import { ENTITY_TYPE, SCOPE } from "../../../../constants/Index";
import {
  getErrorMsg,
  getStatusAction,
  parseQueryString,
  showMsg,
} from "../../../../helpers";
import _, { isEmpty } from "lodash";
import Regex from "../../../../constants/regex";
import telemetryService from "../../../../modules/telemetry/telemetry.service";
import { useAppDispatch } from "../../../../hooks/hooks";
import smsService from "../../../../modules/sms/sms.service";

interface IProps {
  idStation: string;
}
export default function Phone(props: IProps) {
  //! state
  const { idStation } = props;
  const [phones, setPhones] = useState<string[]>([]);
  const [errorField, setErrorField] = useState<string>("");
  const dispatch = useAppDispatch();
  const { data, isLoading, refetch } = useGetAttributeKey(
    ENTITY_TYPE,
    idStation,
    true,
    parseQueryString({ keys: "Setphone" })
  );
  //! function

  const onChangePhone = (value: string) => {
    if (isEmpty(value)) {
      return;
    }
    if (!String(value).toLocaleLowerCase().match(Regex.phoneVN)) {
      setErrorField("Số điện thoại không hợp lệ!");
      return;
    }
    if (phones.includes(value)) {
      setErrorField("Số điện thoại đã tồn tại!");
      return;
    }
    const newValue = [...phones];
    newValue.push(value);
    setPhones(newValue);
    setErrorField("");
  };

  const onCloseTag = (index: number) => {
    const newValue = [...phones];
    newValue.splice(index, 1);
    setPhones(newValue);
  };

  const handleSubmit = async () => {
    try {
      if (phones.length > 5) {
        return dispatch(showMsg("error", "Tối đa 5 số điện thoại"));
      }
      const valuesPhone = phones.join(",");
      const getPhone = await smsService.getPhone(
        parseQueryString({ station: idStation })
      );
      const postSetPhone = await telemetryService.postDeviceTele(
        idStation,
        SCOPE.SHARED_SCOPE,
        {
          Setphone: valuesPhone,
        }
      );
      const updatePhoneSMS = await smsService.postPhone(
        {
          station: idStation,
          phone_number: phones,
        },
        getPhone.data.length > 0 ? `id=${getPhone.data[0].id}` : ""
      );

      if (postSetPhone.status === 200) {
        refetch();
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };
  useEffect(() => {
    const listPhone = data?.data[0]?.value
      .split(",")
      .filter((value: any) => value !== "0");
    if (listPhone && listPhone?.length > 0) {
      setPhones(listPhone);
    }
  }, [isLoading]);

  //! render
  const renderExtraAction = () => {
    return (
      <CommonStyles.ButtonUI
        onClick={handleSubmit}
        disabled={getStatusAction(
          _.map(data?.data[0]?.value?.split(","), _.trim),
          phones
        )}
        size="small"
      >
        Hoàn thành
      </CommonStyles.ButtonUI>
    );
  };

  return (
    <CommonStyles.SubCard
      title="Cài đặt số điện thoại nhận tin nhắn"
      extra={renderExtraAction()}
    >
      <CommonStyles.InputMulti
        label="Số điện thoại(Tối đa 5 số)"
        defaultValue={phones}
        onChangeMulti={onChangePhone}
        onCloseTag={onCloseTag}
        limit={5}
        errorField={errorField}
      />
    </CommonStyles.SubCard>
  );
}
