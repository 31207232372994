import { createSlice } from "@reduxjs/toolkit";

interface ToastState {
  status: boolean;
  type: "success" | "info" | "error" | "warning";
  content: string;
  duration: number;
}

// Define the initial state using that type
const initialState: ToastState = {
  status: false,
  type: "success",
  content: "",
  duration: 10,
};

export const toastSlice = createSlice({
  name: "Toast",
  initialState,
  reducers: {
    handleToast: (state, action) => {
      const { type, content, duration }: ToastState = action.payload;
      state.content = content;
      state.type = type;
      state.duration = duration;
      state.status = !state.status;
    },
  },
});

export const { handleToast } = toastSlice.actions;

export default toastSlice.reducer;
