import { useEffect } from "react";
import { CommonStyles } from "../../../ui";
import { Form } from "antd";
import { useAppDispatch } from "../../../../hooks/hooks";
import useGetAttributeKey from "../../../../modules/telemetry/hooks/useGetAttributeKey";
import { ENTITY_TYPE, SCOPE } from "../../../../constants/Index";
import {
  getErrorMsg,
  getStatusAction,
  parseQueryString,
  showMsg,
} from "../../../../helpers";
import telemetryService from "../../../../modules/telemetry/telemetry.service";
import Regex from "../../../../constants/regex";
interface IProps {
  idStation: string;
}
export default function LMMNSoftware(props: IProps) {
  //! state
  const { idStation } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const keys = [
    "waterMainLevelThresholdHigh",
    "rainfallDayThresholdHigh",
    "instantaneousRainfallThresholdHigh",
    "totalRainfallThresholdHigh",
    "hourlyRainfallThresholdHigh",
  ];
  const { data, isLoading, refetch } = useGetAttributeKey(
    ENTITY_TYPE,
    idStation,
    true,
    parseQueryString({
      keys: keys.join(","),
    })
  );

  const waterMainLevelThresholdHigh = data?.data.find(
    (item) => item.key === "waterMainLevelThresholdHigh"
  );

  const rainfallDayThresholdHigh = data?.data.find(
    (item) => item.key === "rainfallDayThresholdHigh"
  );
  const instantaneousRainfallThresholdHigh = data?.data.find(
    (item) => item.key === "instantaneousRainfallThresholdHigh"
  );

  const totalRainfallThresholdHigh = data?.data.find(
    (item) => item.key === "totalRainfallThresholdHigh"
  );
  const hourlyRainfallThresholdHigh = data?.data.find(
    (item) => item.key === "hourlyRainfallThresholdHigh"
  );

  const initialValues = {
    instantaneousRainfallThresholdHigh:
      Number(instantaneousRainfallThresholdHigh?.value) ?? 0,

    hourlyRainfallThresholdHigh:
      Number(hourlyRainfallThresholdHigh?.value) ?? 0,

    rainfallDayThresholdHigh: Number(rainfallDayThresholdHigh?.value) ?? 0,

    totalRainfallThresholdHigh: Number(totalRainfallThresholdHigh?.value) ?? 0,

    waterMainLevelThresholdHigh:
      Number(waterMainLevelThresholdHigh?.value) ?? 0,
  };

  const initValue = [
    Number(instantaneousRainfallThresholdHigh?.value) ?? 0,
    Number(hourlyRainfallThresholdHigh?.value) ?? 0,
    Number(rainfallDayThresholdHigh?.value) ?? 0,
    Number(totalRainfallThresholdHigh?.value) ?? 0,
    Number(waterMainLevelThresholdHigh?.value) ?? 0,
  ];
  const valueInstantaneousRainfallThresholdHigh = Form.useWatch(
    "instantaneousRainfallThresholdHigh",
    form
  );
  const valueHourlyRainfallThresholdHigh = Form.useWatch(
    "hourlyRainfallThresholdHigh",
    form
  );
  const valueRainfallDayThresholdHigh = Form.useWatch(
    "rainfallDayThresholdHigh",
    form
  );
  const valueTotalRainfallThresholdHigh = Form.useWatch(
    "totalRainfallThresholdHigh",
    form
  );
  const valueWaterMainLevelThresholdHigh = Form.useWatch(
    "waterMainLevelThresholdHigh",
    form
  );

  //! function
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [isLoading, initialValues]);

  const handleSubmit = async () => {
    const values = form.getFieldsValue();
    const valid = form.getFieldsError();
    let check = true;
    valid.forEach((element) => {
      if (element.errors.length > 0) {
        check = false;
      }
    });
    if (!check) {
      return;
    }
    try {
      const postSoftware = await telemetryService.postDeviceTele(
        idStation,
        SCOPE.SHARED_SCOPE,
        values
      );
      if (postSoftware.status === 200) {
        refetch();
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };
  //! render
  const renderExtra = () => {
    return (
      <CommonStyles.ButtonUI
        disabled={getStatusAction(initValue, [
          Number(valueInstantaneousRainfallThresholdHigh),
          Number(valueHourlyRainfallThresholdHigh),
          Number(valueRainfallDayThresholdHigh),
          Number(valueTotalRainfallThresholdHigh),
          Number(valueWaterMainLevelThresholdHigh),
        ])}
        onClick={handleSubmit}
        size="small"
      >
        Hoàn thành
      </CommonStyles.ButtonUI>
    );
  };
  return (
    <CommonStyles.SubCard
      title="Cập nhật ngưỡng cảnh báo phần mềm"
      extra={renderExtra()}
    >
      <Form
        form={form}
        name="deviceLMMNN"
        initialValues={initialValues}
        // onFinish={handleSubmit}
        layout="vertical"
      >
        <div className="md:grid md:grid-cols-2 gap-3 items-center">
          <CommonStyles.FormFieldUI
            name={"instantaneousRainfallThresholdHigh"}
            label="Lượng mưa tức thời (mm)"
            rules={[
              {
                required: true,
                message: "Lượng mưa tức thời không được trống!",
              },
              {
                pattern: Regex.number,
                message: "Giá trị cần là số!",
              },
            ]}
            component={
              <CommonStyles.InputUI
                maxLength={50}
                className="h-9 border !border-s-line active:!border-main-color"
              />
            }
          />
          <CommonStyles.FormFieldUI
            name={"hourlyRainfallThresholdHigh"}
            label="Lượng mưa theo giờ (mm)"
            rules={[
              {
                required: true,
                message: "Lượng mưa theo giờ không được trống!",
              },
              {
                pattern: Regex.number,
                message: "Giá trị cần là số!",
              },
            ]}
            component={
              <CommonStyles.InputUI
                maxLength={50}
                className="h-9 border !border-s-line active:!border-main-color"
              />
            }
          />
          <CommonStyles.FormFieldUI
            name={"rainfallDayThresholdHigh"}
            label="Lượng mưa trong ngày (mm)"
            rules={[
              {
                required: true,
                message: "Lượng mưa trong ngày không được trống!",
              },
              {
                pattern: Regex.number,
                message: "Giá trị cần là số!",
              },
            ]}
            component={
              <CommonStyles.InputUI
                maxLength={50}
                className="h-9 border !border-s-line active:!border-main-color"
              />
            }
          />
          <CommonStyles.FormFieldUI
            name={"totalRainfallThresholdHigh"}
            label="Tổng lượng mưa (mm)"
            rules={[
              {
                required: true,
                message: "Tổng lượng mưa không được trống!",
              },
              {
                pattern: Regex.number,
                message: "Giá trị cần là số!",
              },
            ]}
            component={
              <CommonStyles.InputUI
                maxLength={50}
                className="h-9 border !border-s-line active:!border-main-color"
              />
            }
          />

          <CommonStyles.FormFieldUI
            name={"waterMainLevelThresholdHigh"}
            label="Mực nước (cm)"
            className="col-span-2"
            rules={[
              {
                required: true,
                message: "Mực nước không được trống!",
              },
              {
                pattern: Regex.number,
                message: "Giá trị cần là số!",
              },
            ]}
            component={
              <CommonStyles.InputUI
                maxLength={50}
                className="h-9 border !border-s-line active:!border-main-color"
              />
            }
          />
        </div>

        {/* <CommonStyles.ButtonUI className="col-span-2 w-full" action="submit">
          Cập nhật
        </CommonStyles.ButtonUI> */}
      </Form>
    </CommonStyles.SubCard>
  );
}
