import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { CommonStyles } from "../../ui";
import {
  ENTITY_TYPE,
  SCOPE,
  TicketStatus,
  statusTicket,
} from "../../../constants/Index";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { getErrorMsg, parseQueryString, showMsg } from "../../../helpers";
import { useTranslation } from "react-i18next";
import telemetryService from "../../../modules/telemetry/telemetry.service";
import useGetAttributeKey from "../../../modules/telemetry/hooks/useGetAttributeKey";
import ITicket from "../../../interface/ITicket";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import useGetAttributeScope from "../../../modules/telemetry/hooks/useGetAttributeScope";
import ResponseUI from "../../../constants/response";
interface IProps {
  idStation: string;
  toggle?: () => void;
  refetch?: () => void;
  idTicket?: string;
  type?: "edit" | "info";
  onlyCreate?: boolean;
}
export default function ModalTicket(props: IProps) {
  //! state
  const {
    idStation,
    toggle,
    refetch,
    idTicket,
    type = "edit",
    onlyCreate,
  } = props;
  const [form] = Form.useForm();
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [ticketDetail, setTicketDetail] = useState<ITicket>();
  const { data, isLoading } = useGetAttributeScope(
    ENTITY_TYPE,
    String(idStation),
    SCOPE.SERVER_SCOPE,
    true,
    parseQueryString({
      keys: "hisTicket",
    })
  );
  const initialValues = {
    id: idStation,
    title: ticketDetail ? ticketDetail.title : "",
    description: ticketDetail ? ticketDetail.description : "",
    status: ticketDetail ? ticketDetail.status : statusTicket.receive,
  };
  //! function
  const handleSubmit = async (values: any) => {
    try {
      const ticketData = (data?.data[0]?.value as any) ?? [];
      let tickets = [...ticketData];

      if (idTicket) {
        const ticketInfo = tickets?.findIndex(
          (item: ITicket) => item.id === idTicket
        );
        if (ticketInfo > -1) {
          tickets[ticketInfo].title = values.title;
          tickets[ticketInfo].description = values.description;
          tickets[ticketInfo].status = values.status;
          tickets[ticketInfo].userHandle = user;
          tickets[ticketInfo].createTime = moment().format(
            "DD-MM-YYYY HH:mm:ss"
          );
        }
      } else {
        tickets = [
          ...tickets,
          {
            ...values,
            id: uuidv4(),
            modifyTime: moment().format("DD-MM-YYYY HH:mm:ss"),
            createTime: moment().format("DD-MM-YYYY HH:mm:ss"),
            userHandle: values.status !== statusTicket.receive && user,
            status: TicketStatus[0].value,
          },
        ];
      }
      const postSetPhone = await telemetryService.postDeviceTele(
        idStation,
        SCOPE.SERVER_SCOPE,
        { hisTicket: tickets }
      );
      if (postSetPhone.status === 200) {
        refetch && refetch();
        toggle && toggle();
        dispatch(showMsg("success", t(`success`)));
      }
    } catch (error) {
      dispatch(showMsg("error", t(`${getErrorMsg(error)}`)));
    }
  };
  useEffect(() => {
    if (idTicket) {
      const tickets = data?.data[0]?.value as any;
      const ticketInfo = tickets?.find((item: ITicket) => item.id === idTicket);
      setTicketDetail(ticketInfo);
    }
  }, [isLoading]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);
  //! render
  return (
    <Form
      form={form}
      name="modalTicketForm"
      initialValues={initialValues}
      onFinish={handleSubmit}
      layout="vertical"
      className="grid grid-cols-1"
    >
      <CommonStyles.FormFieldUI
        name={"title"}
        label="Tiêu đề"
        rules={[
          {
            required: true,
            message: "Tiêu đề không được để trống!",
          },
        ]}
        component={
          <CommonStyles.InputUI
            maxLength={50}
            className="h-9 border !border-s-line active:!border-main-color"
            disabled={type === "info"}
          />
        }
      />

      <CommonStyles.FormFieldUI
        name={"description"}
        label="Mô tả"
        rules={[
          {
            required: true,
            message: "Mô tả không được để trống!",
          },
        ]}
        component={
          <CommonStyles.TextAreaUI
            rows={8}
            className="h-9 border !border-s-line active:!border-main-color"
            disabled={type === "info"}
          />
        }
      />
      {!onlyCreate && (
        <CommonStyles.FormFieldUI
          name={"status"}
          label="Trạng thái"
          component={
            <CommonStyles.SelectUI
              disabled={type === "info"}
              options={TicketStatus}
            />
          }
        />
      )}

      {type && type !== "info" && (
        <div className="flex gap-3 justify-end items-center">
          <CommonStyles.ButtonUI
            className={`${ResponseUI.button} w-24`}
            danger
            ghost
            onClick={toggle}
          >
            Hủy
          </CommonStyles.ButtonUI>
          <CommonStyles.ButtonUI
            className={`${ResponseUI.button} w-24`}
            action="submit"
          >
            Xác nhận
          </CommonStyles.ButtonUI>
        </div>
      )}
    </Form>
  );
}
