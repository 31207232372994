import { Table } from "antd";
import type { TableColumnsType, TableProps } from "antd";

interface IProps extends TableProps {
  columns: TableColumnsType<any>;
  dataSource: any;
  hasPagination?: boolean;
}
export default function TableUI(props: IProps) {
  //! state
  const { columns, dataSource, hasPagination = false, ...res } = props;
  //! function
  //! render
  return (
    <div>
      <Table
        locale={{ emptyText: "No data" }}
        columns={columns}
        dataSource={dataSource}
        pagination={hasPagination ? undefined : false}
        // className="min-h-[450px]"
        {...res}
      />
    </div>
  );
}
