import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { showMsg } from "../helpers";
interface IProps {
  accessRole?: string[];
  children: any;
}
export default function PageAccessProvider(props: IProps) {
  //! state
  const { accessRole, children } = props;

  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  //! function
  //! render
  if (accessRole && !accessRole.includes(String(user?.lastName))) {
    dispatch(showMsg("error", "Bạn không có quyền truy cập!"));
    setTimeout(() => {
      navigate("/");
    }, 1000);
    return;
  }
  return children;
}
