import { Pagination, PaginationProps } from "antd";

interface IProps extends PaginationProps {
  page: number;
  pageSize: number;
  totalElement?: number;
  potision?: "start" | "end" | "center";
  onChangePage?: (page: number) => void;
}
export default function PaginationUI(props: IProps) {
  //! state
  const {
    page,
    pageSize = 10,
    totalElement,
    potision = "end",
    onChangePage,
  } = props;
  //! function
  //! render
  return (
    <div className={`flex justify-end  mt-3`}>
      <Pagination
        current={page}
        total={totalElement}
        pageSize={pageSize}
        onChange={(page) => {
          onChangePage && onChangePage(page);
        }}
        responsive
        showSizeChanger={false}
      />
    </div>
  );
}
