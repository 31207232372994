import React, { useState } from "react";
import useGetAttributeKey from "../../../modules/telemetry/hooks/useGetAttributeKey";
import {
  ENTITY_TYPE,
  SCOPE,
  TicketStatus,
  statusTicket,
} from "../../../constants/Index";
import {
  getErrorMsg,
  limitTextString,
  parseQueryString,
  showMsg,
} from "../../../helpers";
import { CommonStyles } from "../../ui";
import ITicket from "../../../interface/ITicket";
import useToggle from "../../../hooks/useToggle";
import telemetryService from "../../../modules/telemetry/telemetry.service";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import moment from "moment";
import ModalTicket from "../../overall/modalTicket";

interface IProps {
  idStation: string;
  data: ITicket[];
  isLoading: boolean;
  refetch: () => void;
}
export default function TikcetStationTable(props: IProps) {
  //! state
  const { idStation, data, isLoading, refetch } = props;
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const { open, shouldRender, toggle } = useToggle();
  const {
    open: openInfo,
    shouldRender: shouldRenderInfo,
    toggle: toggleInfo,
  } = useToggle();

  const [idTicket, setIdTicket] = useState<{
    id: string;
    type: "edit" | "info";
  }>();

  //! function
  const postTicket = async (ticket: any, toggle?: any) => {
    try {
      const postSettime = await telemetryService.postDeviceTele(
        idStation,
        SCOPE.SERVER_SCOPE,
        {
          hisTicket: ticket,
        }
      );
      if (postSettime.status === 200) {
        refetch();
        toggle && toggle();
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };
  const confirmStatus = async () => {
    const indexTicket = data.findIndex(
      (item: ITicket) => item.id === idTicket?.id
    );
    if (indexTicket > -1) {
      data[indexTicket].modifyTime = moment().format("DD-MM-YYYY HH:mm:ss");
      data[indexTicket].userHandle = user;
      if (idTicket?.type === "edit") {
        data[indexTicket].status = statusTicket.done;
      }
      if (idTicket?.type === "info") {
        data[indexTicket].status = statusTicket.pending;
      }

      postTicket(data, toggle);
    }
  };
  const handleDelete = async (id: string) => {
    const itemFind = data.findIndex((item) => item.id === id);
    if (itemFind > -1) {
      data.splice(itemFind, 1);
      postTicket(data);
    }
  };
  const handleEdit = (id: string) => {
    setIdTicket({ id, type: "edit" });
    toggleInfo();
  };
  const handleInfo = (id: string) => {
    setIdTicket({
      id,
      type: "info",
    });
    toggleInfo();
  };
  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      render: (_: any, record: ITicket, index: number) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
      key: "title",
      render: (_: any, record: ITicket) => {
        return (
          <div title={record.title}>{limitTextString(record.title, 20)}</div>
        );
      },
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
      render: (_: any, record: ITicket) => {
        return (
          <div title={record.description}>
            {limitTextString(record.description, 20)}
          </div>
        );
      },
    },
    {
      title: "Thời gian tạo",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "Thời gian xử lý",
      dataIndex: "modifyTime",
      key: "modifyTime",
      render: (_: any, record: ITicket) => {
        return (
          <div>
            {record.status === statusTicket.done ? record.modifyTime : ""}
          </div>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (_: any, record: ITicket) => {
        let color = "";
        if (record.status === statusTicket.done) color = "bg-[#52C41A]";
        if (record.status === statusTicket.receive) color = "bg-[#E3E7ED]";
        if (record.status === statusTicket.pending) color = "bg-[#64b5f6]";

        return (
          <div className="flex items-center gap-1 ">
            <div className={`w-1.5 h-1.5 ${color} rounded-full`}></div>
            <p>{record.status}</p>
          </div>
        );
      },
    },

    {
      title: "Người thực hiện",
      dataIndex: "userHanlde",
      key: "userHanlde",
      render: (_: any, record: ITicket) => {
        return (
          <CommonStyles.TooltipUI title={String()}>
            {limitTextString(String(record.userHandle?.email ?? ""), 20)}
          </CommonStyles.TooltipUI>
        );
      },
    },
    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      render: (_: any, record: ITicket) => {
        return (
          <>
            {record.status === statusTicket.receive && (
              <CommonStyles.ButtonUI
                onClick={() => {
                  toggle();
                  setIdTicket({
                    id: record.id,
                    type: "info",
                  });
                }}
                className="px-4 bg-[#DBE8FE] text-main-color border-main-color hover:!bg-main-color"
                size="small"
              >
                Tiếp nhận
              </CommonStyles.ButtonUI>
            )}
            {record.status === statusTicket.pending && (
              <CommonStyles.ButtonUI
                onClick={() => {
                  toggle();
                  setIdTicket({
                    id: record.id,
                    type: "edit",
                  });
                }}
                className="px-4 bg-[#E2F7E1] hover:!bg-[#45B441] text-[#45B441] border-[#45B441]"
                size="small"
              >
                Hoàn thành
              </CommonStyles.ButtonUI>
            )}
          </>
        );
      },
    },
    {
      title: "Thao tác",
      dataIndex: "action1",
      key: "action1",
      render: (_: any, record: ITicket) => {
        return (
          <p>
            {record.status === String(TicketStatus[2].value) ? (
              <CommonStyles.ButtonAction
                handleDelete={() => {
                  handleDelete(record.id);
                }}
                handleInfo={() => {
                  handleInfo(record.id);
                }}
              />
            ) : (
              <CommonStyles.ButtonAction
                handleDelete={() => {
                  handleDelete(record.id);
                }}
                handleEdit={() => {
                  handleEdit(record.id);
                }}
                handleInfo={() => {
                  handleInfo(record.id);
                }}
              />
            )}
          </p>
        );
      },
    },
  ];
  //! render
  return (
    <div>
      <CommonStyles.TableUI
        columns={columns}
        dataSource={data ?? []}
        hasPagination
        loading={isLoading}
        scroll={{
          x: 1200,
        }}
      />
      {shouldRender && (
        <CommonStyles.ModalUI
          title="Bạn muốn thay đổi trạng thái ticket ?"
          toggle={toggle}
          open={open}
          type="error"
          onConfirm={confirmStatus}
        />
      )}
      {shouldRenderInfo && (
        <CommonStyles.ModalUI
          title="Thông tin ticket"
          toggle={toggleInfo}
          open={openInfo}
          hiddenAction
        >
          <ModalTicket
            idStation={idStation}
            idTicket={idTicket?.id}
            type={idTicket?.type}
            refetch={refetch}
            toggle={toggleInfo}
          />
        </CommonStyles.ModalUI>
      )}
    </div>
  );
}
