import React, { useEffect, useRef, useState } from "react";
import { CommonStyles } from "../../../../ui";
import { ColorsChart } from "../../../../../constants/color";
import useGetDetailDevice from "../../../../../modules/device/hooks/useGetDetailDevice";
import { getErrorMsg, parseQueryString, showMsg } from "../../../../../helpers";
import telemetryService from "../../../../../modules/telemetry/telemetry.service";
import { ENTITY_TYPE, SCOPE } from "../../../../../constants/Index";
import { useAppDispatch } from "../../../../../hooks/hooks";
import { isEmpty } from "lodash";
import useGetAttributeScope from "../../../../../modules/telemetry/hooks/useGetAttributeScope";

interface IProps {
  idStation: string;
  toggle: () => void;
  refetch: () => void;
}
export default function ModalPickColor(props: IProps) {
  //! state
  const { idStation, toggle, refetch } = props;
  const dispatch = useAppDispatch();
  const [keyActive, setKeyActive] = useState(ColorsChart[0].value);
  const { data: dataDevice, isLoading: loadingDevice } = useGetDetailDevice(
    String(idStation)
  );

  const { data: dataColor, isLoading: loadingColor } = useGetAttributeScope(
    ENTITY_TYPE,
    String(idStation),
    SCOPE.SHARED_SCOPE,
    true,
    parseQueryString({ keys: "colorChart" })
  );

  useEffect(() => {
    if (!!dataColor?.data) {
      setKeyActive(dataColor.data[0]?.value ?? "");
    }
  }, [loadingColor]);
  //! function
  const handleClickColor = (item: { key: string; value: string }) => {
    setKeyActive(item.key);
  };
  const handlePickColor = async () => {
    try {
      if (isEmpty(keyActive)) {
        dispatch(showMsg("warning", "Bạn chưa chọn màu!"));
        return;
      }

      const postColor = await telemetryService.postDeviceTele(
        idStation,
        SCOPE.SHARED_SCOPE,
        {
          colorChart: keyActive,
        }
      );
      if (postColor.status === 200) {
        toggle && toggle();
        refetch && refetch();
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };
  //! render
  const renderOptionColors = () => {
    return (
      <div className="flex gap-3 my-3">
        {ColorsChart.map((item, index) => {
          return (
            <CommonStyles.ButtonUI
              onClick={() => {
                handleClickColor(item);
              }}
              style={{
                backgroundColor: item.value,
                borderWidth: 4,
                borderColor: keyActive === item.key ? "white" : "transparent",
                borderRadius: keyActive === item.key ? 10 : 5,
              }}
            ></CommonStyles.ButtonUI>
          );
        })}
      </div>
    );
  };
  return (
    <div>
      <CommonStyles.InputUI
        disabled
        label="Tên trạm"
        value={dataDevice?.data?.name?.split("/")[1]}
      />

      {renderOptionColors()}

      <div className="flex justify-end gap-3 mt-8">
        <CommonStyles.ButtonUI
          onClick={toggle}
          danger
          ghost
          size="large"
          className="w-24"
        >
          Hủy
        </CommonStyles.ButtonUI>
        <CommonStyles.ButtonUI
          onClick={handlePickColor}
          size="large"
          className="w-24"
          style={{ backgroundColor: keyActive }}
        >
          Xác nhận
        </CommonStyles.ButtonUI>
      </div>
    </div>
  );
}
