import CardResize from "./cardResize/CardResize";
import ButtonUI from "./button/Button";
import Chart from "./chart/Chart";
import CheckBoxUI from "./checkbox/Checkbox";
import InputUI from "./input/Input";
import SubCard from "./subCard/SubCard";
import SwitchUI from "./switch/Switch";
import TypographyUI from "./typography/Typography";
import TabUI from "./tabs/Tabs";
import Select from "./select/Select";
import LeafletUI from "./leaflet/LeafletUI";
import ToastUI from "./toast/Toast";
import ModalUI from "./modal/ModalUI";
import PopConfirmUI from "./popConfirm/PopConfirmUI";
import TableUI from "./table/TableUI";
import DateTimePickerUI from "./datePicker/DateTimePickerUI";
import FormFieldUI from "./formField/FormField";
import TooltipUI from "./tooltip/TooltipUI";
import TextAreaUI from "./textArea/TextAreaUI";
import LoadingUI from "./LoadingUI/LoadingUI";
import LineChart from "./chart/LineChart/LineChart";
import TitleCardUI from "./ttitleCard";
import PaginationUI from "./pagination/PaginationUI";
import DropdownUI from "./dropdown/DropdownUI";
import FiltersUI from "./filters/FiltersUI";
import BoxInfo from "./BoxInfo/BoxInfo";
import ButtonAction from "./button/butonAction/ButtonAction";
import InputMulti from "./input/inputMultiple";
import AutoCompleteUI from "./autocomplete";

export const CommonStyles = {
  ButtonUI: ButtonUI,
  CheckBoxUI: CheckBoxUI,
  InputUI: InputUI,
  TypographyUI: TypographyUI,
  SwichUI: SwitchUI,
  SubCard: SubCard,
  ChartUI: Chart,
  CardResize: CardResize,
  TabUI: TabUI,
  SelectUI: Select,
  LeafletUI: LeafletUI,
  ToastUI: ToastUI,
  ModalUI: ModalUI,
  PopConfirmUI: PopConfirmUI,
  TableUI: TableUI,
  DateTimePickerUI: DateTimePickerUI,
  FormFieldUI: FormFieldUI,
  TooltipUI: TooltipUI,
  TextAreaUI: TextAreaUI,
  LoadingUI: LoadingUI,
  LineChart: LineChart,
  TitleCardUI: TitleCardUI,
  PaginationUI: PaginationUI,
  DropdownUI: DropdownUI,
  FiltersUI: FiltersUI,
  BoxInfo: BoxInfo,
  ButtonAction: ButtonAction,
  InputMulti: InputMulti,
  AutoCompleteUI: AutoCompleteUI,
};
