import React, { useEffect, useState } from "react";
import { CommonStyles } from "../../../ui";
import useGetTenantDeviceInfos from "../../../../modules/device/hooks/useGetTenantDeviceInfos";
import FormEditDevice from "./FormEdit";
import useToggle from "../../../../hooks/useToggle";
import useGetDeviceByID from "../../../../modules/device/hooks/useGetInfoDeviceByID";
import { ENTITY_TYPE, SCOPE } from "../../../../constants/Index";
import useGetAttributeScope from "../../../../modules/telemetry/hooks/useGetAttributeScope";
import { getErrorMsg, parseQueryString, showMsg } from "../../../../helpers";
import CommonIcons from "../../../../assets/icons";
import telemetryService from "../../../../modules/telemetry/telemetry.service";
import { useAppDispatch } from "../../../../hooks/hooks";

interface IProps {
  type: "LMMN" | "CDRC" | string | undefined;
  idStation: string;
}
export default function ListDevice(props: IProps) {
  //! state
  const { idStation } = props;
  const dispatch = useAppDispatch();
  const { open, shouldRender, toggle } = useToggle();
  const [page, setPage] = useState(1);
  const {
    data: dataKey,
    isLoading: loadingKey,
    refetch,
  } = useGetAttributeScope(
    ENTITY_TYPE,
    String(idStation),
    SCOPE.SHARED_SCOPE,
    true,
    parseQueryString({ keys: "devices" })
  );
  const [device, setDevice] = useState<{
    type: "info" | "edit" | "create";
    id: string | null;
  }>({
    type: "create",
    id: null,
  });

  //! function
  const handleAdd = () => {
    setDevice({
      type: "create",
      id: null,
    });
    toggle();
  };
  const handleInfo = (record: any) => {
    setDevice({
      type: "info",
      id: record.id,
    });
    toggle();
  };
  const handleEdit = (record: any) => {
    setDevice({
      type: "edit",
      id: record.id,
    });
    toggle();
  };
  const handleDelete = async (record: any) => {
    try {
      const indexDelete = dataKey?.data[0]?.value?.findIndex(
        (el: any) => el.id === record.id
      );
      if (indexDelete > -1) {
        const dataClone = [...dataKey?.data[0]?.value];
        dataClone.splice(indexDelete, 1);
        const deleteDevice = await telemetryService.postDeviceTele(
          idStation,
          SCOPE.SHARED_SCOPE,
          {
            devices: dataClone,
          }
        );
        if (deleteDevice.status === 200) {
          refetch();
          dispatch(showMsg("success", "Thành công"));
        }
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };
  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      render: (_: any, record: any, index: number) => {
        return <p>{(page - 1) * 10 + index + 1}</p>;
      },
    },
    {
      title: "Tên thiết bị",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Hãng",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Mã model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Serial",
      dataIndex: "serial",
      key: "serial",
    },

    {
      title: "Ngày lắp đặt",
      dataIndex: "date",
      key: "date",
    },

    {
      title: "Thao tác",
      dataIndex: "action",
      key: "action",
      render: (_: any, record: any) => {
        return (
          <CommonStyles.ButtonAction
            handleDelete={() => {
              handleDelete(record);
            }}
            handleInfo={() => {
              handleInfo(record);
            }}
            handleEdit={() => {
              handleEdit(record);
            }}
          />
        );
      },
    },
  ];
  //! render
  const renderExtra = () => {
    return (
      <CommonStyles.ButtonUI
        Icons={<CommonIcons.PlusOutlined />}
        onClick={handleAdd}
        size="small"
      >
        Thêm thiết bị
      </CommonStyles.ButtonUI>
    );
  };
  if (loadingKey) {
    return <CommonStyles.LoadingUI />;
  }

  return (
    <CommonStyles.CardResize title="Danh sách thiết bị" extra={renderExtra()}>
      <CommonStyles.TableUI
        columns={columns}
        dataSource={dataKey?.data[0]?.value ?? []}
        hasPagination
        pagination={{
          onChange(current) {
            setPage(current);
          },
        }}
        scroll={{
          x: 1300,
        }}
        loading={loadingKey}
      />

      {shouldRender && (
        <CommonStyles.ModalUI
          title={
            device.type === "info"
              ? "Thông tin thiết bị trạm"
              : "Thêm thiết bị trạm"
          }
          toggle={toggle}
          open={open}
          hiddenAction
        >
          <FormEditDevice
            toggle={toggle}
            idStation={idStation}
            refetch={refetch}
            device={device}
          />
        </CommonStyles.ModalUI>
      )}
    </CommonStyles.CardResize>
  );
}
