import { useCallback, useEffect, useState } from "react";
import {
  HomeOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import {
  Button,
  Breadcrumb,
  Avatar,
  Badge,
  Typography,
  Tabs,
  Alert,
} from "antd";
import OutsideClickHandler from "react-outside-click-handler";
import CommonIcons from "../../assets/icons";
import useGetAuth from "../../modules/auth/hooks/useGetAuth";
import { useTranslation } from "react-i18next";
import useToggle from "../../hooks/useToggle";
import { CommonStyles } from "../../components/ui";
import useAuth from "../../modules/auth/hooks/useAuth";
import { checkRole, limitTextString } from "../../helpers";
import { useNavigate } from "react-router-dom";
import baseRouter from "../../router/baseRouter";
import NotificationsDasboard from "../../components/notificationDashboard";
import useComponentVisible from "../../hooks/useClickOutside";
import SearchDashboard from "../../components/searchDashboard";
import { wsNotification } from "../../helpers/ws";
import CommonImages from "../../assets/images";
import { colorHighLow } from "../../constants/color";

const { Text } = Typography;
export default function Navbar(props: any) {
  //! state
  const { t } = useTranslation();
  const [showUserMenus, setShowUserMenus] = useState<boolean>(false);
  const { open, shouldRender, toggle } = useToggle();
  const {
    ref: refSearch,
    isComponentVisible: visibleSearch,
    toggleComponent: toggleSearch,
  } = useComponentVisible(false);

  const { ref, isComponentVisible, toggleComponent } =
    useComponentVisible(false);

  const { data: dataUser } = useGetAuth();
  const user = dataUser?.data;
  const navigate = useNavigate();
  const { handleLogout } = useAuth();
  const options = [
    {
      id: 1,
      name: "Your Profile",
      action: () => {
        navigate(baseRouter.profile);
      },
    },
    {
      id: 2,
      name: "Password",
      action: () => {
        navigate(baseRouter.settingPassword);
      },
    },
    {
      id: 3,
      name: "Sign out",
      action: () => {
        toggle();
      },
      color: colorHighLow.value,
    },
  ];
  const [countNotification, setCountNotification] = useState(0);
  //! function
  const receiveNotification = (data: any) => {
    setCountNotification(data.totalUnreadCount);
  };
  useEffect(() => {
    wsNotification(
      "plugins/notifications",
      {
        unreadCountSubCmd: {
          cmdId: 1,
        },
      },
      receiveNotification
    );
  }, []);
  //! render
  const renderOptions = () => {
    return options?.map((item, index) => {
      return (
        <button
          key={index}
          className={`block px-4 py-2 text-sm text-left w-full ${
            item.color ? "text-red-500" : ""
          } hover:text-main-color`}
          role="menuitem"
          id={`user-menu-item-${item.id}`}
          onClick={() => {
            item.action();
          }}
        >
          {t(`${item.name}`)}
        </button>
      );
    });
  };

  return (
    <nav className="flex justify-between bg-main-color relative z-1500">
      <div className="flex gap-x-2 flex-nowrap items-center">
        <Button
          className="text-base !w-16 h-16 hover:!bg-transparent active:!bg-transparent ml-2"
          type="text"
          icon={
            props?.collapsed ? (
              <MenuUnfoldOutlined style={{ color: "#fff" }} />
            ) : (
              <MenuFoldOutlined style={{ color: "#fff" }} />
            )
          }
          onClick={() => props.setCollapsed(!props?.collapsed)}
        />
        <p className="sm:text-sm text-white line-clamp-1">
          HTQL THÔNG TIN CẢNH BÁO SỚM THIÊN TAI THEO THỜI GIAN THỰC TẠI TỈNH HÒA
          BÌNH
        </p>
      </div>

      <div className="flex items-center px-4 gap-x-5">
        <CommonStyles.ButtonUI
          className="flex items-center bg-[#6CABFF] border-[#6CABFF] text-white hover:!bg-[#6CABFF] hover:!text-white"
          type="default"
          Icons={<CommonIcons.VideoCamera />}
          onClick={() => {
            alert("Tính năng đang phát triển ");
            // navigate(baseRouter.camera)
          }}
        >
          <Text className="text-white !hidden lg:!block">Camera các trạm</Text>
        </CommonStyles.ButtonUI>
        <div className="cursor-pointer" onClick={toggleSearch}>
          <CommonIcons.SearchOutlined className="text-white text-xl" />
        </div>
        <div
          className="relative w-11 cursor-pointer"
          onClick={() => {
            toggleComponent();
          }}
        >
          <CommonStyles.TooltipUI title="Thông báo">
            <CommonIcons.Bell className="text-white text-xl" />
            <Badge
              count={countNotification}
              size="default"
              className="absolute top-[20%] right-[4px] border-main-color "
            />
          </CommonStyles.TooltipUI>
        </div>

        <div className="relative">
          <OutsideClickHandler onOutsideClick={() => setShowUserMenus(false)}>
            <div
              className="flex justify-center items-center cursor-pointer gap-2"
              onClick={() => setShowUserMenus(!showUserMenus)}
            >
              <div>
                <Avatar
                  className="hover:cursor-pointer bg-transparent"
                  size={"large"}
                  icon={<img src={CommonImages.userDefault} />}
                />
              </div>
              <div className="hidden md:flex-col lg:flex mr-3 text-left gap-0.5">
                <Text className="text-white">
                  {limitTextString(String(user?.email) ?? "", 15)}
                </Text>
                <Text className="text-white text-xs">
                  {user?.authority === "TENANT_ADMIN"
                    ? "Quản trị viên "
                    : checkRole(String(user?.lastName))}
                </Text>
              </div>
              <div>
                <CommonIcons.DownOutlined className="text-white text-xs" />
              </div>
            </div>
          </OutsideClickHandler>

          <div
            className={
              "absolute right-0 z-10 mt-0 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" +
              (showUserMenus ? " transition duration-300 delay-300" : " hidden")
            }
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
          >
            {renderOptions()}
          </div>
        </div>
      </div>
      {shouldRender && (
        <CommonStyles.ModalUI
          toggle={toggle}
          open={open}
          title={t("You want to exit the system?")}
          type="error"
          onConfirm={handleLogout}
        />
      )}
      {isComponentVisible && (
        <div
          ref={ref}
          className="absolute top-[105%] right-[5%] border border-gray-200"
        >
          <NotificationsDasboard
            toggle={toggleComponent}
            setCountNotification={setCountNotification}
          />
        </div>
      )}
      {visibleSearch && (
        <div
          // ref={refSearch}
          className="absolute top-[150%] left-1/2 -translate-x-1/2 rounded-2xl"
        >
          <SearchDashboard toggle={toggleSearch} />
        </div>
      )}
    </nav>
  );
}
