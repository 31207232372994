import { useCallback, useEffect, useState } from "react";
import { CommonStyles } from "../../../ui";
import telemetryService from "../../../../modules/telemetry/telemetry.service";
import { ENTITY_TYPE, ID_STATION, SCOPE } from "../../../../constants/Index";
import { useAppDispatch } from "../../../../hooks/hooks";
import {
  getErrorMsg,
  getStatusAction,
  parseQueryString,
  showMsg,
} from "../../../../helpers";
import useGetAttributeKey from "../../../../modules/telemetry/hooks/useGetAttributeKey";
import _, { isEmpty } from "lodash";
import Regex from "../../../../constants/regex";

interface IProps {
  idStation: string;
}
export default function Email(props: IProps) {
  //! state
  const { idStation } = props;
  const dispatch = useAppDispatch();
  const { data, isLoading, refetch } = useGetAttributeKey(
    ENTITY_TYPE,
    idStation,
    true,
    parseQueryString({ keys: "Setmail" })
  );
  const [valueMail, setValueMail] = useState<string[]>([]);
  const [errorField, setErrorField] = useState<string>("");

  //! function
  const onChangeMail = (value: string) => {
    if (isEmpty(value)) {
      return;
    }
    if (!String(value).toLocaleLowerCase().match(Regex.email)) {
      setErrorField("Email không hợp lệ!");
      return;
    }
    const newValue = [...valueMail];
    newValue.push(value);
    setValueMail(newValue);
    setErrorField("");
  };
  const onCloseTag = (index: number) => {
    const newValue = [...valueMail];
    newValue.splice(index, 1);
    setValueMail(newValue);
  };

  const handleSubmit = async () => {
    try {
      const emails = valueMail.join(",");
      const postSetPhone = await telemetryService.postDeviceTele(
        idStation,
        SCOPE.SHARED_SCOPE,
        {
          Setmail: `${emails}`,
        }
      );
      if (postSetPhone.status === 200) {
        refetch();
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };

  useEffect(() => {
    const emails = data?.data[0]?.value.split(",");
    if (emails && emails?.length > 0) {
      setValueMail(emails);
    }
  }, [isLoading]);
  //! render
  const renderExtraAction = useCallback(() => {
    return (
      <CommonStyles.ButtonUI
        onClick={handleSubmit}
        disabled={getStatusAction(
          _.map(data?.data[0]?.value?.split(","), _.trim),
          valueMail
        )}
        size="small"
      >
        Hoàn thành
      </CommonStyles.ButtonUI>
    );
  }, [data, valueMail]);

  return (
    <CommonStyles.SubCard
      title="Email nhận thông báo (Tối đa 5 email)"
      extra={renderExtraAction()}
    >
      <CommonStyles.InputMulti
        defaultValue={valueMail}
        limit={5}
        onChangeMulti={onChangeMail}
        onCloseTag={onCloseTag}
        errorField={errorField}
      />
    </CommonStyles.SubCard>
  );
}
