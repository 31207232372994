import React from "react";
import { CommonStyles } from "../../components/ui";
import baseRouter from "../../router/baseRouter";
import CDRCStation from "../../components/station/CDRC";
import LMMNStation from "../../components/station/LMMN";

export default function Station() {
  //! state
  //! function
  //! render
  return (
    <div className="flex flex-col gap-5">
      <CommonStyles.TitleCardUI
        title="Danh sách trạm đo"
        subTitle={[
          {
            title: "Quản lý hệ thống",
            link: baseRouter.station,
          },
          {
            title: "Trạm đo",
            link: baseRouter.station,
          },
          {
            title: "Danh sách trạm đo",
            link: baseRouter.station,
          },
        ]}
      />

      <CDRCStation />
      <LMMNStation />
    </div>
  );
}
