import { Input, InputProps } from "antd";
import CommonIcons from "../../../assets/icons";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
interface IProps extends InputProps {
  type?: "text" | "search" | "password";
  maxLength?: number;
  disabled?: boolean;
  size?: "large" | "middle" | "small";
  value?: string | number;
  variant?: "outlined" | "borderless" | "filled";
  onChange?: (e: any) => void;
  onPressEnter?: () => void;
  className?: string;
  placeholder?: string;
  addonAfter?: any;
  label?: string;
  onBlur?: () => void;
  subText?: string;
}

const InputUI = (props: IProps) => {
  //! state
  const {
    type,
    className,
    placeholder,
    maxLength,
    variant,
    disabled,
    onChange,
    onPressEnter,
    size,
    addonAfter,
    value,
    onBlur,
    subText,
    label,
    ...res
  } = props;
  //!! function
  //! render
  if (type === "password") {
    return (
      <>
        <Input.Password
          value={value}
          maxLength={maxLength}
          disabled={disabled}
          onChange={onChange}
          onPressEnter={onPressEnter}
          onBlur={onBlur}
          size={size}
          type="password"
          className="h-9 border !border-s-line active:!border-main-color "
          placeholder={placeholder}
          iconRender={(visible) =>
            visible ? (
              <EyeTwoTone style={{ color: "#2F80ED!important" }} />
            ) : (
              <EyeInvisibleOutlined />
            )
          }
        />
        {subText && <p className="mt-2 font-light text-xs">{subText}</p>}
      </>
    );
  }

  return (
    <div className="relative">
      {label && <p className="mb-2">{label}</p>}
      <Input
        type={"text"}
        value={value}
        maxLength={maxLength}
        className={`h-9 border !border-s-line active:!border-main-color ${
          type === "search" && "pl-8 "
        } ${className} `}
        placeholder={placeholder}
        variant={variant}
        disabled={disabled}
        onChange={onChange}
        onPressEnter={onPressEnter}
        size={size}
        addonAfter={addonAfter}
        onBlur={onBlur}
      />
      {type === "search" && (
        <div className="absolute flex top-1/2 left-2 -translate-y-1/2">
          <CommonIcons.SearchOutlined />
        </div>
      )}
    </div>
  );
};

export default InputUI;
