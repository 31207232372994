import { useCallback, useEffect, useState } from "react";
import { ResponseGetDetailDevice } from "../device.interface";
import deviceService from "../device.service";

const useGetDeviceByID = (deviceId: string, enabled = true) => {
  const [data, setData] = useState<ResponseGetDetailDevice>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return deviceService.getDeviceInfoByID(deviceId);
  }, [deviceId]);

  const transformResponse = useCallback((response: ResponseGetDetailDevice) => {
    if (response) {
      setData(response);
    }
  }, []);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled, deviceId]);

  return { data, error, isLoading };
};

export default useGetDeviceByID;
