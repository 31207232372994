import { useCallback, useEffect, useState } from "react";
import {
  ResponseGetCustomerDeviceInfos,
  ResponseGetDetailDevice,
} from "../device.interface";
import deviceService from "../device.service";
import { parseQueryString } from "../../../helpers";
interface IQuery {
  page: number;
  pageSize: number;
  type?: "LMMN" | "CDRC" | string;
  deviceProfileId?: string;
  active?: number | string;
  textSearch?: string;
  sortProperty?:
    | "createdTime"
    | "customerTitle"
    | "label"
    | "name"
    | "deviceProfileName";
}
const useGetTenantDeviceInfos = (query: IQuery, enabled = true) => {
  const [data, setData] = useState<ResponseGetCustomerDeviceInfos>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return deviceService.getTenantDeviceInfos(parseQueryString(query));
  }, [query]);

  const transformResponse = useCallback(
    (response: ResponseGetCustomerDeviceInfos) => {
      if (response) {
        setData(response);
      }
    },
    []
  );
  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [query]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled]);

  return { data, error, isLoading, refetch };
};

export default useGetTenantDeviceInfos;
