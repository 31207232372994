import React, { useEffect, useState } from "react";
import { CommonStyles } from "../ui";
import useGetTemplates from "../../modules/sms/hooks/useGetTemplates";
import { parseQueryString } from "../../helpers";
import {
  CDRC,
  LMMN,
  optionsContentSMSCDRC,
  optionsContentSMSLMMN,
} from "../../constants/Index";
import { ITemplateSMS } from "../../modules/sms/templateSMS.interface";
import { Tag } from "antd";
import { IDevice } from "../../modules/device/device.interface";
import useToggle from "../../hooks/useToggle";
import ModalMessage from "./ModalMessage";
import { IDataTime } from "../../interface";
import ResponseUI from "../../constants/response";

interface IProps {
  toggle: () => void;
  detailDevice: IDevice;
  dataSms: IDataTime | undefined;
}
export default function ModalChoiceTemplate(props: IProps) {
  //! state
  const { toggle, detailDevice, dataSms } = props;
  const { data, isLoading } = useGetTemplates(
    parseQueryString({ station: detailDevice.type, status: true })
  );

  const { open, shouldRender, toggle: toggleMessage } = useToggle();
  const [detailTemplate, setDetailTemplate] = useState<{
    id: string | null;
    length: number;
    dataSms: IDataTime | undefined;
    device: IDevice;
  }>({
    id: null,
    length: 0,
    dataSms: dataSms,
    device: detailDevice,
  });
  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      render: (_: any, record: any, index: number) => <p>{index + 1}</p>,
    },
    {
      title: "Tên mẫu",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Loại trạm",
      dataIndex: "station",
      key: "station",
      render: (_: any, record: ITemplateSMS) => {
        let stationName = "";
        if (record.station === LMMN) {
          stationName = "Lũ quét";
        }
        if (record.station === CDRC) {
          stationName = "Sạt lở";
        }
        if (record.station !== LMMN && record.station !== CDRC) {
          stationName = record.station;
        }
        return <>{stationName}</>;
      },
    },
    {
      title: "Nội dung",
      dataIndex: "content",
      key: "content",
      render: (_: any, record: ITemplateSMS, index: number) => {
        const options =
          record.station === LMMN
            ? optionsContentSMSLMMN
            : optionsContentSMSCDRC;
        return (
          <div>
            <div className="flex items-center flex-wrap gap-y-1">
              {record?.tags?.map((item, index) => {
                const itemFind = options.find((el) => el.value === item);
                return (
                  <Tag color="#E7E7EE" className="!text-black">
                    {itemFind?.label}
                  </Tag>
                );
              })}
            </div>

            <p className="mt-2">{record.message}</p>
          </div>
        );
      },
    },
  ];
  //! function
  const onRecord = (record: ITemplateSMS) => {
    setDetailTemplate((prev) => ({
      ...prev,
      id: record.id,
      length: Number(data?.data.page_contents.length),
    }));
    toggleMessage();
  };
  useEffect(() => {
    if (!!data?.data.page_contents && data?.data.page_contents.length == 1) {
      setDetailTemplate((prev) => ({
        ...prev,
        id: data?.data.page_contents[0].id,
        length: 1,
      }));
      toggleMessage();
    }
  }, [isLoading]);

  //! render
  return (
    <div>
      <CommonStyles.TableUI
        columns={columns}
        dataSource={data?.data.page_contents}
        rowClassName={"cursor-pointer"}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              onRecord(record);
            },
          };
        }}
        scroll={{
          x: 1000,
        }}
      />
      <div className="flex justify-end items-center mt-5">
        <CommonStyles.ButtonUI
          danger
          ghost
          onClick={toggle}
          className={`${ResponseUI.button} w-24`}
        >
          Hủy
        </CommonStyles.ButtonUI>
      </div>

      {shouldRender && (
        <CommonStyles.ModalUI
          title="Nội dung tin nhắn"
          toggle={() => {
            if (Number(data?.data.page_contents.length) > 1) {
              toggleMessage();
            } else {
              toggle();
            }
          }}
          open={open}
          hiddenAction
        >
          <ModalMessage
            toggle={toggleMessage}
            toggleChoiceTemplate={toggle}
            detailTemplate={detailTemplate}
          />
        </CommonStyles.ModalUI>
      )}
    </div>
  );
}
