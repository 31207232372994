import { Form } from "antd";
import { CommonStyles } from "../ui";
import { useAppDispatch } from "../../hooks/hooks";
import { getErrorMsg, showMsg } from "../../helpers";
import authService from "../../modules/auth/auth.service";

interface IProps {
  toggle: () => void;
}
export default function ForgotPassword(props: IProps) {
  //! state
  const { toggle } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const initialValues = {
    email: "",
  };
  //! function
  const hanleSubmit = async (values: any) => {
    try {
      const result = await authService.forgotPassword(values);
      if (result.status === 200) {
        toggle();
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };
  //! render
  return (
    <Form
      layout="vertical"
      name="forgotPassword"
      form={form}
      initialValues={initialValues}
      onFinish={hanleSubmit}
      className="mt-7"
    >
      <CommonStyles.FormFieldUI
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: "Email không được bỏ trống!",
          },
          {
            type: "email",
            message: "Email không hợp lệ!",
          },
        ]}
        component={<CommonStyles.InputUI />}
      />
      <div className="flex justify-end gap-3 items-center">
        <CommonStyles.ButtonUI
          onClick={toggle}
          action="submit"
          size="large"
          danger
          ghost
        >
          Hủy bỏ
        </CommonStyles.ButtonUI>
        <CommonStyles.ButtonUI action="submit" size="large">
          Xác nhận
        </CommonStyles.ButtonUI>
      </div>
    </Form>
  );
}
