import { message, notification } from "antd";
import { useAppSelector } from "../../../hooks/hooks";
import { useEffect } from "react";

export default function ToastUI() {
  //! state
  const toastInfo = useAppSelector((state) => state.toast);
  const [api, contextHolder] = notification.useNotification();

  message.config({
    rtl: true,
  });
  //! function
  useEffect(() => {
    api[toastInfo.type]({
      message: "Thông báo",
      description: `${toastInfo.content}`,
      duration: toastInfo.duration,
    });
    // api.open({
    //   type: `${toastInfo.type}`,
    //   content: `${toastInfo.content}`,
    //   duration: toastInfo.duration,
    // });
  }, [toastInfo]);

  //! render
  return <div>{toastInfo.content && contextHolder}</div>;
}
