import { useParams } from "react-router-dom";
import CommonIcons from "../../../assets/icons";
import { CommonStyles } from "../../../components/ui";
import useGetCustomerUsers from "../../../modules/user/hooks/useGetCustomerUsers";
import {
  getCreatedTime,
  limitTextString,
  parseQueryString,
  showMsg,
} from "../../../helpers";
import { useEffect, useState } from "react";
import { IUser } from "../../../modules/auth/user.interface";
import useToggle from "../../../hooks/useToggle";
import ModalUser from "./modalUser";
import useGetCustomer from "../../../modules/customer/hooks/useGetCustomer";
import userService from "../../../modules/user/user.service";
import { useAppDispatch } from "../../../hooks/hooks";
import baseRouter from "../../../router/baseRouter";
import useGetCustomers from "../../../modules/customer/hooks/useGetCustomers";
import { optionsRole } from "../../../constants/Index";
import ResponseUI from "../../../constants/response";
import { debounce } from "lodash";

export default function Users() {
  //! state
  const params = useParams();
  const customerID = String(params.id);
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState({
    page: 0,
    pageSize: 10,
    textSearch: "",
    sortProperty: "createdTime",
    sortOrder: "DESC",
  });
  const [textUser, setTextUser] = useState("");
  const { data: dataRole, isLoading: loadingCustomer } = useGetCustomers(
    parseQueryString({
      page: 0,
      pageSize: 100,
    })
  );
  const roleCustomer = dataRole?.data.data.find(
    (item) => item.title === "customer"
  );
  const { data, isLoading, refetch } = useGetCustomerUsers(
    String(roleCustomer?.id?.id),
    parseQueryString(filters)
  );

  const dataUsers = data?.data;
  const [userAction, setUserAction] = useState<{
    title: "add" | "info" | "edit";
    id: string | null;
    customerId:
      | {
          id: string;
          entityType: string;
        }
      | undefined;
  } | null>(null);
  const { open, shouldRender, toggle } = useToggle();
  //! function
  const handleInfo = (record: IUser) => {
    setUserAction({
      title: "info",
      id: record.id.id,
      customerId: record.customerId,
    });
    toggle();
  };
  const handleAddUser = () => {
    setUserAction({
      title: "add",
      id: null,
      customerId: roleCustomer?.id,
    });
    toggle();
  };
  const handleDeleteUser = async (userID: string) => {
    try {
      const userDelete = await userService.deleteUser(userID);
      if (userDelete) {
        dispatch(showMsg("success", "Thành công"));
        refetch();
      }
    } catch (error) {
      dispatch(showMsg("error", "Thất bại"));
    }
  };
  const handleEdit = (record: IUser) => {
    setUserAction({
      title: "edit",
      id: record.id.id,
      customerId: record.customerId,
    });
    toggle();
  };

  const handleSearch = debounce((e: any) => {
    setTextUser(e.target.value);
  }, 3000);

  const onChangePage = (value: number) => {
    setFilters((prev) => ({ ...prev, page: value - 1 }));
  };

  useEffect(() => {
    if (!!roleCustomer?.id) {
      refetch && refetch();
    }
  }, [filters, loadingCustomer]);

  useEffect(() => {
    setFilters((prev) => ({ ...prev, page: 0, textSearch: textUser }));
  }, [textUser]);
  const columns = [
    {
      title: "STT",
      dataIndex: "name",
      key: "stt",
      render: (_: any, record: IUser, index: number) => {
        return <p>{index + filters.page * filters.pageSize + 1}</p>;
      },
    },
    {
      title: "Tên tài khoản",
      dataIndex: "firstName",
      key: "name",
      render: (_: any, record: IUser) => {
        return <p>{record.firstName}</p>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_: any, record: IUser) => {
        return <p>{limitTextString(record.email, 30)}</p>;
      },
    },
    {
      title: "Loại tài khoản",
      dataIndex: "role",
      key: "userType",
      render: (_: any, record: IUser) => {
        const role = optionsRole.find((item) => item.value === record.lastName);
        return <p>{role?.label}</p>;
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdTime",
      key: "createdTime",
      render: (_: any, record: IUser) => {
        return <p>{getCreatedTime(record.createdTime)}</p>;
      },
    },

    {
      title: "Thao tác",
      dataIndex: "action",
      key: "action",
      render: (_: any, record: IUser) => {
        return (
          <CommonStyles.ButtonAction
            handleInfo={() => {
              handleInfo(record);
            }}
            handleDelete={() => {
              handleDeleteUser(record.id.id);
            }}
            handleEdit={() => {
              handleEdit(record);
            }}
          />
        );
      },
    },
  ];
  //! render
  const renderExtraAction = () => {
    return (
      <CommonStyles.ButtonUI
        onClick={handleAddUser}
        Icons={<CommonIcons.PlusOutlined />}
        className={ResponseUI.button}
      >
        Thêm tài khoản
      </CommonStyles.ButtonUI>
    );
  };

  return (
    <div className="flex flex-col gap-5">
      <CommonStyles.TitleCardUI
        title="Danh sách tài khoản"
        subTitle={[
          {
            title: "Quản lý hệ thống",
            link: baseRouter.user,
          },

          {
            title: "Tài khoản",
            link: baseRouter.user,
          },
        ]}
        extra={renderExtraAction()}
      />
      <CommonStyles.CardResize>
        <div className="mb-5">
          <CommonStyles.InputUI
            onChange={handleSearch}
            // value={textUser}
            type="search"
            placeholder="Nhập email ... "
          />
        </div>
        <CommonStyles.TableUI
          columns={columns}
          dataSource={dataUsers?.data}
          scroll={{
            x: 1000,
          }}
          // loading={isLoading}
        />
        <CommonStyles.PaginationUI
          page={filters.page + 1}
          pageSize={filters.pageSize}
          totalElement={dataUsers?.totalElements}
          onChangePage={onChangePage}
        />
      </CommonStyles.CardResize>

      {shouldRender && (
        <CommonStyles.ModalUI
          title="Thông tin tài khoản"
          toggle={toggle}
          open={open}
          hiddenAction
        >
          <ModalUser
            userModal={userAction}
            cancel={toggle}
            refetchUser={refetch}
          />
        </CommonStyles.ModalUI>
      )}
    </div>
  );
}
