import { useCallback, useEffect, useState } from "react";
import authService from "../auth.service";
import { IUser, ResponseGetAuth } from "../user.interface";

const useGetAuth = () => {
  const [data, setData] = useState<ResponseGetAuth>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return authService.user();
  }, []);

  const refetch = useCallback(async () => {
    try {
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    }
  }, []);

  const transformResponse = useCallback((response: ResponseGetAuth) => {
    if (response) {
      setData(response);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { data, error, isLoading, refetch };
};

export default useGetAuth;
