import { Form } from "antd";
import { CommonStyles } from "../../../ui";
import { useAppDispatch } from "../../../../hooks/hooks";
import useGetAttributeKey from "../../../../modules/telemetry/hooks/useGetAttributeKey";
import { ENTITY_TYPE, SCOPE } from "../../../../constants/Index";
import {
  getErrorMsg,
  getStatusAction,
  parseQueryString,
  showMsg,
} from "../../../../helpers";
import { useCallback, useEffect } from "react";
import telemetryService from "../../../../modules/telemetry/telemetry.service";
import Regex from "../../../../constants/regex";

interface IProps {
  idStation: string;
}
export default function Locate(props: IProps) {
  //! state
  const { idStation } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const { data, isLoading, refetch } = useGetAttributeKey(
    ENTITY_TYPE,
    idStation,
    true,
    parseQueryString({ keys: "lat,lon" })
  );
  const valueLat = Form.useWatch("lat", form);
  const valueLon = Form.useWatch("lon", form);
  const lat = data?.data.find((item) => item.key === "lat");
  const lon = data?.data.find((item) => item.key === "lon");
  const initialValues = {
    lat: Number(lat?.value) ?? 0,
    lon: Number(lon?.value) ?? 0,
  };
  //! function
  const handleSubmit = async () => {
    const values = form.getFieldsValue();
    const valid = form.getFieldsError();
    let check = true;
    valid.forEach((element) => {
      if (element.errors.length > 0) {
        check = false;
      }
    });
    if (!check) {
      return;
    }
    try {
      const postLocate = await telemetryService.postDeviceTele(
        idStation,
        SCOPE.SHARED_SCOPE,
        values
      );
      if (postLocate.status === 200) {
        refetch();
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [isLoading]);
  //! render
  const renderExtra = useCallback(() => {
    return (
      <CommonStyles.ButtonUI
        disabled={getStatusAction(
          [Number(lat?.value) ?? 0, Number(lon?.value) ?? 0],
          [Number(valueLat), Number(valueLon)]
        )}
        onClick={handleSubmit}
        size="small"
      >
        Hoàn thành
      </CommonStyles.ButtonUI>
    );
  }, [form, initialValues]);
  return (
    <CommonStyles.SubCard title="Cài đặt toạ độ" extra={renderExtra()}>
      <Form
        name="LocateForm"
        form={form}
        initialValues={initialValues}
        layout="vertical"
        className="md:grid md:grid-cols-2 gap-3"
      >
        <CommonStyles.FormFieldUI
          name={"lat"}
          label="Vĩ độ"
          rules={[
            {
              pattern: Regex.lat,
              message: "Vĩ độ không hợp lệ!",
            },
          ]}
          component={
            <CommonStyles.InputUI
              maxLength={50}
              className="h-9 border !border-s-line active:!border-main-color"
            />
          }
        />

        <CommonStyles.FormFieldUI
          name={"lon"}
          label="Kinh độ"
          rules={[
            {
              pattern: Regex.lon,
              message: "Kinh độ không hợp lệ!",
            },
          ]}
          component={
            <CommonStyles.InputUI
              maxLength={50}
              className="h-9 border !border-s-line active:!border-main-color"
            />
          }
        />
      </Form>
    </CommonStyles.SubCard>
  );
}
