import {Link} from 'react-router-dom';

import logo from "../../assets/images/logo.png";

const LoginHead = (props:any)=> {
  return (
     <div className="mb-10">
            <a href='/' className="flex justify-center">
                <img  alt="logo"
                    className="h-24"
                    src={logo}/>
            </a>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                {props?.heading}
            </h2>
            <p className="text-center text-sm text-gray-600 mt-5">
            {props?.paragraph} {' '}
            <Link to={props?.linkUrl} className="font-medium text-indigo-600 hover:text-indigo-500">
                {props?.linkName}
            </Link>
            </p>
        </div>
  );
}

export default LoginHead;