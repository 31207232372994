import { useState } from "react";
import { IDateFilter } from "../interface/IDateFilter";
import moment from "moment";
import { TOption } from "../interface";
import { useAppDispatch } from "./hooks";
import { showMsg } from "../helpers";
import { Ws } from "../helpers/ws";

const useFilterDate = (dataFilter: TOption[], real?: boolean) => {
  //! state
  const dispatch = useAppDispatch();
  const [dateFilter, setDateFilter] = useState<IDateFilter>({
    type: "hour",
    value: dataFilter[0].value,
    startDate: moment()
      .utc()
      .add(9, "hours")
      .subtract(dataFilter[0].value, "milliseconds")
      .valueOf(),
    endDate: moment().utc().add(9, "hours").valueOf(),
  });
  const [dateSetting, setDateSetting] = useState(false);
  const ws = Ws("plugins/telemetry");

  //! function
  const onChangeFilterDate = (value: any) => {
    let type: "hour" | "date" | "month" = "hour";
    ws.close();
    if (value === "real") {
      type = "hour";
      setDateFilter({
        value,
        type,
        startDate: moment()
          .utc()
          .add(9, "hours")
          .startOf("date")
          .subtract(value, "milliseconds")
          .valueOf(),
        endDate: moment().utc().add(9, "hours").valueOf(),
      });
      setDateSetting(false);

      return;
    }
    if (value === 604800000) {
      type = "date";
    }

    if (value === 7776000000) {
      type = "month";
    }
    if (value <= 12) {
      type = "date";
      const latestDate = moment()
        .utc()
        .add(9, "hours")
        .month(value)
        .endOf("month")
        .valueOf();
      const startDate = moment()
        .utc()
        .add(9, "hours")
        .month(value)
        .startOf("month")
        .valueOf();
      setDateFilter({
        value,
        type,
        startDate: startDate,
        endDate: latestDate,
      });
      setDateSetting(false);
      return;
    }

    if (value === "setting") {
      setDateSetting(true);
      setDateFilter((prev) => ({ ...prev, value: "setting" }));
      return;
    }

    setDateFilter({
      value,
      type,
      startDate: moment()
        .utc()
        .add(9, "hours")
        // .startOf("date")
        .subtract(value, "milliseconds")
        .valueOf(),
      endDate: moment().utc().add(9, "hours").endOf("date").valueOf(),
    });
    setDateSetting(false);
  };

  const onChangeSettingDate = (value: any, dateString: any) => {
    const format = "YYYY-MM-DD HH:mm:ss";

    const startDate = moment.utc(dateString[0]).add(9, "hours").format(format);
    const timestampStart = moment(startDate, format).valueOf();
    const latestDate = moment.utc(dateString[1]).add(9, "hours").format(format);
    const timestampEnd = moment(latestDate, format).valueOf();

    const startDatefor = moment(dateString[0]).startOf("day").format(format);
    const timestampStartDate = moment.utc(startDatefor, format).valueOf();
    const latestDateFor = moment(dateString[1]).endOf("day").format(format);
    const timestampEndDate = moment.utc(latestDateFor, format).valueOf();

    const diffInMonths = moment
      .utc(timestampEnd)
      .diff(moment.utc(timestampStart), "months", true);

    const diffHours = moment
      .utc(timestampEnd)
      .diff(moment.utc(timestampStart), "hours", true);
    if (diffInMonths > 3) {
      dispatch(showMsg("error", "Không được chọn quá 3 tháng"));
      return;
    }
    const getTypeDate = () => {
      if (diffHours <= 24) {
        return "hour";
      }
      return diffInMonths > 1 ? "month" : "date";
    };
    setDateFilter({
      type: getTypeDate(),
      value: "setting",
      // endDate: diffHours <= 24 ? timestampEnd : timestampEndDate,
      // startDate: diffHours <= 24 ? timestampStart : timestampStartDate,
      endDate: timestampEnd,
      startDate: timestampStart,
    });
  };
  return { dateFilter, dateSetting, onChangeFilterDate, onChangeSettingDate };
};

export default useFilterDate;
