import { Checkbox } from "antd";

interface IProps {
  options: { label: any; value: any; color?: string | any }[];
  checkedList: Array<any>;
  setCheckedList?: any;
  afterOnchange?: () => void;
}
export default function FiltersUI(props: IProps) {
  //! state
  const { options, checkedList, setCheckedList, afterOnchange } = props;
  const checkAll = options.length === checkedList.length;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < options.length;
  //! function
  const onCheckAllChange = (e: any) => {
    if (e.target.checked) {
      setCheckedList(options.map((item) => item));
    } else {
      setCheckedList([]);
    }
  };
  const onChange = (event: any) => {
    const node = event?.target;
    const listChecked = [...checkedList];
    if (node?.checked) {
      listChecked.push({
        label: node?.name,
        value: node?.name,
      });
    } else {
      const indexElement = listChecked.findIndex(
        (el) => el?.value === node?.name
      );
      if (indexElement > -1) {
        listChecked.splice(indexElement, 1);
      }
    }
    setCheckedList(listChecked);

    if (afterOnchange) {
      afterOnchange();
    }
  };
  const items: any = [
    {
      key: "1",
      label: (
        <div className="flex flex-col">
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
          >
            Tất cả
          </Checkbox>
          <Checkbox.Group
            className="flex flex-col"
            options={options}
            value={checkedList.map((item) => item.value)}
            onChange={onChange}
          />
        </div>
      ),
    },
  ];

  //! function
  //! render
  return (
    <div className="flex items-center flex-wrap gap-x-2 mt-2">
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        TẤT CẢ
      </Checkbox>

      {/* <div className="flex flex-wrap gap-2"> */}
      {options?.map((item, index) => {
        return (
          <div className="flex flex-col">
            <Checkbox
              onChange={onChange}
              name={item.value}
              checked={
                !!checkedList.find((element) => element.value === item.value)
              }
              style={{
                color: item?.color ?? "black",
              }}
            >
              {item.label}
            </Checkbox>
            {/* <div
              className={`w-10 h-0.5 inline-block mt-2`}
              style={{
                backgroundColor: item.color ?? "transparent",
              }}
            ></div> */}
          </div>
        );
      })}
      {/* </div> */}

      {/* <Checkbox.Group
        options={options}
        value={checkedList.map((item) => item.value)}
        onChange={onChange}
      /> */}
    </div>
    // <DropdownUI items={items}>
    //   <ButtonUI
    //     type="text"
    //     className="hover:!bg-transparent"
    //     Icons={
    //       <TooltipUI title={"Bộ lọc"}>
    //         <CommonIcons.FilterOutlined />
    //       </TooltipUI>
    //     }
    //   ></ButtonUI>
    // </DropdownUI>
  );
}
