import { urlsAPI } from "../../constants/api";
import httpService from "../../services/httpService";

class CustomerService {
  getCustomers(query: string) {
    return httpService.get(`${urlsAPI.customer.getAll}?${query}`);
  }
  getCustomer(customerID: string) {
    return httpService.get(`${urlsAPI.customer.customer}/${customerID}`);
  }
  postCustomer(body: any) {
    return httpService.post(urlsAPI.customer.customer, body);
  }
  deleteCustomer(id: string) {
    return httpService.delete(`${urlsAPI.customer.customer}/${id}`);
  }
}

export default new CustomerService();
