import React, { useCallback, useState } from "react";
import { ResponseGetRelationInfo } from "../relation.interface";
import relationService from "../relation.service";
import { parseQueryString } from "../../../helpers";

export const useGetRelationInfo = (query: any, enabled = true) => {
  const [data, setData] = useState<ResponseGetRelationInfo>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return relationService.info(parseQueryString(query));
  }, []);

  const transformResponse = useCallback((response: ResponseGetRelationInfo) => {
    if (response) {
      setData(response);
    }
  }, []);

  React.useEffect(() => {
    if (!enabled) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled]);

  return { data, error, isLoading };
};
