import { useCallback, useEffect, useState } from "react";
import CommonIcons from "../../assets/icons";
import { CommonStyles } from "../../components/ui";
import { getErrorMsg, parseQueryString, showMsg } from "../../helpers";
import useToggle from "../../hooks/useToggle";
import useGetTemplates from "../../modules/sms/hooks/useGetTemplates";
import baseRouter from "../../router/baseRouter";
import ModalSMS from "./ModalSMS";
import { ITemplateSMS } from "../../modules/sms/templateSMS.interface";
import {
  CDRC,
  LMMN,
  optionsContentSMSCDRC,
  optionsContentSMSLMMN,
} from "../../constants/Index";
import { Tag } from "antd";
import moment from "moment";
import smsService from "../../modules/sms/sms.service";
import { useAppDispatch } from "../../hooks/hooks";
import ResponseUI from "../../constants/response";

export default function SMS() {
  //! state
  const { open, shouldRender, toggle } = useToggle();
  const dispatch = useAppDispatch();

  const [filters, setFilters] = useState({
    // station: "",
    // status: "",
    page: 0,
    limit: 10,
  });
  const { data, isLoading, refetch } = useGetTemplates(
    parseQueryString(filters),
    false
  );

  const [detailTemplate, setDetailTemplate] = useState<{
    id: null | string;
    type: "info" | "edit" | "create";
  }>({
    id: null,
    type: "create",
  });

  //! function
  const handleChangePage = (page: number) => {
    setFilters((prev) => ({ ...prev, page: page - 1 }));
  };
  const hanldeInfo = (record: ITemplateSMS) => {
    setDetailTemplate({
      id: record.id,
      type: "info",
    });
    toggle();
  };

  const hanldeEdit = (record: ITemplateSMS) => {
    setDetailTemplate({
      id: record.id,
      type: "edit",
    });
    toggle();
  };

  const handleAddSMS = () => {
    setDetailTemplate({
      id: null,
      type: "create",
    });
    toggle();
  };
  const handleDelete = async (record: ITemplateSMS) => {
    try {
      const deleteTemplate = await smsService.postTemplate(
        {
          ...record,
          deleted: true,
        },
        record.id
      );
      if (deleteTemplate.status === 200) {
        refetch();
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };
  const actionSwitch = async (record: ITemplateSMS) => {
    try {
      const switchStatus = await smsService.postTemplate(
        {
          ...record,
          status: !record.status,
        },
        record.id
      );
      if (switchStatus.status === 200) {
        refetch();
        dispatch(showMsg("success", "Thay đổi trạng thái thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };

  useEffect(() => {
    refetch && refetch();
  }, [filters]);
  //! render
  const renderColumns = useCallback(() => {
    return [
      {
        title: "STT",
        dataIndex: "index",
        key: "index",
        render: (_: any, record: any, index: number) => (
          <p>{index + filters.page * filters.limit + 1}</p>
        ),
      },
      {
        title: "Tên mẫu",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Loại trạm",
        dataIndex: "station",
        key: "station",
        render: (_: any, record: ITemplateSMS) => {
          let stationName = "";
          if (record.station === LMMN) {
            stationName = "Lũ quét";
          }
          if (record.station === CDRC) {
            stationName = "Sạt lở";
          }
          if (record.station !== LMMN && record.station !== CDRC) {
            stationName = record.station;
          }
          return <>{stationName}</>;
        },
      },
      {
        title: "Nội dung",
        dataIndex: "content",
        key: "content",
        render: (_: any, record: ITemplateSMS, index: number) => {
          const options =
            record.station === LMMN
              ? optionsContentSMSLMMN
              : optionsContentSMSCDRC;
          return (
            <div>
              <div className="flex items-center flex-wrap gap-y-1">
                {record?.tags?.map((item, index) => {
                  const itemFind = options.find((el) => el.value === item);
                  return (
                    <Tag color="#E7E7EE" className="!text-black">
                      {itemFind?.label}
                    </Tag>
                  );
                })}
              </div>

              <p className="mt-2">{record.message}</p>
            </div>
          );
        },
      },
      {
        title: "Ngày tạo",
        dataIndex: "created_date",
        key: "created_date",
        render: (_: any, record: ITemplateSMS, index: number) => {
          return <p>{moment(record.created_date).format("DD-MM-YYYY")}</p>;
        },
      },
      {
        title: "Trạng thái",
        dataIndex: "status",
        key: "status",

        render: (_: any, record: ITemplateSMS) => {
          const statusText = record.status ? "Hoạt động" : "Ẩn";
          return (
            <div className="flex items-center justify-start gap-2">
              <div
                className="w-1.5 h-1.5 rounded-full"
                style={{
                  backgroundColor: record.status ? "#52C41A" : "#D9D9D9",
                }}
              />
              <p>{statusText}</p>
            </div>
          );
        },
      },
      {
        title: "Thao tác",
        dataIndex: "action",
        key: "action",
        render: (_: any, record: ITemplateSMS) => {
          return (
            <CommonStyles.ButtonAction
              moreItems={[
                {
                  key: "switch",
                  label: "Thay đổi trạng thái",
                  action: () => {
                    actionSwitch(record);
                  },
                },
              ]}
              handleInfo={() => {
                hanldeInfo(record);
              }}
              handleEdit={() => {
                hanldeEdit(record);
              }}
              handleDelete={() => {
                handleDelete(record);
              }}
            />
          );
        },
      },
    ];
  }, [isLoading]);
  const extraAction = () => {
    return (
      <CommonStyles.ButtonUI
        onClick={handleAddSMS}
        Icons={<CommonIcons.PlusOutlined />}
        className={ResponseUI.button}
      >
        Cấu hình SMS
      </CommonStyles.ButtonUI>
    );
  };
  return (
    <div className="flex flex-col gap-5">
      <CommonStyles.TitleCardUI
        title="SMS đa kênh"
        subTitle={[
          {
            title: "Quản lý hệ thống",
            link: baseRouter.sms,
          },
          {
            title: "SMS đa kênh",
            link: baseRouter.sms,
          },
        ]}
        extra={extraAction()}
      />
      <CommonStyles.TableUI
        dataSource={data?.data.page_contents}
        columns={renderColumns() ?? []}
        scroll={{
          x: 1000,
        }}
      />

      <CommonStyles.PaginationUI
        page={filters.page + 1}
        totalElement={data?.data.total_records}
        pageSize={filters.limit}
        onChangePage={handleChangePage}
      />

      {shouldRender && (
        <CommonStyles.ModalUI
          toggle={toggle}
          hiddenAction
          open={open}
          title="Cấu hình SMS"
          className="!w-[600px]"
        >
          <ModalSMS
            toggle={toggle}
            refetch={refetch}
            detailTemplate={detailTemplate}
          />
        </CommonStyles.ModalUI>
      )}
    </div>
  );
}
