import { urlsAPISMS } from "../../constants/api";
import httpService from "../../services/httpService";

class SMSService {
  postPhone(body: any, id?: string) {
    return httpService.post(`${urlsAPISMS.phone.postPhone}?${id}`, body);
  }
  getPhone(query: string) {
    return httpService.get(`${urlsAPISMS.phone.getPhone}?${query}`);
  }
  postTemplate(body: any, id?: string) {
    if (id) {
      return httpService.post(
        `${urlsAPISMS.phone.postTemplate}?id=${id}`,
        body
      );
    } else {
      return httpService.post(`${urlsAPISMS.phone.postTemplate}`, body);
    }
  }

  getTemplates(query: string) {
    return httpService.get(`${urlsAPISMS.phone.getTemplate}?${query}`);
  }

  sendSMS(group: string, body: any) {
    return httpService.post(`${urlsAPISMS.sms.send}?id_group=${group}`, body);
  }

  //! group phone
  setGroupPhone(body: any, id?: string) {
    if (id) {
      return httpService.post(`${urlsAPISMS.groupPhones.api}?id=${id}`, body);
    } else {
      return httpService.post(`${urlsAPISMS.groupPhones.api}`, body);
    }
  }

  getGroupPhones(query: string) {
    return httpService.get(`${urlsAPISMS.groupPhones.api}?${query}`);
  }

  deteleGroupPhone(id: string) {
    return httpService.delete(`${urlsAPISMS.groupPhones.api}?id=${id}`);
  }
}

export default new SMSService();
