import React, { useState, useEffect } from "react";
import { Layout, notification, theme } from "antd";
import Navbar from "./default/Navbar";
import SiderMenu from "./default/SiderMenu";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import CommonImages from "../assets/images";

const { Header, Sider, Content } = Layout;

const DefaultLayout: React.FC = () => {
  //! state
  const [breadcrumbs, setBreadcrumb] = useState<Array<Object>>([]);
  const [collapsed, setCollapsed] = useState(false);
  const [documentWidth, setDocumentWidth] = useState<number>(
    document.documentElement.clientWidth ?? 0
  );
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  let location = useLocation();
  const navigate = useNavigate();
  //! function
  useEffect(() => {
    setBreadcrumb([{ title: location.pathname.replace("/", "") }]);
  }, [location]);

  useEffect(() => {
    window.addEventListener("resize", onResize, { passive: true });
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const onResize = () => {
    setDocumentWidth(document.documentElement.clientWidth);
  };

  const onClickOutSideSider = () => {
    if ((documentWidth ? documentWidth : 0) <= 1024 && !collapsed) {
      setCollapsed(true);
    }
  };
  useEffect(() => {
    if (Number(documentWidth) <= 1024) {
      setCollapsed(true);
    }
  }, [documentWidth]);
  return (
    <Layout>
      <OutsideClickHandler onOutsideClick={() => onClickOutSideSider()}>
        <Sider
          breakpoint="lg"
          onCollapse={(collapsed, type) => {}}
          collapsible={false}
          collapsed={collapsed}
          trigger={null}
          width={240}
          collapsedWidth={(documentWidth ? documentWidth : 0) <= 1024 ? 0 : 64}
          className={
            "h-screen !bg-main-color" +
            ((documentWidth ? documentWidth : 0) <= 1024
              ? !collapsed
                ? " !fixed top-0 bottom-0 z-1030"
                : " hidden"
              : "")
          }
        >
          <a
            className={
              "flex items-center align-middle py-3" +
              (collapsed ? " text-center" : " px-2")
            }
            href="/"
          >
            <img
              className={
                "h-10 w-10 text-white" +
                (collapsed ? " block mx-auto transition duration-500" : "")
              }
              src={CommonImages.logoDash}
            />
            <span
              className={
                "text-xl text-white w-full text-left ml-4 uppercase font-medium" +
                (collapsed ? " hidden duration-300" : "")
              }
            >
              Vegastar
            </span>
          </a>
          <SiderMenu />
        </Sider>
      </OutsideClickHandler>
      <Layout
        className={
          "overflow-y-auto" +
          ((documentWidth ? documentWidth : 0) > 1024
            ? " h-screen"
            : " h-screen")
        }
      >
        <Header
          className="sticky top-0 z-1080 border-b border-gray-100"
          style={{ padding: 0, background: colorBgContainer }}
        >
          <Navbar
            breadcrumbs={breadcrumbs}
            collapsed={collapsed}
            setCollapsed={(val: boolean) => setCollapsed(val)}
          />
        </Header>
        <Content className={"m-4 md:m-6 h-full"}>
          <Outlet></Outlet>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
