import { urlsAPI } from "../../constants/api";
import httpService from "../../services/httpService";

class Relation {
  info(query: string) {
    return httpService.get(`${urlsAPI.relation.getInfo}?${query}`);
  }
}

export default new Relation();
