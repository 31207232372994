import React, { useEffect } from "react";
import { CommonStyles } from "../../../ui";
import { Form } from "antd";
import { useAppDispatch } from "../../../../hooks/hooks";
import useGetAttributeKey from "../../../../modules/telemetry/hooks/useGetAttributeKey";
import { ENTITY_TYPE, SCOPE } from "../../../../constants/Index";
import {
  getErrorMsg,
  getStatusAction,
  parseQueryString,
  showMsg,
} from "../../../../helpers";
import telemetryService from "../../../../modules/telemetry/telemetry.service";
import Regex from "../../../../constants/regex";
interface IProps {
  idStation: string;
}
export default function CDRCSoftware(props: IProps) {
  //! state
  const { idStation } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { data, isLoading, refetch } = useGetAttributeKey(
    ENTITY_TYPE,
    idStation,
    true,
    parseQueryString({
      keys: "crackMetterThresholdHigh,vectorGalMaxThresholdHigh,intensityMaxThresholdHigh,crackMetterThresholdLow,crackMetterStartSoftware",
    })
  );
  const crackMetterStartSoftware = data?.data?.find(
    (item) => item.key === "crackMetterStartSoftware"
  );
  const crackMetterThresholdHigh = data?.data.find(
    (item) => item.key === "crackMetterThresholdHigh"
  );
  const vectorGalMaxThresholdHigh = data?.data.find(
    (item) => item.key === "vectorGalMaxThresholdHigh"
  );
  const intensityMaxThresholdHigh = data?.data.find(
    (item) => item.key === "intensityMaxThresholdHigh"
  );

  const crackMetterThresholdLow = data?.data.find(
    (item) => item.key === "crackMetterThresholdLow"
  );

  const initialValues = {
    crackMetterStartSoftware:
      (crackMetterStartSoftware && Number(crackMetterStartSoftware.value)) ?? 0,
    crackMetterThresholdHigh: Number(crackMetterThresholdHigh?.value) ?? 0,
    crackMetterThresholdLow: Number(crackMetterThresholdLow?.value) ?? 0,

    vectorGalMaxThresholdHigh: Number(vectorGalMaxThresholdHigh?.value) ?? 0,

    intensityMaxThresholdHigh: Number(intensityMaxThresholdHigh?.value) ?? 0,
  };
  const initValue = [
    (crackMetterStartSoftware && Number(crackMetterStartSoftware.value)) ?? 0,
    Number(crackMetterThresholdHigh?.value) ?? 0,
    Number(crackMetterThresholdLow?.value) ?? 0,
    Number(vectorGalMaxThresholdHigh?.value) ?? 0,
    Number(intensityMaxThresholdHigh?.value) ?? 0,
  ];
  const valueCrackMetterStartSoftware = Form.useWatch(
    "crackMetterStartSoftware",
    form
  );
  const valueCrackMetterThresholdHigh = Form.useWatch(
    "crackMetterThresholdHigh",
    form
  );
  const valueCrackMetterThresholdLow = Form.useWatch(
    "crackMetterThresholdLow",
    form
  );
  const valueVectorGalMaxThresholdHigh = Form.useWatch(
    "vectorGalMaxThresholdHigh",
    form
  );
  const valueIntensityMaxThresholdHigh = Form.useWatch(
    "intensityMaxThresholdHigh",
    form
  );
  //! function
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [isLoading]);

  const handleSubmit = async () => {
    const values = form.getFieldsValue();
    const valid = form.getFieldsError();
    let check = true;
    valid.forEach((element) => {
      if (element.errors.length > 0) {
        check = false;
      }
    });
    if (!check) {
      return;
    }
    try {
      const postSoftware = await telemetryService.postDeviceTele(
        idStation,
        SCOPE.SHARED_SCOPE,
        values
      );
      if (postSoftware.status === 200) {
        refetch();
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };
  //! render
  const renderExtra = () => {
    return (
      <CommonStyles.ButtonUI
        disabled={getStatusAction(initValue, [
          Number(valueCrackMetterStartSoftware),
          Number(valueCrackMetterThresholdHigh),
          Number(valueCrackMetterThresholdLow),
          Number(valueVectorGalMaxThresholdHigh),
          Number(valueIntensityMaxThresholdHigh),
        ])}
        onClick={handleSubmit}
        size="small"
      >
        Hoàn thành
      </CommonStyles.ButtonUI>
    );
  };
  return (
    <CommonStyles.SubCard
      title="Cập nhật ngưỡng cảnh báo phần mềm"
      extra={renderExtra()}
    >
      <Form
        form={form}
        name="softwareCDRC"
        initialValues={initialValues}
        layout="vertical"
        className="md:grid md:grid-cols-2 gap-3"
      >
        <CommonStyles.FormFieldUI
          name={"crackMetterStartSoftware"}
          label="Độ dài dây căng ban đầu (Cm)"
          className="col-span-2"
          rules={[
            {
              required: true,
              message: "Độ dài dây căng ban đầu không được trống!",
            },
            {
              pattern: Regex.number,
              message: "Giá trị cần là số!",
            },
          ]}
          component={
            <CommonStyles.InputUI
              maxLength={50}
              className="h-9 border !border-s-line active:!border-main-color"
            />
          }
        />

        <CommonStyles.FormFieldUI
          name={"crackMetterThresholdHigh"}
          label="Dây căng cao (Cm)"
          rules={[
            {
              required: true,
              message: "Dây căng cao không được trống!",
            },
            {
              pattern: Regex.number,
              message: "Giá trị cần là số!",
            },
          ]}
          component={
            <CommonStyles.InputUI
              maxLength={50}
              className="h-9 border !border-s-line active:!border-main-color"
            />
          }
        />
        <CommonStyles.FormFieldUI
          name={"crackMetterThresholdLow"}
          label="Dây căng thấp (Cm)"
          rules={[
            {
              required: true,
              message: "Dây căng thấp không được trống!",
            },
            {
              pattern: Regex.number,
              message: "Giá trị cần là số!",
            },
          ]}
          component={
            <CommonStyles.InputUI
              maxLength={50}
              className="h-9 border !border-s-line active:!border-main-color"
            />
          }
        />
        <CommonStyles.FormFieldUI
          name={"vectorGalMaxThresholdHigh"}
          label="Gia tốc rung chấn (Gal)"
          rules={[
            {
              required: true,
              message: "Gia tốc rung chấn không được trống!",
            },
            {
              pattern: Regex.number,
              message: "Giá trị cần là số!",
            },
          ]}
          component={
            <CommonStyles.InputUI
              maxLength={50}
              className="h-9 border !border-s-line active:!border-main-color"
            />
          }
        />

        <CommonStyles.FormFieldUI
          name={"intensityMaxThresholdHigh"}
          label="Cường độ rung chấn cao (Level)"
          rules={[
            {
              required: true,
              message: "Cường độ rung chấn cao không được trống!",
            },
            {
              pattern: Regex.number,
              message: "Giá trị cần là số!",
            },
          ]}
          component={
            <CommonStyles.InputUI
              maxLength={50}
              className="h-9 border !border-s-line active:!border-main-color"
            />
          }
        />
      </Form>
    </CommonStyles.SubCard>
  );
}
