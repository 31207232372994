import { useCallback, useEffect, useState } from "react";
import { ResponseGetCustomers } from "../customer.interface";
import customerService from "../customer.service";

const useGetCustomers = (query: string) => {
  const [data, setData] = useState<ResponseGetCustomers>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return customerService.getCustomers(query);
  }, []);

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [query]);

  const transformResponse = useCallback((response: ResponseGetCustomers) => {
    if (response) {
      setData(response);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { data, error, isLoading, refetch };
};

export default useGetCustomers;
