import React, { useEffect, useState } from "react";
import { CommonStyles } from "../../../components/ui";
import baseRouter from "../../../router/baseRouter";
import CommonIcons from "../../../assets/icons";
import ResponseUI from "../../../constants/response";
import useToggle from "../../../hooks/useToggle";
import ModalGroupPhone from "./ModalGroupPhone";
import useGetGroupPhones from "../../../modules/sms/hooks/useGetGroupPhones";
import {
  getCreatedTime,
  getErrorMsg,
  limitTextString,
  parseQueryString,
  showMsg,
} from "../../../helpers";
import { IGroupPhone } from "../../../modules/sms/groupPhone.interface";
import { Tag } from "antd";
import { useAppDispatch } from "../../../hooks/hooks";
import smsService from "../../../modules/sms/sms.service";

export default function SMSGroupPhone() {
  //! state
  const { open, shouldRender, toggle } = useToggle();
  const dispatch = useAppDispatch();
  const [filters, setFiletrs] = useState({
    page: 0,
    limit: 10,
  });
  const [detailGroup, setDetailGroup] = useState<{
    id: null | string;
    type: "info" | "edit" | "create";
  }>({
    id: null,
    type: "create",
  });
  const { data, isLoading, refetch } = useGetGroupPhones(
    parseQueryString(filters),
    false
  );
  //! function
  const handleDelete = async (id: string) => {
    try {
      const result = await smsService.deteleGroupPhone(id);
      if (result.status === 200) {
        refetch();
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };
  const handleInfo = (record: IGroupPhone) => {
    toggle();
    setDetailGroup({
      id: record.id,
      type: "info",
    });
  };
  const handleAdd = () => {
    setDetailGroup({
      id: null,
      type: "create",
    });
    toggle();
  };
  const handleEdit = (record: IGroupPhone) => {
    toggle();
    setDetailGroup({
      id: record.id,
      type: "edit",
    });
  };
  const hamdleChangeStatus = async (record: IGroupPhone) => {
    try {
      const data = {
        ...record,
        status: !record.status,
      };
      const result = await smsService.setGroupPhone(data, record.id);

      if (result.status === 200) {
        refetch();
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };
  const handleChangePage = (page: number) => {
    setFiletrs((prev) => ({ ...prev, page: page - 1 }));
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      render: (_: any, record: any, index: number) => (
        <p>{index + filters.page * filters.limit + 1}</p>
      ),
    },
    {
      title: "Tên nhóm",
      dataIndex: "name",
      key: "name",
      render: (_: any, record: IGroupPhone) => {
        return <p>{limitTextString(record.name, 30)}</p>;
      },
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone_number",
      key: "phone_number",
      width: "30%",
      render: (_: any, record: IGroupPhone) => {
        return (
          <div>
            {record.phone_number.map((item, index) => {
              return <Tag key={index}>{item}</Tag>;
            })}
          </div>
        );
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "created_date",
      key: "created_date",
      render: (_: any, record: IGroupPhone) => {
        return <p>{getCreatedTime(record.created_date) ?? ""}</p>;
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (_: any, record: IGroupPhone) => {
        const statusText = record.status ? "Hoạt động" : "Ẩn";
        return (
          <div className="flex items-center justify-start gap-2">
            <div
              className="w-1.5 h-1.5 rounded-full"
              style={{
                backgroundColor: record.status ? "#52C41A" : "#D9D9D9",
              }}
            />
            <p>{statusText}</p>
          </div>
        );
      },
    },
    {
      title: "Thao tác",
      dataIndex: "action",
      key: "action",
      render: (_: any, record: IGroupPhone) => {
        return (
          <CommonStyles.ButtonAction
            handleInfo={() => {
              handleInfo(record);
            }}
            handleEdit={() => {
              handleEdit(record);
            }}
            handleDelete={() => {
              handleDelete(record.id);
            }}
            moreItems={[
              {
                label: "Thay đổi trạng thái",
                key: "status",
                action: () => {
                  hamdleChangeStatus(record);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  //! render
  const extraAction = () => {
    return (
      <CommonStyles.ButtonUI
        onClick={handleAdd}
        Icons={<CommonIcons.PlusOutlined />}
        className={ResponseUI.button}
      >
        Cấu hình số điện thoại
      </CommonStyles.ButtonUI>
    );
  };

  return (
    <div className="flex flex-col gap-5">
      <CommonStyles.TitleCardUI
        title="Cấu hình số điện thoại"
        subTitle={[
          {
            title: "Quản lý hệ thống",
            link: `${baseRouter.sms}${baseRouter.smsGroupPhone}`,
          },
          {
            title: "SMS đa kênh",
            link: `${baseRouter.sms}${baseRouter.smsGroupPhone}`,
          },
          {
            title: "Cấu hình số điện thoại",
            link: `${baseRouter.sms}${baseRouter.smsGroupPhone}`,
          },
        ]}
        extra={extraAction()}
      />

      <CommonStyles.TableUI
        dataSource={data?.data.page_contents}
        columns={columns ?? []}
        loading={isLoading}
        scroll={{
          x: 1000,
        }}
      />

      <CommonStyles.PaginationUI
        page={filters.page + 1}
        totalElement={data?.data.total_records}
        pageSize={filters.limit}
        onChangePage={handleChangePage}
      />

      {shouldRender && (
        <CommonStyles.ModalUI
          title="Cấu hình số điện thoại"
          toggle={toggle}
          open={open}
          hiddenAction
        >
          <ModalGroupPhone
            detailGroup={detailGroup}
            refetch={refetch}
            toggle={toggle}
          />
        </CommonStyles.ModalUI>
      )}
    </div>
  );
}
