import { Input, Tag } from "antd";
import React, { useCallback, useState } from "react";
import TooltipUI from "../../tooltip/TooltipUI";
import { limitTextString } from "../../../../helpers";
import { isEmpty } from "lodash";

interface IProps {
  defaultValue: string[];
  limit?: number;
  onChangeMulti: (value: string) => void;
  onCloseTag?: (index: number) => void;
  errorField?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
}
export default function InputMulti(props: IProps) {
  //! state
  const {
    defaultValue,
    limit,
    onChangeMulti,
    onCloseTag,
    errorField,
    label,
    placeholder,
    disabled,
  } = props;
  const [inputValue, setINputValue] = useState("");

  //! function

  const onPressEnter = (e: any) => {
    if (limit) {
      if (defaultValue?.length < limit) {
        onChangeMulti && onChangeMulti(e.target.value);
      }
      setINputValue("");

      return;
    }

    onChangeMulti && onChangeMulti(e.target.value);
    setINputValue("");
  };
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setINputValue(e.target.value);
  };
  //! render
  const renderItem = useCallback(() => {
    return defaultValue.map((item: any, index: any) => {
      return (
        <TooltipUI title={item}>
          <Tag
            closable={true}
            onClose={() => onCloseTag && onCloseTag(index)}
            className="w-fit flex-none px-3 border rounded-2xl flex items-center gap-1 h-7"
          >
            {limit && limit ? limitTextString(item, 25) : item}
          </Tag>
        </TooltipUI>
      );
    });
  }, [defaultValue.length]);
  return (
    <div>
      {label && <p className="mb-2">{label}</p>}
      <div className="border rounded-md p-1">
        <div className="flex flex-wrap w-full gap-y-2">
          {renderItem()}
          <Input
            value={inputValue}
            onChange={onChangeInput}
            onPressEnter={onPressEnter}
            className="grow border-none focus:shadow-none w-fit h-7"
            placeholder={placeholder}
            disabled={disabled}
          />
        </div>
      </div>
      {errorField && (
        <p
          className={`text-red-500 font-light ${
            errorField ? "visible" : "invisible"
          }`}
        >
          {errorField}
        </p>
      )}
    </div>
  );
}
