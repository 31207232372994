import {
  VideoCameraOutlined,
  UserOutlined,
  HomeOutlined,
  AppstoreAddOutlined,
  EnvironmentFilled,
  EnvironmentOutlined,
  BellFilled,
  CloseOutlined,
  CheckOutlined,
  LineChartOutlined,
  InfoCircleOutlined,
  BellOutlined,
  CalendarOutlined,
  RightOutlined,
  DownOutlined,
  QuestionCircleOutlined,
  WarningOutlined,
  SearchOutlined,
  FilterOutlined,
  PlusCircleOutlined,
  PlusCircleFilled,
  SendOutlined,
  CaretUpOutlined,
  ScheduleOutlined,
  PlusOutlined,
  MoreOutlined,
  LoadingOutlined,
  EditOutlined,
  EyeOutlined,
  SettingOutlined,
  InfoCircleFilled,
  DeleteOutlined,
} from "@ant-design/icons";

const CommonIcons = {
  VideoCamera: VideoCameraOutlined,
  User: UserOutlined,
  Home: HomeOutlined,
  AppStoreAdd: AppstoreAddOutlined,
  Enviroment: EnvironmentFilled,
  EnviromentOutline: EnvironmentOutlined,
  Bell: BellFilled,
  BellOutline: BellOutlined,
  Close: CloseOutlined,
  Check: CheckOutlined,
  LineChart: LineChartOutlined,
  InforCricle: InfoCircleOutlined,
  Calendar: CalendarOutlined,
  RightOutline: RightOutlined,
  DownOutlined: DownOutlined,
  QuestionCircle: QuestionCircleOutlined,
  WarningOutlined: WarningOutlined,
  SearchOutlined,
  FilterOutlined,
  PlusCircleOutlined,
  PlusCircleFilled,
  SendOutlined,
  CaretUpOutlined,
  ScheduleOutlined,
  PlusOutlined,
  MoreOutlined,
  LoadingOutlined,
  EditOutlined,
  EyeOutlined,
  SettingOutlined,
  InfoCircleFilled,
  DeleteOutlined,
};

export default CommonIcons;
