import { Button, ButtonProps } from "antd";

interface IProps extends ButtonProps {
  Icons?: any;
  onClick?: (e?: any) => void;
  children?: any;
  className?: string;
  danger?: boolean;
  disabled?: boolean;
  ghost?: boolean;
  href?: string;
  type?: "primary" | "dashed" | "link" | "text" | "default";
  loading?: boolean;
  shape?: "default" | "circle" | "round";
  size?: "large" | "middle" | "small";
  styles?: any;
  action?: "button" | "reset" | "submit";
  ref?: any;
}

export default function ButtonUI(props: IProps) {
  const {
    children,
    onClick,
    Icons,
    styles,
    className,
    danger,
    disabled,
    ghost,
    href,
    loading,
    shape,
    size,
    type,
    action,
    ref,
    ...res
  } = props;
  return (
    <Button
      className={`xs:text-xs sm:text-sm cursor-pointer ${className}`}
      type={type ?? "primary"}
      htmlType={action}
      icon={Icons ? Icons : null}
      danger={danger}
      disabled={disabled}
      ghost={ghost}
      href={href}
      loading={loading}
      shape={shape}
      size={size}
      style={styles}
      {...res}
      onClick={onClick}
      ref={ref}
    >
      {children}
    </Button>
  );
}
