import moment from "moment";
import { IDateFilter } from "../interface/IDateFilter";
import _ from "lodash";

export interface IDataTime {
  hour: number;
  date: number;
  month: number;
  year?: number;
  data: any;
}

export const checkFilterDate = (item: any, dateFilter: IDateFilter) => {
  const dataTime: IDataTime[] = [];
  const convertedDate = moment.utc(item.ts);
  const hour = convertedDate.hour();
  const date = convertedDate.date();
  const month = convertedDate.month();
  let indexFind = NaN;
  if (dateFilter.type === "hour") {
    indexFind = dataTime?.findIndex(
      (item) => item.hour === hour && item.date === date
    );
  }
  if (dateFilter.type === "date") {
    indexFind = dataTime?.findIndex((item) => item.date === date);
  }
  if (dateFilter.type === "month") {
    indexFind = dataTime?.findIndex((item) => item.month === month + 1);
  }

  if (indexFind > -1) {
    dataTime[indexFind].data.push(item.value);
  } else {
    const timeNew: IDataTime = {
      hour,
      date,
      month: month + 1,
      data: [item.value],
    };
    dataTime.push(timeNew);
  }
  return dataTime;
};

export const getLabels = (dataChart: any, dateFilter: IDateFilter) => {
  if (dataChart?.length > 0) {
    _.reverse(dataChart);
  } else {
    return [""];
  }

  if (dateFilter.type === "hour" && dataChart.length > 0) {
    return dataChart?.map((item: any) => {
      return `${String(item.hour)}:00`;
    });
  }
  if (dateFilter.type === "date" && dataChart.length > 0) {
    return dataChart?.map((item: any) => {
      return `${String(item.date)}`;
    });
  }
  if (dateFilter.type === "month" && dataChart.length > 0) {
    return dataChart?.map((item: any) => {
      return `${String(item.month)}`;
    });
  }
};

export const getDataFilterDate = (
  time: number,
  data: any,
  timeSeries?: any
) => {
  const convertedDate = moment(time);
  const minute = convertedDate.minutes();
  const hour = convertedDate.subtract(9, "hours").hour();
  const date = convertedDate.add(7, "hours").date();
  const month = convertedDate.month();
  const year = convertedDate.year();
  return {
    minute: minute === 0 ? "00" : minute,
    hour,
    date,
    month: month + 1,
    data,
    timeSeries,
    year,
  };
};
export const getDataFilterDateFullTime = (time: number, data: any) => {
  const convertedDate = moment(time);
  const minute = convertedDate.format("mm");
  const hour = convertedDate.subtract(9, "hours").format("HH");
  const date = convertedDate.format("DD");
  // const date = convertedDate.add(9, "hours").format("DD");
  const month = convertedDate.format("MM");
  const year = convertedDate.format("YYYY");

  return {
    minute,
    hour,
    date,
    month: month,
    data,
    year,
  };
};

export const valueIncreaseByFilterDate = (dateFilter: IDateFilter) => {
  if (dateFilter.type === "hour") {
    return 3600000;
  }
  if (dateFilter.type === "date") {
    return 86400000;
  }
  if (dateFilter.type === "month") {
    // return 604800000; // 7days
    return 86400000;
  }
  return 3600000;
};
