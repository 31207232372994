import React from "react";
import { CommonStyles } from "../../../ui";
import { useAppDispatch } from "../../../../hooks/hooks";
import useGetAttributeKey from "../../../../modules/telemetry/hooks/useGetAttributeKey";
import { ENTITY_TYPE, SCOPE } from "../../../../constants/Index";
import { getErrorMsg, parseQueryString, showMsg } from "../../../../helpers";
import telemetryService from "../../../../modules/telemetry/telemetry.service";
interface IProps {
  idStation: string;
}
export default function SMS(props: IProps) {
  //! state
  const { idStation } = props;
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetAttributeKey(
    ENTITY_TYPE,
    idStation,
    true,
    parseQueryString({ keys: "SMS" })
  );
  const sms = data?.data[0];
  //! function
  const handleChange = async (value: any) => {
    try {
      const postSMS = await telemetryService.postDeviceTele(
        idStation,
        SCOPE.SHARED_SCOPE,
        { SMS: value ? "on" : "off" }
      );
      if (postSMS.status === 200) {
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };
  //! render
  return (
    <CommonStyles.SubCard
      title={"Bật tin nhắn SMS"}
      className="sm:justify-center md:justify-normal"
    >
      {isLoading ? (
        <CommonStyles.LoadingUI />
      ) : (
        <div className="flex justify-center items-center py-3">
          <CommonStyles.SwichUI
            defaultChecked={sms?.value === "on" ? true : false}
            onChange={handleChange}
          />
        </div>
      )}
    </CommonStyles.SubCard>
  );
}
