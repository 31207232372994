import React, { useEffect, useState } from "react";
import TypographyUI from "../ui/typography/Typography";
import CommonIcons from "../../assets/icons";
import ButtonUI from "../ui/button/Button";
import { Divider, Empty } from "antd";
import { wsNotification } from "../../helpers/ws";
import { isEmpty } from "lodash";
import { CommonStyles } from "../ui";
import { useNavigate } from "react-router-dom";
import baseRouter from "../../router/baseRouter";
interface IProps {
  toggle: () => void;
  setCountNotification: any;
}
export default function NotificationsDasboard(props: IProps) {
  //! state
  const { toggle, setCountNotification } = props;
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  //! function
  let i = 0;
  let tempNotifications: any = [];
  const receiveNotification = (data: any) => {
    if (i == 0 && !isEmpty(data.notifications)) {
      tempNotifications = data.notifications;
      setNotifications(data.notifications);
      i = 1;
    } else {
      if (!isEmpty(data.update)) {
        const dataNew: any = [...tempNotifications];
        dataNew.splice(5, 1);
        dataNew.unshift(data.update);
        tempNotifications = dataNew;
        setNotifications(dataNew);
      }
    }
  };

  const clearNotifications = () => {
    tempNotifications = [];
    i = 0;
    setNotifications(tempNotifications);
    setCountNotification(0);
    wsNotification(
      "plugins/notifications",
      {
        markAllAsReadCmd: {
          cmdId: 1234,
        },
      },
      receiveNotification
    );
  };

  useEffect(() => {
    wsNotification(
      "plugins/notifications",
      {
        unreadSubCmd: {
          cmdId: 2,
          limit: 6,
        },
      },
      receiveNotification
    );
  }, []);

  //! render
  const renderMess = () => {
    if (notifications.length < 1) {
      return <Empty description={false} />;
    }
    return notifications?.map((item: any, index) => {
      const danger = item?.subject?.split(" - ")[1] === "'created'";
      return (
        <div>
          <div key={index} className="cursor-pointes p-3 ">
            <div className="flex items-center justify-between mb-1">
              <div className="flex items-center gap-3">
                <div className="w-1.5 h-1.5 rounded-full bg-main-color"></div>
                <TypographyUI typographyType="text" className="font-semibold">
                  {item?.subject?.split("-")[0]}
                </TypographyUI>
              </div>
              {danger && (
                <CommonStyles.ButtonUI size="small" danger ghost>
                  Nguy cấp
                </CommonStyles.ButtonUI>
              )}
            </div>
            <div className="ml-5">
              <div className="leading-3 text-sm text-[#626973]">
                <TypographyUI
                  typographyType="text"
                  className="text-sm text-[#626973]"
                >
                  {item?.text}
                </TypographyUI>
                <p className="mt-2"> {item?.time}</p>
              </div>
            </div>
          </div>
          <Divider className="my-1" />
        </div>
      );
    });
  };
  return (
    <div className="bg-white px-5 pt-5 pb-1 z-1000  md:w-[450px] ">
      <div className="h-[500px] overflow-y-scroll">
        <div className="flex justify-between items-center mb-3">
          <TypographyUI typographyType="title" level={4}>
            Thông báo
          </TypographyUI>
          <ButtonUI
            type="text"
            className="hover:!bg-transparent"
            Icons={<CommonIcons.Close />}
            onClick={toggle}
          />
        </div>
        <div className="flex justify-end my-3">
          {/* <CommonStyles.ButtonUI
            icon={<CommonIcons.Check />}
            size="small"
            onClick={clearNotifications}
          >
            Đánh dấu tất cả đã đọc
          </CommonStyles.ButtonUI> */}
        </div>

        <>{renderMess()}</>
      </div>
      {notifications.length > 0 && (
        <div className="flex justify-center items-center border-t">
          <CommonStyles.ButtonUI
            type="text"
            className="hover:!bg-transparent hover:!text-main-color"
            onClick={() => {
              navigate(`${baseRouter.notifications}`);
              toggle();
            }}
          >
            Xem tất cả
          </CommonStyles.ButtonUI>
        </div>
      )}
    </div>
  );
}
