import AutoCompleteUI from "../ui/autocomplete";
import { TOption } from "../../interface";
import { useAppSelector } from "../../hooks/hooks";
import useGetTenantDeviceInfos from "../../modules/device/hooks/useGetTenantDeviceInfos";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import baseRouter from "../../router/baseRouter";
import { debounce } from "lodash";
import useGetCustomerDeviceInfos from "../../modules/device/hooks/useGetCustomerDeviceInfos";

interface IFileters {
  page: number;
  pageSize: number;
  textSearch: string;
  sortProperty: "createdTime" | "name";
}
interface IProps {
  toggle: () => void;
}
export default function SearchDashboard(props: IProps) {
  //! state
  const { toggle } = props;
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);
  const [filters, setFiletrs] = useState<IFileters>({
    page: 0,
    pageSize: 10,
    textSearch: "",
    sortProperty: "name",
  });
  const {
    data: dTenantDevice,
    isLoading: loadingTenant,
    refetch: refetchTenant,
  } = useGetTenantDeviceInfos(filters, user?.authority === "TENANT_ADMIN");

  const {
    data: dCustomerDevice,
    isLoading: loadingCustomer,
    refetch: refetchCustomer,
  } = useGetCustomerDeviceInfos(
    String(user?.customerId.id),
    filters,
    user?.authority !== "TENANT_ADMIN"
  );
  //! function
  const options = useCallback(() => {
    if (user?.authority === "TENANT_ADMIN") {
      if (dTenantDevice?.data.data) {
        return dTenantDevice?.data.data.map((item) => {
          return { label: item.name, value: item.id.id };
        });
      }
    } else {
      if (dCustomerDevice?.data.data) {
        return dCustomerDevice?.data.data.map((item) => {
          return { label: item.name, value: item.id.id };
        });
      }
    }
  }, [loadingTenant, loadingCustomer]);

  const onSearch = debounce((value: string) => {
    setFiletrs((prev) => ({ ...prev, textSearch: value }));
  }, 1000);

  const onSelect = (data: string, options: TOption) => {
    toggle();
    navigate(`${baseRouter.station}/${data}`);
  };
  const onScroll = () => {
    if (dTenantDevice?.data.hasNext) {
      setFiletrs((prev) => ({ ...prev, pageSize: filters.pageSize + 10 }));
    }
  };
  useEffect(() => {
    if (user?.authority === "TENANT_ADMIN") {
      refetchTenant && refetchTenant();
    }
    if (user?.authority !== "TENANT_ADMIN") {
      refetchCustomer && refetchCustomer();
    }
  }, [filters]);
  //! render

  return (
    <AutoCompleteUI
      options={options() ?? []}
      onSearch={onSearch}
      onSelect={onSelect}
      placeholder="Tìm kiếm trạm ..."
      className="!w-52 sm:!w-[300px]  md:!w-[600px]"
      type="search"
      autoFocus
      onScroll={onScroll}
    />
  );
}
