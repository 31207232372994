import React from "react";
import { CommonStyles } from "../../ui";
import CommonIcons from "../../../assets/icons";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { useAppDispatch } from "../../../hooks/hooks";
import { getErrorMsg, showMsg } from "../../../helpers";
import telemetryService from "../../../modules/telemetry/telemetry.service";
import { SCOPE } from "../../../constants/Index";
import useToggle from "../../../hooks/useToggle";
import ResponseUI from "../../../constants/response";
interface IProps {
  idStation: string;
}
export default function Alarm(props: IProps) {
  //! station
  const { idStation } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { open, shouldRender, toggle } = useToggle();
  //! function
  const handleAPI = async (status: string) => {
    const postAlarm = await telemetryService.postDeviceTele(
      idStation,
      SCOPE.SHARED_SCOPE,
      {
        out3: status,
      }
    );
    return postAlarm;
  };
  const toggleAlarm = () => {
    toggle();
  };
  const handleAlarm = async () => {
    try {
      const res = await handleAPI("on");
      if (res.status === 200) {
        toggle();
        dispatch(
          showMsg("success", "Báo động đã bật và sẽ tự động tắt sau 30s")
        );
        setTimeout(async () => {
          const resOff = await handleAPI("off");
          if (resOff.status === 200) {
            dispatch(showMsg("success", "Báo động đã tắt"));
          }
        }, 30000);
      }
    } catch (error) {
      dispatch(showMsg("error", t(`${getErrorMsg(error)}`)));
    }
  };
  //! render
  return (
    <>
      <CommonStyles.ButtonUI
        Icons={<CommonIcons.Bell style={{ color: "white" }} />}
        danger
        onClick={toggleAlarm}
        className={ResponseUI.button}
      >
        Báo động
      </CommonStyles.ButtonUI>
      {shouldRender && (
        <CommonStyles.ModalUI
          open={open}
          type="error"
          toggle={toggle}
          title="Bạn muốn bật báo động ?"
          onConfirm={handleAlarm}
        />
      )}
    </>
  );
}
