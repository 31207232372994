import { AutoComplete } from "antd";
import { TOption } from "../../../interface";
import CommonIcons from "../../../assets/icons";

interface IProps {
  placeholder?: string;
  onSearch: (value: any) => void;
  options: TOption[];
  className?: string;
  type?: "text" | "search";
  onChange?: (e: any) => void;
  onScroll?: () => void;
  onSelect?: (data: string, options: TOption) => void;
  autoFocus?: boolean;
}
export default function AutoCompleteUI(props: IProps) {
  //! state
  const {
    onSearch,
    options,
    placeholder,
    className,
    type,
    onChange,
    onScroll,
    onSelect,
    autoFocus,
    ...res
  } = props;
  //! function
  //! render
  return (
    <div className="relative">
      <AutoComplete
        style={{ width: 200 }}
        className={`${className}`}
        size="large"
        onSearch={onSearch}
        onPopupScroll={(e: any) => {
          const { target } = e;
          if (
            (target as any).scrollTop + (target as any).offsetHeight ===
            (target as any).scrollHeight
          ) {
            onScroll && onScroll();
          }
        }}
        placeholder={placeholder}
        options={options}
        filterOption={(inputValue: any, options) => {
          return (
            options!.label
              .toString()
              .toUpperCase()
              .indexOf(inputValue.toUpperCase()) !== -1
          );
        }}
        onSelect={onSelect}
        autoFocus={autoFocus}
        defaultActiveFirstOption
        {...res}
      />
      {type === "search" && (
        <div className="absolute top-1/2 left-2 -translate-y-1/2 h-full">
          <CommonIcons.SearchOutlined />
        </div>
      )}
    </div>
  );
}
