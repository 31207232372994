import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";
import _ from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { Line, getElementAtEvent } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  //   ArcElement,
  //   BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface IProps {
  dataChart?: any;
  labels?: string[];
  displayLengend?: boolean;
}
export default function LineChart(props: IProps) {
  //! state
  const chartRef = useRef();
  const { dataChart, labels, displayLengend = true } = props;

  const options = {
    type: "line",
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    elements: {
      point: {
        radius: 0,
      },
    },

    plugins: {
      legend: {
        display: displayLengend,
        position: "bottom" as any,
        labels: {
          color: "black",
          boxHeight: 2,
          boxWidth: 30,
        },
      },
    },
    // scales: {
    //   y: {
    //     beginAtZero: true,
    //   },
    // },
  };

  const data = {
    labels: labels ?? [],
    datasets: dataChart,
  };
  //! function
  //! render

  return (
    <div
      className="h-full w-full relative"
      style={{
        minHeight: "250px",
      }}
    >
      <Line
        ref={chartRef}
        updateMode="resize"
        options={options as any}
        data={data}
      />
    </div>
  );
}
