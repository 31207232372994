import React, { useEffect, useState } from "react";
import { CommonStyles } from "../../ui";
import useGetTenantDeviceInfos from "../../../modules/device/hooks/useGetTenantDeviceInfos";
import { CDRC, SCOPE } from "../../../constants/Index";
import { IDevice } from "../../../modules/device/device.interface";
import { useNavigate } from "react-router-dom";
import baseRouter from "../../../router/baseRouter";
import useGetCustomerDeviceInfos from "../../../modules/device/hooks/useGetCustomerDeviceInfos";
import { useAppSelector } from "../../../hooks/hooks";
import telemetryService from "../../../modules/telemetry/telemetry.service";
import { parseQueryString } from "../../../helpers";

export default function CDRCTickets() {
  //! state
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    page: 0,
    pageSize: 10,
    type: CDRC,
  });
  const user = useAppSelector((state) => state.user.user);
  const role = user?.authority == "TENANT_ADMIN" ? "TENANT" : "CUSTOMER";
  const {
    data: dataListStation,
    isLoading: loadingListStation,
    refetch: refetchCustomer,
  } = useGetCustomerDeviceInfos(
    String(user?.customerId.id),
    filters,
    user?.authority !== "TENANT_ADMIN"
  );

  const {
    data: dataLisStationTenant,
    isLoading: loadingListStationTenant,
    refetch: refetchTenant,
  } = useGetTenantDeviceInfos(filters, user?.authority === "TENANT_ADMIN");

  const [dataTable, setDataTable] = useState<IDevice[]>([]);
  //! function
  const onChangePage = (value: number) => {
    setFilters((prev) => ({ ...prev, page: value - 1 }));
  };
  useEffect(() => {
    if (role === "TENANT") {
      refetchTenant();
    } else {
      refetchCustomer();
    }
  }, [filters]);
  useEffect(() => {
    const fetchData = async () => {
      if (
        (dataListStation && dataListStation?.data?.data?.length > 0) ||
        dataLisStationTenant?.data?.data
      ) {
        let data: IDevice[] = [];
        if (role === "TENANT") {
          if (dataLisStationTenant?.data?.data) {
            for (const iterator of dataLisStationTenant?.data?.data) {
              const ticketsData = await telemetryService.getAttributeScope(
                iterator.id.entityType,
                iterator.id.id,
                SCOPE.SERVER_SCOPE,
                parseQueryString({ keys: "hisTicket" })
              );
              const item = {
                ...iterator,
                count: Number(ticketsData.data[0]?.value?.length ?? 0),
              };

              data.push(item as IDevice);
            }
            setDataTable(data as IDevice[]);
          }
        } else {
          if (dataListStation?.data?.data) {
            for (const iterator of dataListStation?.data?.data) {
              const ticketsData = await telemetryService.getAttributeScope(
                iterator.id.entityType,
                iterator.id.id,
                SCOPE.SERVER_SCOPE,
                parseQueryString({ keys: "hisTicket" })
              );
              const item = {
                ...iterator,
                count: Number(ticketsData.data[0]?.value?.length ?? 0),
              };

              data.push(item as IDevice);
            }
            setDataTable(data as IDevice[]);
          }
        }
      }
    };

    fetchData();
  }, [
    dataListStation?.data?.data?.length,
    dataLisStationTenant?.data?.data?.length,
  ]);

  const onClickRow = (record: IDevice) => {
    navigate(`${baseRouter.tickets}/${record.id.id}`);
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      render: (_: any, record: IDevice, index: number) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: "Mã trạm",
      dataIndex: "code",
      key: "code",
      render: (_: any, record: IDevice) => {
        return <p>{record.name.split("/")[0]}</p>;
      },
    },
    {
      title: "Tên trạm",
      dataIndex: "name",
      key: "name",
      render: (_: any, record: IDevice) => {
        return <p>{record.name.split("/")[1]}</p>;
      },
    },
    {
      title: "Loại trạm",
      dataIndex: "type",
      key: "type",
      render: (_: any, record: IDevice) => {
        const stationType = record.type === CDRC ? "Sạt lở" : "Lũ quét";
        return <p>{stationType}</p>;
      },
    },
    {
      title: "Số lương",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Trạng thái",
      dataIndex: "active",
      key: "active",
      render: (_: any, record: IDevice) => {
        const status = record.active ? "Online" : "Offline";
        const color = record.active ? "bg-[#52C41A]" : "bg-[#E3E7ED]";

        return (
          <div className="flex items-center gap-1">
            <div className={`w-1.5 h-1.5 ${color} rounded-full`}></div>
            <p>{status}</p>
          </div>
        );
      },
    },
  ];
  //! render
  return (
    <div>
      <CommonStyles.TableUI
        dataSource={dataTable}
        columns={columns}
        loading={
          role === "TENANT" ? loadingListStationTenant : loadingListStation
        }
        rowClassName={"cursor-pointer"}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              onClickRow(record);
            },
          };
        }}
        scroll={{
          x: 1000,
        }}
      />
      <CommonStyles.PaginationUI
        page={filters.page + 1}
        pageSize={filters.pageSize}
        totalElement={
          role === "TENANT"
            ? dataLisStationTenant?.data.totalElements
            : dataListStation?.data.totalElements
        }
        onChangePage={onChangePage}
      />
    </div>
  );
}
