import { Typography } from "antd";
import React from "react";
import CommonIcons from "../../../assets/icons";
import { Link, NavLink } from "react-router-dom";

interface IPorps {
  title: string;
  subTitle: {
    title: string;
    link: string;
  }[];
  extra?: string | React.ReactNode;
}
export default function TitleCardUI(props: IPorps) {
  //! state
  const { subTitle, title, extra } = props;
  //! function
  //! render
  const renderSubTitle = () => {
    return subTitle?.map((item, index) => {
      const latest = index === subTitle.length - 1;
      return (
        <>
          <Link to={item.link} relative="path" className="!text-gray-500">
            {item.title}
          </Link>
          {!latest && <CommonIcons.RightOutline className="mx-2" />}
        </>
      );
    });
  };
  return (
    <div className="flex flex-col sm:flex-row  sm:justify-between sm:items-center gap-2  border rounded-xl p-3 bg-white border-white ">
      <div>
        <Typography.Title level={4} className="!font-medium">
          {title}
        </Typography.Title>
        <Typography.Text>{renderSubTitle()}</Typography.Text>
      </div>

      {extra && <div className="flex justify-end">{extra}</div>}
    </div>
  );
}
