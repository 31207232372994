import { Avatar, Divider, Form } from "antd";
import { CommonStyles } from "../../components/ui";
import { useAppSelector } from "../../hooks/hooks";
import baseRouter from "../../router/baseRouter";
import { checkRole } from "../../helpers";
import useAuth from "../../modules/auth/hooks/useAuth";
import Regex from "../../constants/regex";
import { optionsRole } from "../../constants/Index";
import warningJS from "../../assets/warningJS.json";
import CommonImages from "../../assets/images";
export default function Profile() {
  //! state
  const user = useAppSelector((state) => state.user.user);
  const { handleChangeProfile } = useAuth();
  const [form] = Form.useForm();
  const initialValues = {
    name: user?.name ?? "",
    email: user?.email ?? "",
    phone: user?.phone ?? "",
    lastName: user?.lastName ?? "",
    firstName: user?.firstName ?? "",
  };
  //! function
  const handleSave = (values: any) => {
    const valueSave = {
      ...values,
      id: user?.id,
      name: user?.name,
      lastName: user?.lastName,
      email: user?.email,
      tenantId: user?.tenantId,
      customerId: user?.customerId,
      authority: user?.authority,
    };
    handleChangeProfile(valueSave);
  };
  //! render

  return (
    <div className="flex flex-col gap-5">
      <CommonStyles.TitleCardUI
        title="Chỉnh sửa thông tin tài khoản"
        subTitle={[
          {
            title: "Thông tin tài khoản",
            link: baseRouter.profile,
          },
        ]}
      />
      {/* <div dangerouslySetInnerHTML={{ __html:  }}></div> */}
      <CommonStyles.CardResize>
        <div>
          <div className="flex gap-3 items-center">
            <div>
              <Avatar
                className="hover:cursor-pointer bg-transparent"
                size={"large"}
                icon={<img src={CommonImages.userDefault} />}
              />
            </div>
            <div>
              <CommonStyles.TypographyUI
                typographyType="title"
                level={4}
                className="!mb-0"
              >
                {user?.firstName}
              </CommonStyles.TypographyUI>
              <p>{checkRole(String(user?.authority))}</p>
            </div>
          </div>
          <Divider />
          <Form
            form={form}
            name="changePassword"
            layout="vertical"
            onFinish={handleSave}
            initialValues={initialValues}
          >
            <div className="grid  md:grid-cols-3 gap-5">
              <CommonStyles.FormFieldUI
                name="name"
                label={"Tên đăng nhập"}
                component={
                  <CommonStyles.InputUI
                    maxLength={50}
                    className="h-9 border !border-s-line active:!border-main-color"
                    disabled
                  />
                }
              />

              <CommonStyles.FormFieldUI
                name="email"
                label={"Email"}
                component={
                  <CommonStyles.InputUI
                    maxLength={50}
                    className="h-9 border !border-s-line active:!border-main-color"
                    disabled
                  />
                }
              />
              <CommonStyles.FormFieldUI
                name="phone"
                label={"Số điện thoại"}
                rules={[
                  {
                    required: true,
                    message: "Số điện thoại không được để trống!",
                  },
                  {
                    pattern: Regex.phoneVN,
                    message: "Số điện thoại không hợp lệ!",
                  },
                ]}
                component={
                  <CommonStyles.InputUI
                    maxLength={50}
                    className="h-9 border !border-s-line active:!border-main-color"
                  />
                }
              />

              <CommonStyles.FormFieldUI
                name="firstName"
                label={"Họ và Tên"}
                component={
                  <CommonStyles.InputUI
                    maxLength={50}
                    className="h-9 border !border-s-line active:!border-main-color"
                  />
                }
              />
              <CommonStyles.FormFieldUI
                name="lastName"
                label={"Quyển truy cập"}
                component={
                  <CommonStyles.SelectUI
                    options={optionsRole}
                    disabled
                    className=" !border-s-line active:!border-main-color h-9"
                  />
                }
              />
            </div>

            <div className="flex justify-center items-center mt-5">
              <CommonStyles.ButtonUI size="large" action="submit">
                Lưu thay đổi
              </CommonStyles.ButtonUI>
            </div>
          </Form>
        </div>
      </CommonStyles.CardResize>
    </div>
  );
}
