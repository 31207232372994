import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../modules/auth/user.interface";
import { USER } from "../../constants/localKey";

interface IUserState {
  user: IUser | null;
}
// const getUser =  ()=>{
//     localStorage.getItem(user)
// }
const initialState: IUserState = {
  user: JSON.parse(String(localStorage?.getItem(USER))) || {},
};
const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setUser: (state, action) => {
      localStorage.setItem(USER, JSON.stringify(action.payload));
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
