import { useEffect, useState } from "react";
import {
  ASSET_CDRC,
  ASSET_LMMN,
  ASSET_TYPE,
  ENTITY_TYPE,
} from "../../constants/Index";
import { useGetRelationInfo } from "../../modules/relation/hooks/useGetRelationInfo";
import { IAttributeScope } from "../../modules/telemetry/attributeScope.interface";
import telemetryService from "../../modules/telemetry/telemetry.service";
import { parseQueryString } from "../../helpers";
import { CommonStyles } from "../../components/ui";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import baseRouter from "../../router/baseRouter";

interface IRecord {
  stt: number;
  id: string;
  name: string;
  lat: IAttributeScope;
  lon: IAttributeScope;
  active: IAttributeScope;
}
export default function StationLMMN() {
  //! state
  const navigate = useNavigate();

  const { data: dLMMN, isLoading: loadingLMMN } = useGetRelationInfo({
    fromId: ASSET_LMMN,
    fromType: ASSET_TYPE,
  });
  const [dataTable, setDataTable] = useState<IRecord[]>([]);

  //! function
  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
    },
    {
      title: "Mã",
      dataIndex: "name",
      key: "code",
      render: (_: any, record: IRecord) => {
        return <>{record.name.split("/")[0]}</>;
      },
    },
    {
      title: "Tên trạm",
      dataIndex: "name",
      key: "name",
      render: (_: any, record: IRecord) => {
        return <>{record.name.split("/")[1]}</>;
      },
    },
    {
      title: "Vĩ độ",
      dataIndex: "lat",
      key: "lat",
      render: (_: any, record: IRecord) => {
        return <>{record.lat.value}</>;
      },
    },
    {
      title: "Kinh độ",
      dataIndex: "lon",
      key: "lon",
      render: (_: any, record: IRecord) => {
        return <>{record.lon.value}</>;
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "active",
      key: "active",
      render: (_: any, record: IRecord) => {
        const status = record.active.value ? "Online" : "Offline";
        const color = record.active.value ? "bg-[#52C41A]" : "bg-[#E7E7EE]";

        return (
          <div className="flex items-center gap-1">
            <div className={`w-1.5 h-1.5  rounded-full ${color}`}></div>
            <p>{status}</p>
          </div>
        );
      },
    },
  ];
  const onClickRow = (record: IRecord) => {
    navigate(`${baseRouter.station}/${record.id}`);
  };
  useEffect(() => {
    const fetchData = async () => {
      const newData: any = [];
      if (dLMMN?.data && dLMMN.data.length > 0) {
        await Promise.all(
          dLMMN?.data.map(async (device, index) => {
            const attributes = await telemetryService.getAttributeKey(
              ENTITY_TYPE,
              device.to.id,
              parseQueryString({
                keys: "lat,lon,active",
              })
            );

            const itemWithLatKey = attributes.data?.find(
              (item: any) => item?.key === "lat"
            );
            const itemWithLonKey = attributes.data?.find(
              (item: any) => item?.key === "lon"
            );
            const itemWithActiveKey = attributes.data?.find(
              (item: any) => item?.key === "active"
            );

            newData.push({
              stt: index + 1,
              id: device.to.id,
              name: device.toName,
              lat: itemWithLatKey,
              lon: itemWithLonKey,
              active: itemWithActiveKey,
            });
          })
        );
        setDataTable(newData);
      }
    };
    fetchData();
  }, [loadingLMMN]);
  //! render
  return (
    <div className="flex flex-col gap-5">
      <CommonStyles.TitleCardUI
        title="Danh sách trạm lũ quét"
        subTitle={[
          {
            title: "Danh sách trạm lũ quét",
            link: baseRouter.stationCDRC,
          },
        ]}
      />
      <CommonStyles.TableUI
        columns={columns}
        dataSource={_.sortBy(dataTable, ["stt"])}
        rowClassName={"cursor-pointer"}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              onClickRow(record);
            },
          };
        }}
        scroll={{
          x: 1000,
        }}
      />
    </div>
  );
}
