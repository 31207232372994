import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../hooks/hooks";
import { isEmpty } from "lodash";

const EmptyLayout: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);
  if (!isEmpty(user)) {
    return <Navigate to={"/"} />;
  }
  return (
    <>
      <Outlet></Outlet>
    </>
  );
};

export default EmptyLayout;
