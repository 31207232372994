import { CommonStyles } from "../ui";

import { useTranslation } from "react-i18next";

const FormAction = (props: any) => {
  const { t } = useTranslation();

  return (
    <div className="mt-8">
      <CommonStyles.ButtonUI
        type={props?.action}
        action="submit"
        className={
          "w-full h-12 block justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-main-color hover:bg-main-color/90"
        }
        onClick={props?.handleSubmit}
      >
        {t("Signin")}
      </CommonStyles.ButtonUI>
    </div>
  );
};
export default FormAction;
