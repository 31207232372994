import { useCallback, useEffect, useState } from "react";
import telemetryService from "../telemetry.service";
import { ResponseGetAttributeScope } from "../attributeScope.interface";

const useGetAttributeKey = (
  entityType: string,
  entityId: string,
  enabled = true,
  query?: string
) => {
  const [data, setData] = useState<ResponseGetAttributeScope>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return telemetryService.getAttributeKey(entityType, entityId, query);
  }, [enabled]);

  const transformResponse = useCallback(
    (response: ResponseGetAttributeScope) => {
      if (response) {
        setData(response);
      }
    },
    []
  );
  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [query]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled]);

  return { data, error, isLoading, refetch };
};

export default useGetAttributeKey;
