import _ from "lodash";
import { IDateFilter } from "../interface/IDateFilter";
import { useEffect, useState } from "react";

export const useGetLabelsChart = (
  dataChart: any,
  dateFilter: IDateFilter,
  loading: boolean,
  trigger?: boolean
) => {
  //! state
  const [labelChart, setLabelChart] = useState([""]);
  //! function
  const getLabels = (dataChart: any, dateFilter: IDateFilter) => {
    if (!dataChart || dataChart?.length < 0) {
      return [""];
    }

    if (dateFilter.value === "real") {
      return dataChart?.map((item: any) => {
        return `${String(item.hour)}:${item.minute}`;
      });
    }
    if (dateFilter.type === "second" && dataChart.length > 0) {
      const data = _.reverse(dataChart);

      return data?.map((item: any) => {
        return `${String(item.hour)}:${item.minute}`;
      });
    }

    if (dateFilter.type === "hour" && dataChart.length > 0) {
      // const data = _.reverse(dataChart);

      return dataChart?.map((item: any) => {
        return `${String(item.hour)}:00`;
      });
    }
    if (dateFilter.type === "date" && dataChart.length > 0) {
      return dataChart?.map((item: any) => {
        return `${String(item.date)}`;
      });
    }
    if (dateFilter.type === "month" && dataChart.length > 0) {
      return dataChart?.map((item: any) => {
        return `${String(item.date)}/${String(item.month)}`;
      });
    }
  };
  useEffect(() => {
    if (!loading) {
      const labels = getLabels(dataChart, dateFilter);
      setLabelChart(labels);
    }
  }, [loading, dataChart, trigger, dateFilter]);

  return { labelChart, getLabels };
};
