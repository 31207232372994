import { Spin } from "antd";
import styles from "./loading.module.css";
import CommonIcons from "../../../assets/icons";
export default function LoadingUI() {
  return (
    <div className="flex justify-center items-center h-full">
      {/* <div className={styles.loader}></div>
       */}
      <Spin
        indicator={
          <CommonIcons.LoadingOutlined style={{ fontSize: 50 }} spin />
        }
      />
    </div>
  );
}
