import logo from "./logo.png";
import backgroundWeather from "./backgroundWeather.svg";
import drop from "./drop.svg";
import dropWater from "./dropWater.svg";
import eye from "./eye.svg";
import sun from "./sun.svg";
import temperature from "./temperature.svg";
import weather from "./weather.svg";
import wind from "./wind.svg";
import maximize from "./maximize.svg";
import water from "./map/water.svg";
import satlo from "./map/satlo.svg";
import luongmua from "./map/luongmua.svg";
import position from "./map/position.svg";
import map from "./map/map.svg";
import canhbao from "./map/canhbao.svg";
import tabCDRC from "./tabCDRC.svg";
import tabLMMN from "./tabLMMN.svg";
import logoDash from "./logoDash.png";
import bgLogin from "./bg-login.png";
import satloOffline from "./map/satloOffline.svg";
import luquetOffline from "./map/luquetOffline.svg";
import userDefault from "./user-default.png";
import home from "./home.svg";
import setting from "./setting.svg";

const CommonImages = {
  logo,
  backgroundWeather,
  drop,
  dropWater,
  eye,
  sun,
  temperature,
  weather,
  wind,
  maximize,
  waterMap: water,
  satlo,
  luongmua,
  position,
  map,
  canhbao,
  tabCDRC,
  tabLMMN,
  logoDash,
  bgLogin,
  satloOffline,
  luquetOffline,
  userDefault,
  home,
  setting,
};

export default CommonImages;
