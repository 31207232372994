import { Form } from "antd";
import { CommonStyles } from "../../../components/ui";
import { TOption } from "../../../interface";
import CommonIcons from "../../../assets/icons";
import {
  CDRC,
  LMMN,
  optionsContentSMSCDRC,
  optionsContentSMSLMMN,
} from "../../../constants/Index";
import { useCallback, useEffect, useState } from "react";
import useGetDeviceType from "../../../modules/device/hooks/useGetDeviceType";
import { map } from "lodash";
import smsService from "../../../modules/sms/sms.service";
import { useAppDispatch } from "../../../hooks/hooks";
import { getErrorMsg, parseQueryString, showMsg } from "../../../helpers";
import useGetTemplates from "../../../modules/sms/hooks/useGetTemplates";
import ResponseUI from "../../../constants/response";

interface IProps {
  toggle: () => void;
  refetch: () => void;
  detailTemplate: {
    id: null | string;
    type: "info" | "edit" | "create";
  };
}
export default function ModalSMS(props: IProps) {
  //! state
  const { toggle, refetch, detailTemplate } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { data: dataOptions, isLoading: loadingOptions } = useGetDeviceType();

  const { data, isLoading } = useGetTemplates(
    parseQueryString({ id: detailTemplate.id }),
    !!detailTemplate.id
  );
  const [optionSMS, setOptionSMS] = useState(optionsContentSMSCDRC);
  const initialValues = {
    name: data?.data?.page_contents[0]?.name ?? "",
    station: data?.data?.page_contents[0]?.station ?? CDRC,
    tags: data?.data?.page_contents[0]?.tags ?? [],
    message: data?.data?.page_contents[0]?.message ?? "",
  };
  //! function
  const optionStation = useCallback(() => {
    return dataOptions?.data?.map((item) => {
      return {
        label: item.type === LMMN ? "Lũ lụt" : "Sạt lở",
        value: item.type,
      };
    });
  }, [loadingOptions]);

  const handleChoiceStation = (value: any) => {
    if (value === LMMN) {
      setOptionSMS(optionsContentSMSLMMN);
    } else {
      setOptionSMS(optionsContentSMSCDRC);
    }
    form.setFieldValue("station", value);

    form.setFieldValue("tags", []);
  };
  const handleSubmit = async (values: any) => {
    const item = {
      ...values,
      status: true,
    };
    try {
      if (detailTemplate.type === "edit") {
        const editTemplate = await smsService.postTemplate(
          {
            ...item,
            status: data?.data.page_contents[0].status,
            deleted: data?.data.page_contents[0].deleted,
          },
          String(detailTemplate.id)
        );
      } else {
        const postTemplate = await smsService.postTemplate(item);
      }
      toggle();
      refetch();
      dispatch(showMsg("success", "Thành công"));
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };
  useEffect(() => {
    if (data?.data.page_contents && data?.data.page_contents.length > 0) {
      if (data?.data?.page_contents[0]?.station === LMMN) {
        setOptionSMS(optionsContentSMSLMMN);
      } else {
        setOptionSMS(optionsContentSMSCDRC);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    const values = {
      name: data?.data?.page_contents[0]?.name ?? "",
      station: data?.data?.page_contents[0]?.station ?? CDRC,
      tags: data?.data?.page_contents[0]?.tags ?? [],
      message: data?.data?.page_contents[0]?.message ?? "",
    };
    form.setFieldsValue(values);
  }, [isLoading]);
  //! render
  return (
    <Form
      initialValues={initialValues}
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
    >
      <CommonStyles.FormFieldUI
        name="name"
        label="Tên mẫu"
        rules={[
          {
            required: true,
            message: "Tên mẫu không được để trống!",
          },
        ]}
        component={
          <CommonStyles.InputUI
            disabled={detailTemplate.type === "info"}
            placeholder="Nhập tên mẫu ..."
          />
        }
      />

      <CommonStyles.FormFieldUI
        name="station"
        label="Loại trạm"
        component={
          <CommonStyles.SelectUI
            className="!h-9"
            size="large"
            loading={loadingOptions}
            disabled={detailTemplate.type === "info"}
            options={optionStation() ?? []}
            handleSelect={handleChoiceStation}
          />
        }
      />
      <CommonStyles.FormFieldUI
        name="tags"
        label="Nội dung SMS"
        rules={[
          {
            required: true,
            message: "Tên mẫu không được để trống!",
          },
        ]}
        component={
          <CommonStyles.SelectUI
            mode="multiple"
            className="!min-h-9"
            disabled={detailTemplate.type === "info"}
            options={optionSMS}
          />
        }
      />

      <CommonStyles.FormFieldUI
        name="message"
        label="Nội dung bổ sung"
        component={
          <CommonStyles.TextAreaUI
            rows={5}
            disabled={detailTemplate.type === "info"}
            placeholder="Nội dung tin gửi bổ sung ..."
          />
        }
      />
      {detailTemplate.type !== "info" && (
        <div className="flex gap-3 justify-end items-center">
          <CommonStyles.ButtonUI
            onClick={toggle}
            size="large"
            danger
            ghost
            // className={`${ResponseUI.button} w-24`}
            className="w-24"
          >
            Hủy
          </CommonStyles.ButtonUI>
          <CommonStyles.ButtonUI
            // className={`${ResponseUI.button} w-24`}
            className="w-24"
            action="submit"
            size="large"
          >
            Xác nhận
          </CommonStyles.ButtonUI>
        </div>
      )}
    </Form>
  );
}
