export const ColorsChart = [
  {
    key: "#2F80ED",
    value: "#2F80ED",
  },
  {
    key: "#436FB6",
    value: "#436FB6",
  },
  {
    key: "#8750A1",
    value: "#8750A1",
  },
  {
    key: "#D1499B",
    value: "#D1499B",
  },

  {
    key: "#FBA51A",
    value: "#FBA51A",
  },
  {
    key: "#EDE41D",
    value: "#EDE41D",
  },
  {
    key: "#72C156",
    value: "#72C156",
  },
];
export const colorHighLow = {
  key: "#ED1E26",
  value: "#ED1E26",
};
