const Regex = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phoneVN: /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/,
  number: /^-?\d+(\.\d)?$/,
  password: /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/,
  tag: /#(\w+)/g,
  lat: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/,
  lon: /^[-+]?([1-9]?\d(\.\d+)?|1[0-7]\d(\.\d+)?|180(\.0+)?)$/,
};

export default Regex;
