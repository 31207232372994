import CommonIcons from "../../assets/icons";
import { CommonStyles } from "../../components/ui";
import { TOption } from "../../interface";
import baseRouter from "../../router/baseRouter";

const optionsTypeStation: TOption[] = [
  {
    label: "Tất cả",
    value: 1,
  },
  {
    label: "Lũ lụt",
    value: 2,
  },
  {
    label: "Sạt lở",
    value: 3,
  },
];
export default function Camera() {
  //! state
  //! function
  const dataSource = [
    {
      stationName: "TRAM_SAT_LO_1 (DATA TEST)",
      code: "ff123d",
      stationType: "LMMN",
      active: true,
      status: "warning",
      cameraNumber: 12,
    },
  ];
  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      render: (_: any, record: any, index: number) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: "Mã trạm",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Tên trạm",
      dataIndex: "stationName",
      key: "stationName",
    },
    {
      title: "Loại trạm",
      dataIndex: "stationType",
      key: "stationType",
    },
    {
      title: "Tình trạng",
      dataIndex: "active",
      key: "active",
      render: (_: any, record: any) => {
        const active = record.active ? "Online" : "Offline";
        return (
          <div className="flex gap-2 items-center">
            <div className="w-1.5 h-1.5 rounded-full bg-green-400"></div>
            <p>{active}</p>
          </div>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (_: any, record: any) => {
        const status = record.status === "warning" ? "Cảnh báo" : "Bình thường";
        return (
          <div className="bg-red-100 rounded-xl text-center text-[#F96161]">
            <p>{status}</p>
          </div>
        );
      },
    },
    {
      title: "Số lượng camera",
      dataIndex: "cameraNumber",
      key: "cameraNumber",
    },
    {
      title: "Xem camera",
      dataIndex: "view",
      key: "view",
      render: (_: any, record: any) => {
        const status = record.status === "warning" ? "Cảnh báo" : "Bình thường";
        return (
          <CommonStyles.ButtonUI
            type="text"
            className="hover:!bg-transparent"
            Icons={<CommonIcons.EyeOutlined className="text-main-color" />}
          />
        );
      },
    },
  ];
  //! render
  return (
    <div className="flex flex-col gap-5">
      <CommonStyles.TitleCardUI
        title="Danh sách camera"
        subTitle={[
          {
            title: "Quản lý hệ thống",
            link: baseRouter.camera,
          },
          {
            title: "Danh sách camera",
            link: baseRouter.camera,
          },
        ]}
      />

      <CommonStyles.CardResize title="Danh sách các trạm">
        <div className="flex flex-col md:flex-row gap-3 justify-between my-3">
          <CommonStyles.InputUI
            type="search"
            placeholder="Nhập trạm tìm kiếm ..."
          />

          <div className="flex gap-5 flex-col md:flex-row">
            <CommonStyles.SelectUI
              label="Loại trạm:"
              horizontal
              defaultValue={1}
              options={optionsTypeStation}
            />
            <CommonStyles.SelectUI
              label="Tình trạng:"
              horizontal
              defaultValue={1}
              options={[
                {
                  label: "Tất cả ",
                  value: 1,
                },
              ]}
            />
            <CommonStyles.SelectUI
              label="Trạng thái:"
              horizontal
              defaultValue={1}
              options={[
                {
                  label: "Tất cả ",
                  value: 1,
                },
              ]}
            />
          </div>
        </div>
        <CommonStyles.TableUI
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: 1500 }}
        />
      </CommonStyles.CardResize>
    </div>
  );
}
