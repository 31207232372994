import { urlsAPI } from "../../constants/api";
import httpService from "../../services/httpService";

class DeviceService {
  getDeviceById(deviceId: string) {
    return httpService.get(urlsAPI.device.getById(deviceId));
  }
  getCustomerDeviceInfos(customerID: string, query?: string) {
    return httpService.get(
      urlsAPI.device.getCustomerDeviceInfos(customerID, query)
    );
  }
  getDeviceInfoByID(deviceID: string) {
    return httpService.get(`${urlsAPI.device.getInfoById}/${deviceID}`);
  }
  getTenantDeviceInfos(query?: string) {
    return httpService.get(`${urlsAPI.device.tenantDeviceInfos}?${query}`);
  }
  createAndUpdate(data: any) {
    return httpService.post(urlsAPI.device.createAndUpdate, data);
  }

  createAndUpdateWithAccessToken(data: any, accessTokentoken: string) {
    return httpService.post(
      `${urlsAPI.device.createAndUpdate}?accessToken=${accessTokentoken}`,
      data
    );
  }

  getDeviceType() {
    return httpService.get(`${urlsAPI.device.typesDevice}`);
  }
}

export default new DeviceService();
