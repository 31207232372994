import React from "react";
import { CommonStyles } from "../../../ui";
import useToggle from "../../../../hooks/useToggle";
import ModalPickColor from "./ModalPickColor";
import useGetAttributeScope from "../../../../modules/telemetry/hooks/useGetAttributeScope";
import { ENTITY_TYPE, SCOPE } from "../../../../constants/Index";
import { parseQueryString } from "../../../../helpers";
import { ColorsChart } from "../../../../constants/color";

interface IProps {
  idStation: string;
}
export default function ColorStation(props: IProps) {
  //! state
  const { idStation } = props;
  const { open, shouldRender, toggle } = useToggle();
  const {
    data: dataColor,
    isLoading: loadingColor,
    refetch,
  } = useGetAttributeScope(
    ENTITY_TYPE,
    String(idStation),
    SCOPE.SHARED_SCOPE,
    true,
    parseQueryString({ keys: "colorChart" })
  );
  //! function
  //! render
  const renderExtraAction = () => {
    return (
      <CommonStyles.ButtonUI
        // onClick={handleSubmit}
        size="small"
      >
        Hoàn thành
      </CommonStyles.ButtonUI>
    );
  };
  return (
    <CommonStyles.SubCard
      title="Chọn màu cho trạm"
      // extra={renderExtraAction()}
    >
      <label>Chọn màu </label>
      <CommonStyles.ButtonUI
        onClick={toggle}
        style={{
          backgroundColor: dataColor?.data[0]?.value ?? ColorsChart[0]?.value,
        }}
      />

      {shouldRender && (
        <CommonStyles.ModalUI
          title="Chọn màu hiện thị biểu đồ"
          open={open}
          toggle={toggle}
          hiddenAction
        >
          <ModalPickColor
            idStation={idStation}
            toggle={toggle}
            refetch={refetch}
          />
        </CommonStyles.ModalUI>
      )}
    </CommonStyles.SubCard>
  );
}
