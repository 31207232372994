import { useState, useEffect, useRef } from "react";

export default function useComponentVisible(initialIsVisible: boolean) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      event.stopPropagation(); // Cancels the click event
      // shouldCancelEvent ? event.stopPropagation() : null;
      setIsComponentVisible(false);
    }
  };

  const toggleComponent = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return {
    ref,
    isComponentVisible,
    setIsComponentVisible,
    toggleComponent,
    handleClickOutside,
  };
}
