import { Form, Select } from "antd";
import { CommonStyles } from "../../../../components/ui";
import useGetUser from "../../../../modules/user/hooks/useGetUser";
import { useEffect } from "react";
import { IUser } from "../../../../modules/auth/user.interface";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { ActionSubmit } from "./actionSubmit";
import { optionsRole } from "../../../../constants/Index";
import ResponseUI from "../../../../constants/response";

interface IProps {
  userModal: {
    title: "add" | "info" | "edit";
    id: string | null;
    customerId:
      | {
          id: string;
          entityType: string;
        }
      | undefined;
  } | null;
  cancel: () => void;
  refetchUser: () => void;
}
export default function ModalUser(props: IProps) {
  //! state
  const { userModal, cancel, refetchUser } = props;
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const { data, isLoading } = useGetUser(
    String(userModal?.id),
    !!userModal?.id
  );
  const detailUser = data?.data;
  const [form] = Form.useForm();
  const initialValues = {
    email: "",
    firstName: "",
    lastName: optionsRole[1].value,
    phone: "",
  };

  //! function
  const handleSubmit = async (values: IUser) => {
    const actionAfterSuccess = () => {
      refetchUser();
      cancel();
    };
    if (!!userModal?.id) {
      const userSend = {
        ...detailUser,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        email: values.email,
      };
      ActionSubmit(userSend, actionAfterSuccess, dispatch, "update");
    } else {
      const userSend = {
        tenantId: user?.tenantId,
        customerId: userModal?.customerId,
        authority: "CUSTOMER_USER",
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        email: values.email,
      };
      ActionSubmit(userSend, actionAfterSuccess, dispatch, "create");
    }
  };

  const handleKeyUp = (e: any) => {
    // Enter
    if (e.keyCode === 13) {
      // handleSubmit(e);
    }
  };

  useEffect(() => {
    if (!!userModal?.id) {
      form.setFieldsValue(detailUser);
    } else {
      form.setFieldsValue({
        email: "",
        firstName: "",
        lastName: optionsRole[1].value,
        phone: "",
      });
    }
  }, [detailUser, userModal]);
  //! render
  if (isLoading) {
    return (
      <div className="h-72">
        <CommonStyles.LoadingUI />
      </div>
    );
  }
  return (
    <Form
      form={form}
      name="detailUser"
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={initialValues}
      onKeyUp={handleKeyUp}
    >
      <div className="grid md:grid-cols-2  gap-x-4">
        <CommonStyles.FormFieldUI
          name="firstName"
          label="Họ và tên"
          rules={[{ required: true, message: `Tên không được để trống!` }]}
          component={
            <CommonStyles.InputUI
              maxLength={50}
              className="h-9 border !border-s-line active:!border-main-color"
              placeholder={"Nhập họ và tên ..."}
              disabled={userModal?.title === "info" ? true : false}
            />
          }
        />

        <CommonStyles.FormFieldUI
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: `Email không được để trống!`,
            },
            {
              type: "email",
              message: `Email không hợp lệ!`,
            },
          ]}
          component={
            <CommonStyles.InputUI
              maxLength={50}
              className="h-9 border !border-s-line active:!border-main-color"
              placeholder={"Nhập email ..."}
              disabled={
                userModal?.title === "info" || userModal?.title === "edit"
                  ? true
                  : false
              }
            />
          }
        />
        <CommonStyles.FormFieldUI
          name="phone"
          label="Số điện thoại"
          rules={[
            {
              required: true,
              message: `Số điện thoại không được để trống!`,
            },
            {
              pattern: new RegExp(/^(?:\+84|84|0)(3|5|7|8|9|1[2689])[0-9]{8}$/),
              message: "Số điện thoại không hợp lệ!",
            },
          ]}
          component={
            <CommonStyles.InputUI
              maxLength={50}
              className="h-9 border !border-s-line active:!border-main-color"
              placeholder={"Nhập số điện thoại ..."}
              disabled={userModal?.title === "info" ? true : false}
            />
          }
        />
        <CommonStyles.FormFieldUI
          name="lastName"
          label="Quyển truy cập"
          component={
            <CommonStyles.SelectUI
              options={optionsRole}
              className="h-9"
              disabled={userModal?.title === "info" ? true : false}
            />
          }
        />
      </div>
      {userModal?.title === "info" ? (
        true
      ) : (
        <div className="flex gap-3 justify-center mt-7">
          <CommonStyles.ButtonUI
            action="submit"
            className={`${ResponseUI.button} w-24`}
          >
            Xác nhận
          </CommonStyles.ButtonUI>
          <CommonStyles.ButtonUI
            ghost
            danger
            onClick={cancel}
            className={`${ResponseUI.button} w-24`}
          >
            Hủy
          </CommonStyles.ButtonUI>
        </div>
      )}
    </Form>
  );
}
