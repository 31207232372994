import { configureStore } from "@reduxjs/toolkit";
import ToastSlice from "./Slices/ToastSlice";
import UserSlice from "./Slices/UserSlice";

export const store = configureStore({
  reducer: {
    toast: ToastSlice,
    user: UserSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
