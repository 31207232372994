import React, { useCallback, useEffect, useState } from "react";
import { CommonStyles } from "../../../ui";
import {
  CDRC,
  LMMN,
  optionStationTypes,
  optionStatusOnOff,
  optionStatusStation,
  stationTypeDefault,
} from "../../../../constants/Index";
import useGetCustomerDeviceInfos from "../../../../modules/device/hooks/useGetCustomerDeviceInfos";
import { IDevice } from "../../../../modules/device/device.interface";
import { limitTextString, parseQueryString } from "../../../../helpers";
import telemetryService from "../../../../modules/telemetry/telemetry.service";
import { IAttributeScope } from "../../../../modules/telemetry/attributeScope.interface";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../hooks/hooks";
import useGetTenantDeviceInfos from "../../../../modules/device/hooks/useGetTenantDeviceInfos";
import baseRouter from "../../../../router/baseRouter";
import { wsNotification } from "../../../../helpers/ws";
import { isEmpty } from "lodash";
import httpService from "../../../../services/httpService";
import { colorHighLow } from "../../../../constants/color";

interface IFilters {
  page: number;
  pageSize: number;
  textSearch: string;
  active: number | string;
  type: string;
}
export default function ModalStations({ keyStation }: { keyStation: string }) {
  //! state
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);
  const [dataTable, setDataTable] = useState<any>([]);
  const [dataTableNoti, setDataTableNoti] = useState<any>({});
  const [check, setCheck] = useState(false);
  const [nameSearch, setNameSearch] = useState("");
  const [filters, setFilters] = useState<IFilters>({
    page: 0,
    pageSize: 20,
    textSearch: "",
    active: "",
    type: keyStation,
  });
  //! function
  const {
    data: dataListStation,
    isLoading: loadingListStation,
    refetch,
  } = useGetCustomerDeviceInfos(
    String(user?.customerId.id),
    filters,
    user?.authority !== "TENANT_ADMIN"
  );

  const {
    data: dataLisStationTenant,
    isLoading: loadingListStationTenant,
    refetch: refetchTenant,
  } = useGetTenantDeviceInfos(filters, user?.authority === "TENANT_ADMIN");

  useEffect(() => {
    const PositionDevice = async () => {
      if (
        (dataListStation &&
          !loadingListStation &&
          dataListStation?.data?.data.length > 0) ||
        (dataLisStationTenant &&
          !loadingListStationTenant &&
          dataLisStationTenant?.data?.data.length > 0)
      ) {
        const dataUsing: IDevice[] =
          user?.authority === "TENANT_ADMIN"
            ? (dataLisStationTenant?.data?.data as IDevice[])
            : (dataListStation?.data?.data as IDevice[]);
        let listDevice: any = [];

        for (const device of dataUsing.filter(
          (item) => item.type === LMMN || item.type === CDRC
        )) {
          const dataDevice = await telemetryService.getAttributeKey(
            device.id.entityType,
            device.id.id,
            parseQueryString({ keys: "lat,lon" })
          );
          const lat = dataDevice.data.find(
            (item: IAttributeScope) => item.key === "lat"
          );
          const lon = dataDevice.data.find(
            (item: IAttributeScope) => item.key === "lon"
          );
          listDevice.push({
            ...device,
            lat: lat?.value ?? null,
            lon: lon?.value ?? null,
            status: "cleared",
          });
        }
        setCheck(true);
        setDataTable(listDevice);
      } else {
        setDataTable([]);
      }
    };
    PositionDevice();
  }, [loadingListStation, loadingListStationTenant]);

  useEffect(() => {
    if (
      (dataListStation && dataListStation?.data.data.length > 0) ||
      !loadingListStation ||
      (dataLisStationTenant &&
        dataLisStationTenant?.data.data.length > 0 &&
        !loadingListStationTenant)
    ) {
      if (user?.authority === "TENANT_ADMIN") {
        refetchTenant && refetchTenant();
      } else {
        refetch && refetch();
      }
    }
  }, [filters]);

  const receiveNotification = (data: any) => {
    if (!isEmpty(data.update)) {
      setDataTableNoti(data.update);
    }
  };

  useEffect(() => {
    if (!!dataTableNoti) {
      const dataNew = [...dataTable];

      let elementIndex = dataNew.findIndex(
        (item) => item.id.id === dataTableNoti.info.stateEntityId.id
      );
      if (elementIndex > -1) {
        dataNew[elementIndex].status = dataTableNoti.info?.action;
        setDataTable([...dataNew]);
      }
    }
  }, [dataTableNoti]);

  useEffect(() => {
    const notifi = () => {
      wsNotification(
        "plugins/notifications",
        {
          unreadSubCmd: {
            cmdId: 6,
            limit: 6,
          },
        },
        receiveNotification
      );
    };

    notifi();
  }, [check]);

  const onChangePage = (page: number) => {
    setFilters((prev) => ({ ...prev, page: page - 1 }));
  };

  const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameSearch(event.target.value);
  };
  const onSearchDebounce = (value: string) => {
    setFilters((prev) => ({ ...prev, textSearch: value }));
  };
  const onChangeStatus = (key: string, value: any) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };
  const onClickRow = (record: any) => {
    navigate(`${baseRouter.station}/${record?.id?.id}`);
  };
  //! render
  const renderColumn = useCallback(() => {
    return [
      {
        title: "STT",
        dataIndex: "index",
        key: "index",
        render: (_: any, record: IDevice, index: number) => {
          return <p>{index + filters.page * filters.pageSize + 1}</p>;
        },
      },
      {
        title: "Mã trạm",
        dataIndex: "name",
        key: "stationCode",
        render: (_: any, record: IDevice) => {
          const stationCode = record.name.split("/");
          return <p>{stationCode[0]}</p>;
        },
      },
      {
        title: "Tên trạm",
        dataIndex: "name",
        key: "stationName",
        render: (_: any, record: IDevice) => {
          const stationCode = record.name.split("/");
          return <p>{limitTextString(stationCode[1], 20)}</p>;
        },
      },
      {
        title: "Loại trạm",
        dataIndex: "type",
        key: "type",
        render: (_: any, record: IDevice) => {
          const station = stationTypeDefault.find(
            (item) => item.type === record.type
          );
          return <p>{limitTextString(String(station?.name), 20)}</p>;
        },
      },
      {
        title: "Kinh độ",
        dataIndex: "lon",
        key: "lone",
      },
      {
        title: "Vĩ độ",
        dataIndex: "lat",
        key: "lat",
      },
      {
        title: "Tình trạng",
        dataIndex: "active",
        key: "active",
        render: (_: any, record: IDevice) => {
          return (
            <div className="flex items-center gap-2">
              <div
                className={`w-1 h-1 rounded-full ${
                  record.active ? "bg-green-400" : "bg-gray-400"
                }`}
              ></div>
              <span>{record.active ? "Online" : "Offline"}</span>
            </div>
          );
        },
      },
      {
        title: "Trạng thái",
        dataIndex: "status",
        key: "status",
        width: "15%",
        render: (_: any, record: any) => {
          const text = record.status === "created" ? "Cảnh báo" : "Bình thường";
          const danger = record.status === "created";
          return (
            <div
              className="px-1.5 rounded-2xl flex justify-center text-nowrap"
              style={{
                backgroundColor: danger ? "#FFE9E9" : "#E1FFED",
                color: danger ? "#F96161" : "#52D887",
              }}
            >
              {text}
            </div>
          );
        },
      },
    ];
  }, [dataTable]);

  return (
    <div className="min-h-[500px]">
      <div className="flex justify-between items-center">
        <CommonStyles.InputUI
          type="search"
          placeholder="Nhập trạm tìm kiếm ..."
          onChange={changeSearch}
          onPressEnter={() => onSearchDebounce(nameSearch)}
          value={nameSearch}
        />
        <div className="grid grid-cols-2 gap-2 my-3">
          <CommonStyles.SelectUI
            disabled={keyStation ? true : false}
            label="Loại trạm:"
            horizontal
            options={optionStationTypes}
            value={filters.type}
            onChange={(value) => {
              onChangeStatus("type", value);
            }}
          />

          <CommonStyles.SelectUI
            label="Tình trạng:"
            horizontal
            options={optionStatusOnOff}
            value={filters.active}
            onChange={(value) => {
              onChangeStatus("active", value);
            }}
          />
          {/* <CommonStyles.SelectUI
            label="Trạng thái:"
            horizontal
            options={optionStatusStation}
            // value={filters.type}
            onChange={(value) => {
              // onChangeStatus("type", value);
            }}
          /> */}
        </div>
      </div>

      <CommonStyles.TableUI
        dataSource={dataTable ?? []}
        columns={renderColumn()}
        rowClassName={"cursor-pointer"}
        loading={loadingListStation || loadingListStationTenant}
        scroll={{
          y: 600,
        }}
        onRow={(record, index): any => {
          return {
            onClick: () => {
              onClickRow(record);
            },
          };
        }}
      />
      {/* <CommonStyles.PaginationUI
        page={filters.page + 1}
        pageSize={filters.pageSize}
        totalElement={
          user?.authority === "TENANT_ADMIN"
            ? dataLisStationTenant?.data.totalElements
            : dataListStation?.data.totalElements
        }
        onChangePage={onChangePage}
        potision="end"
      /> */}
    </div>
  );
}
