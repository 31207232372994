import { Form } from "antd";
import { CommonStyles } from "../../../../ui";
import { useEffect } from "react";
import moment from "moment";
import { useAppDispatch } from "../../../../../hooks/hooks";
import { getErrorMsg, parseQueryString, showMsg } from "../../../../../helpers";
import telemetryService from "../../../../../modules/telemetry/telemetry.service";
import { ENTITY_TYPE, SCOPE } from "../../../../../constants/Index";
import useGetAttributeScope from "../../../../../modules/telemetry/hooks/useGetAttributeScope";
import { v4 as uuidv4 } from "uuid";

interface IProps {
  toggle: () => void;
  idStation: string;
  refetch: () => void;
  device: {
    type: "create" | "edit" | "info";
    id: string | null;
  };
}
export default function FormEditDevice(props: IProps) {
  //! state
  const { idStation, toggle, refetch, device: deviceProps } = props;
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const { data: dataKey, isLoading: loadingKey } = useGetAttributeScope(
    ENTITY_TYPE,
    String(idStation),
    SCOPE.SHARED_SCOPE,
    true,
    parseQueryString({ keys: "devices" })
  );
  const dataDevices = dataKey?.data[0]?.value ?? [];

  const detailDevice = dataDevices?.find(
    (el: any) => el?.id === deviceProps.id
  );
  const initialValues = {
    name: detailDevice?.name ?? "",
    date: detailDevice?.date ?? moment().format("DD-MM-YYYY"),
    branch: detailDevice?.branch ?? "",
    model: detailDevice?.model ?? "",
    serial: detailDevice?.serial ?? "",
  };
  //! function
  const onChangeDate = (time: any, timeString: any) => {
    form.setFieldValue("date", timeString);
  };
  const handleSubmit = async (values: any) => {
    try {
      const devices = [...dataDevices];

      if (deviceProps.type === "create") {
        const device: any = {
          id: uuidv4(),
          name: values.name,
          branch: values.branch,
          model: values.model,
          serial: values.serial,
          date: values.date,
        };
        devices.push(device);
      }
      if (deviceProps.type === "edit") {
        const indexDevice = devices.findIndex(
          (el) => el?.id === deviceProps.id
        );
        if (indexDevice > -1) {
          devices[indexDevice] = {
            ...devices[indexDevice],
            name: values.name,
            branch: values.branch,
            model: values.model,
            serial: values.serial,
            date: values.date,
          };
        }
      }

      const postDevices = await telemetryService.postDeviceTele(
        idStation,
        SCOPE.SHARED_SCOPE,
        {
          devices: devices,
        }
      );

      if (postDevices.status === 200) {
        toggle && toggle();
        refetch && refetch();
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues, detailDevice?.date]);
  //! render
  if (loadingKey) {
    return <CommonStyles.LoadingUI />;
  }
  return (
    <Form
      form={form}
      initialValues={initialValues}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <div className="grid grid-cols-2 gap-3">
        <CommonStyles.FormFieldUI
          name="name"
          label="Tên thiết bị"
          rules={[
            {
              required: true,
              message: "Tên thiết bị không được bỏ trống!",
            },
          ]}
          component={
            <CommonStyles.InputUI
              disabled={deviceProps.type === "info" && true}
              placeholder="Tên thiết bị ..."
            />
          }
        />

        <CommonStyles.FormFieldUI
          name="date"
          label="Ngày lắp đặt"
          component={
            <CommonStyles.DateTimePickerUI
              type="date"
              disabled={deviceProps.type === "info" && true}
              handleChange={onChangeDate}
              value={detailDevice?.date}
              className="w-full"
              placeholder="Ngày lắp đặt ..."
            />
          }
        />

        <CommonStyles.FormFieldUI
          name="branch"
          label="Hãng"
          className="col-span-2"
          component={
            <CommonStyles.InputUI
              disabled={deviceProps.type === "info" && true}
              placeholder="Hãng ..."
            />
          }
        />
        <CommonStyles.FormFieldUI
          name="model"
          label="Mã model"
          component={
            <CommonStyles.InputUI
              disabled={deviceProps.type === "info" && true}
              placeholder="Mã model ..."
            />
          }
        />
        <CommonStyles.FormFieldUI
          name="serial"
          label="Serial"
          component={
            <CommonStyles.InputUI
              disabled={deviceProps.type === "info" && true}
              placeholder="Serial ..."
            />
          }
        />
      </div>

      {deviceProps.type !== "info" && (
        <div className="flex justify-end items-center gap-3">
          <CommonStyles.ButtonUI
            className="w-24"
            size="large"
            ghost
            danger
            onClick={toggle}
          >
            Hủy
          </CommonStyles.ButtonUI>
          <CommonStyles.ButtonUI action="submit" className="w-24" size="large">
            Xác nhận
          </CommonStyles.ButtonUI>
        </div>
      )}
    </Form>
  );
}
