import React, { ReactNode, useState } from "react";
import CommonIcons from "../../../assets/icons";
import CommonImages from "../../../assets/images";
import ButtonUI from "../button/Button";
import TooltipUI from "../tooltip/TooltipUI";
import { PositionType } from "antd/es/image/style";
import { useTranslation } from "react-i18next";

interface IProps {
  title?: string;
  fullScreen?: boolean;
  handleInfo?: () => void;
  Icon?: any;
  children?: ReactNode;
  className?: string;
  ImageIcon?: any;
  extra?: React.ReactNode;
}
export default function CardResize(props: IProps) {
  //! state
  const { title, Icon, ImageIcon, children, className, extra } = props;
  const [isFullScreen, setFullScreen] = useState(false);
  //! function
  const handleFullScreen = () => {
    setFullScreen(!isFullScreen);
  };
  const styleFullScreen = {
    position: "fixed" as PositionType,
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 2000,
  };
  //! render
  return (
    <div
      id="card-resize"
      className={`bg-white p-3 gap-3 rounded-xl justify-between ${className} h-full `}
      style={isFullScreen ? styleFullScreen : undefined}
    >
      <div className="flex justify-between items-start pb-3 h-[10%]">
        <div className="flex gap-2 font-medium xs:text-xs sm:text-sm lg:text-lg">
          {Icon && <Icon style={{ fontWeight: "bold" }} />}
          {ImageIcon && <img src={`${ImageIcon}`} />}
          {title}
        </div>
        {extra && extra}
      </div>

      <div className="h-[90%]">{children}</div>
    </div>
  );
}
