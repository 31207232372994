const baseRouter = {
  dashboard: "/",
  station: "/stations",
  devices: "/devices",
  stationSettings: "/stations/settings",
  detailStation: "station/:id",
  user: "/manageSystems/users",
  tickets: "/ticketStation",
  profile: "/profile",
  settingPassword: "/settingPassword",
  stationLMMN: "/stationLMMN",
  stationCDRC: "/stationCDRC",
  sms: "/sms",
  camera: "/camera",
  notifications: "/notifications",
  smsGroupPhone: "/smsGroupPhone",
};
export default baseRouter;
