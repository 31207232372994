import { Form, Input } from "antd";
import InputUI from "../ui/input/Input";
import FormExtra from "./FormExtra";
import FormAction from "./FormAction";

import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import { useTranslation } from "react-i18next";

import useAuth from "../../modules/auth/hooks/useAuth";
import { CommonStyles } from "../ui";

const handleKeyUp = (e: any) => {
  // Enter
  if (e.keyCode === 13) {
    // handleSubmit(e);
  }
};

const LoginForm = (props: any) => {
  //! state
  const { t } = useTranslation();
  const { handleLogin } = useAuth();
  const [form] = Form.useForm();
  const initialValues = {
    username: "",
    password: "",
  };
  //! function

  //! render
  return (
    <Form
      form={form}
      name="login"
      layout="vertical"
      onFinish={handleLogin}
      initialValues={initialValues}
      onKeyUp={handleKeyUp}
    >
      <CommonStyles.FormFieldUI
        name="username"
        label={t("Username")}
        rules={[{ required: true, message: `${t("Username is required!")}` }]}
        hasFeedback
        component={
          <InputUI
            maxLength={50}
            className="h-9 border !border-s-line active:!border-main-color"
            placeholder={t("Enter Username (email)")}
          />
        }
      />

      <CommonStyles.FormFieldUI
        name={"password"}
        label={t("Password")}
        rules={[{ required: true, message: `${t("Password is required!")}` }]}
        hasFeedback
        component={
          <Input.Password
            maxLength={50}
            type="password"
            className="h-9 border !border-s-line "
            placeholder={t("Enter Passwod")}
            iconRender={(visible) =>
              visible ? (
                <EyeTwoTone style={{ color: "#2F80ED!important" }} />
              ) : (
                <EyeInvisibleOutlined />
              )
            }
          />
        }
      />

      <FormExtra />
      <FormAction />
    </Form>
  );
};
export default LoginForm;
