import { useCallback, useEffect, useState } from "react";
import { ResponseGetTemplateSMS } from "../templateSMS.interface";
import smsService from "../sms.service";

const useGetTemplates = (query: string, enabled = true) => {
  const [data, setData] = useState<ResponseGetTemplateSMS>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return smsService.getTemplates(query);
  }, [query]);

  const transformResponse = useCallback((response: ResponseGetTemplateSMS) => {
    if (response) {
      setData(response);
    }
  }, []);
  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [query]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled]);

  return { data, error, isLoading, refetch };
};

export default useGetTemplates;
