import React, { useCallback, useEffect } from "react";
import { CommonStyles } from "../../../ui";
import { Form } from "antd";
import { useAppDispatch } from "../../../../hooks/hooks";
import useGetAttributeKey from "../../../../modules/telemetry/hooks/useGetAttributeKey";
import { ENTITY_TYPE, SCOPE } from "../../../../constants/Index";
import {
  getErrorMsg,
  getStatusAction,
  parseQueryString,
  showMsg,
} from "../../../../helpers";
import telemetryService from "../../../../modules/telemetry/telemetry.service";
import Regex from "../../../../constants/regex";
interface IProps {
  idStation: string;
}
export default function DeviceSettings(props: IProps) {
  //! state
  const { idStation } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { data, isLoading, refetch } = useGetAttributeKey(
    ENTITY_TYPE,
    idStation,
    true,
    parseQueryString({ keys: "Setlow,Sethigh,crackMetterStart" })
  );

  const low = data?.data.find((item) => item.key === "Setlow");
  const hight = data?.data.find((item) => item.key === "Sethigh");
  const crackMetterStart = data?.data?.find(
    (item) => item.key === "crackMetterStart"
  );

  const setLow = low?.value.split(",");
  const setHigh = hight?.value.split(",");
  const initialValues = {
    crackMetterStart: (crackMetterStart && Number(crackMetterStart.value)) ?? 0,
    crackMetterThresholdHigh: (setHigh && Number(setHigh[0])) ?? 0,
    crackMetterThresholdLow: (setLow && Number(setLow[0])) ?? 0,

    vectorGalMaxThresholdHigh: (setHigh && Number(setHigh[1])) ?? 0,
    intensityMaxThresholdHigh: (setHigh && Number(setHigh[2])) ?? 0,
  };
  const valueInit = [
    (crackMetterStart && Number(crackMetterStart.value)) ?? 0,
    (setHigh && Number(setHigh[0])) ?? 0,
    (setLow && Number(setLow[0])) ?? 0,
    (setHigh && Number(setHigh[1])) ?? 0,
    (setHigh && Number(setHigh[2])) ?? 0,
  ];
  const valueCrackStart = Form.useWatch("crackMetterStart", form);
  const valueThresholdHigh = Form.useWatch("crackMetterThresholdHigh", form);
  const valueThresholdLow = Form.useWatch("crackMetterThresholdLow", form);
  const valueGalMaxThresholdHigh = Form.useWatch(
    "vectorGalMaxThresholdHigh",
    form
  );
  const valueintensityMaxThresholdHigh = Form.useWatch(
    "intensityMaxThresholdHigh",
    form
  );

  //! function
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [isLoading]);

  const handleSubmit = async () => {
    const values = form.getFieldsValue();
    const valid = form.getFieldsError();
    let check = true;
    valid.forEach((element) => {
      if (element.errors.length > 0) {
        check = false;
      }
    });
    if (!check) {
      return;
    }
    try {
      const dataLow = `${values.crackMetterThresholdLow}`;
      const dataHigh = `${values.crackMetterThresholdHigh},${values.vectorGalMaxThresholdHigh},${values.intensityMaxThresholdHigh}`;

      const postSoftware = await telemetryService.postDeviceTele(
        idStation,
        SCOPE.SHARED_SCOPE,
        {
          Setlow: dataLow,
          Sethigh: dataHigh,
          crackMetterStart: values.crackMetterStart,
        }
      );
      if (postSoftware.status === 200) {
        refetch();
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };

  //! render
  const renderExtra = useCallback(() => {
    return (
      <CommonStyles.ButtonUI
        disabled={getStatusAction(valueInit, [
          Number(valueCrackStart),
          Number(valueThresholdHigh),
          Number(valueThresholdLow),
          Number(valueGalMaxThresholdHigh),
          Number(valueintensityMaxThresholdHigh),
        ])}
        onClick={handleSubmit}
        size="small"
      >
        Hoàn thành
      </CommonStyles.ButtonUI>
    );
  }, [isLoading, initialValues]);
  return (
    <CommonStyles.SubCard
      title="Cài đặt ngưỡng cảnh báo thiết bị"
      extra={renderExtra()}
    >
      <Form
        form={form}
        name="deviceCDRC"
        initialValues={initialValues}
        // onFinish={handleSubmit}

        layout="vertical"
        className="md:grid md:grid-cols-2 gap-3 items-center"
      >
        <CommonStyles.FormFieldUI
          name={"crackMetterStart"}
          label="Độ dài dây căng ban đầu (Cm)"
          className="col-span-2"
          rules={[
            {
              required: true,
              message: "Độ dài dây căng ban đầu không được trống!",
            },
            {
              pattern: Regex.number,
              message: "Giá trị cần là số!",
            },
          ]}
          component={
            <CommonStyles.InputUI
              maxLength={50}
              className="h-9 border !border-s-line active:!border-main-color"
            />
          }
        />

        <CommonStyles.FormFieldUI
          name={"crackMetterThresholdHigh"}
          label="Dây căng cao (Cm)"
          rules={[
            {
              required: true,
              message: "Dây căng cao không được trống!",
            },
            {
              pattern: Regex.number,
              message: "Giá trị cần là số!",
            },
          ]}
          component={
            <CommonStyles.InputUI
              maxLength={50}
              className="h-9 border !border-s-line active:!border-main-color"
            />
          }
        />
        <CommonStyles.FormFieldUI
          name={"crackMetterThresholdLow"}
          label="Dây căng thấp (Cm)"
          rules={[
            {
              required: true,
              message: "Dây căng thấp không được trống!",
            },
            {
              pattern: Regex.number,
              message: "Giá trị cần là số!",
            },
          ]}
          component={
            <CommonStyles.InputUI
              maxLength={50}
              className="h-9 border !border-s-line active:!border-main-color"
            />
          }
        />
        <CommonStyles.FormFieldUI
          name={"vectorGalMaxThresholdHigh"}
          label="Gia tốc rung chấn (Gal)"
          rules={[
            {
              required: true,
              message: "Gia tốc rung chấn không được trống!",
            },
            {
              pattern: Regex.number,
              message: "Giá trị cần là số!",
            },
          ]}
          component={
            <CommonStyles.InputUI
              maxLength={50}
              className="h-9 border !border-s-line active:!border-main-color"
            />
          }
        />

        <CommonStyles.FormFieldUI
          name={"intensityMaxThresholdHigh"}
          label="Cường độ rung chấn cao (Level)"
          rules={[
            {
              required: true,
              message: "Cường độ rung chấn cao không được trống!",
            },
            {
              pattern: Regex.number,
              message: "Giá trị cần là số!",
            },
          ]}
          component={
            <CommonStyles.InputUI
              maxLength={50}
              className="h-9 border !border-s-line active:!border-main-color"
            />
          }
        />
      </Form>
    </CommonStyles.SubCard>
  );
}
