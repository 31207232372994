import React from "react";
import useGetDetailDevice from "../../../../modules/device/hooks/useGetDetailDevice";
import { CommonStyles } from "../../../ui";
import CommonIcons from "../../../../assets/icons";
import useToggle from "../../../../hooks/useToggle";
import EditModalStationName from "./EditStation";

interface IProps {
  idStation: string;
}
export default function InfoStation(props: IProps) {
  //! state
  const { idStation } = props;
  const {
    data: dataDevice,
    isLoading: loadingDevice,
    refetch,
  } = useGetDetailDevice(idStation);
  const { open, shouldRender, toggle } = useToggle();
  //! function
  //! render
  return (
    <div className="bg-white p-3 rounded-lg">
      <div className=" bg-main-color px-4 py-2 rounded-lg h-full">
        <div className="flex justify-end">
          <CommonStyles.TooltipUI title="Chỉnh sửa trạm">
            <CommonStyles.ButtonUI
              onClick={toggle}
              className="bg-[#FFFFFF33]"
              Icons={<CommonIcons.EditOutlined />}
            />
          </CommonStyles.TooltipUI>
        </div>

        <div className="flex flex-col justify-center gap-2">
          <div className="flex">
            <div className="border-r pr-5">
              <p className=" text-base text-white mb-2">Mã trạm</p>
              <CommonStyles.TypographyUI
                className="!text-white"
                typographyType="title"
                level={3}
              >
                {dataDevice?.data.name.split("/")[0]}
              </CommonStyles.TypographyUI>
            </div>

            <div className="px-5">
              <p className=" text-base text-white mb-2">Tên trạm</p>

              <CommonStyles.TypographyUI
                className="!text-white"
                typographyType="title"
                level={3}
              >
                {dataDevice?.data.name.split("/")[1]}
              </CommonStyles.TypographyUI>
            </div>
          </div>
        </div>
      </div>
      {shouldRender && (
        <CommonStyles.ModalUI
          title="Chỉnh sửa thông tin trạm"
          open={open}
          toggle={toggle}
          hiddenAction
        >
          <EditModalStationName
            toggle={toggle}
            idStation={String(idStation)}
            refetch={refetch}
          />
        </CommonStyles.ModalUI>
      )}
    </div>
  );
}
