import { Select, SelectProps, Space } from "antd";
import { TOption } from "../../../interface";
import "./Select.css";
interface IProps extends SelectProps {
  disabled?: boolean;
  loading?: boolean;
  options: TOption[];
  hanleChange?: SelectProps["onChange"];
  className?: string;
  value?: string | number;
  variant?: "outlined" | "borderless" | "filled";
  handleSelect?: SelectProps["onSelect"];
  label?: string;
  horizontal?: boolean;
  afterOnchange?: () => void;
}
export default function SelectUI(props: IProps) {
  //! state
  const {
    disabled,
    loading,
    options,
    hanleChange,
    className,
    value,
    handleSelect,
    variant,
    label,
    horizontal,
    ...res
  } = props;
  //! function
  //! render
  let styleLabel = "";
  if (horizontal) {
    styleLabel = "flex gap-2 items-center";
  } else {
    styleLabel = "";
  }
  return (
    <div className={styleLabel}>
      {label && <label className="whitespace-nowrap">{label}</label>}
      <Select
        onChange={hanleChange}
        options={options}
        value={value}
        variant={variant}
        className={`${className} w-full `}
        style={{
          minWidth: 120,
        }}
        onSelect={handleSelect}
        optionRender={(option) => <Space>{option.label}</Space>}
        disabled={disabled}
        {...res}
      />
    </div>
  );
}
