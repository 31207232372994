import { Dropdown, DropDownProps } from "antd";
import React from "react";
interface IProps extends DropDownProps {
  items: { label: string | React.ReactNode; key: string }[] | undefined;
  children: React.ReactNode;
}
export default function DropdownUI(props: IProps) {
  //! state
  const { items, children } = props;
  //! function
  //! render
  return (
    <Dropdown trigger={["click"]} menu={{ items: items ?? [] }}>
      {children}
    </Dropdown>
  );
}
